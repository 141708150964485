<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>
        <v-card-text>

            <v-container grid-list-md class="pa-0 container--fluid">
                <v-row  >

                    <v-col cols="12" v-if="showProducts">
                        <v-autocomplete
                                :disabled="disabled || !!courseModuleSku.id"
                                :items="availableProducts"
                                v-model="courseModuleSku.product_id"
                                item-text="title"
                                item-value="id"
                                :search-input="search"
                                @update:search-input="$emit('update:search', $event)"
                                :error-messages="errors.collect('product_id')"
                                data-vv-name="product_id"
                                v-validate="'required'"
                                label="Produit *"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                                v-validate="'required'"
                                v-model="courseModuleSku.title"
                                ref="titleInput"
                                :error-messages="errors.collect('title')"
                                label="Nom *"
                                name="title"
                                data-vv-title="title"
                                required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="showPrice">
                        <mg-currency-field
                                v-validate="'required|decimal:2|min_value:1'"
                                :disabled="disabled || !!courseModuleSku.id"
                                v-model="courseModuleSku.price"
                                ref="priceInput"
                                label="Prix *"
                                required
                                :currency="courseModuleSku.currency"
                        ></mg-currency-field>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="showCurrency">
                        <v-autocomplete
                                :items="currencies"
                                :disabled="disabled || !!courseModuleSku.id"
                                v-model="courseModuleSku.currency"
                                :error-messages="errors.collect('currency')"
                                data-vv-name="currency"
                                v-validate="'required'"
                                label="Devise *"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                        <v-row align="center">
                            <v-checkbox
                                    v-validate
                                    v-model="courseModuleSku.enabled"
                                    :error-messages="errors.collect('enabled')"
                                    label="Activé"
                                    data-vv-name="enabled"
                                    :true-value="true"
                                    :false-value="false"
                                    @change="fields.enabled.dirty = true"
                                    color="secondary"
                            ></v-checkbox>
                        </v-row>
                    </v-col>

                </v-row>
            </v-container>
        </v-card-text>

        <v-card-text class="pa-1" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="isLoading"
                   @click="submit">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";

    export default {
        name: 'course-module-sku-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            products: Array,

            showProducts: Boolean,
            availableProducts: Array,

            showCurrency: {
                type: Boolean,
                default: true,
            },
            showPrice: {
                type: Boolean,
                default: true,
            },

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,

            dialog: Boolean,
            isDialog: Boolean,
            isLoading: Boolean,
            disabled: Boolean,

            value: {
                type : Object,
                required : true,
            },

            showToolbar: {
                type: String,
                default: null
            },
            dark: Boolean,
        },

        data: () => ({
            currencies: [
                {
                    text: 'Euro (€)', value: 'EUR'
                },
                {
                    text: 'Dollar ($)', value: 'USD'
                },
            ],
            // courseModuleSkuIntervals: [
            //     {
            //         text: 'Mensuel', value: 'month'
            //     },
            //     {
            //         text: 'Trimestriel', value: 'quarterly'
            //     },
            //     {
            //         text: 'Annuel', value: 'year'
            //     },
            // ]
        }),


        mounted() {

        },

        watch: {
            // courseModuleSku(newValue) {
            //     if(newValue){
            //         this.syncValue(newValue)
            //     }
            // }
        },

        computed: {
            courseModuleSku: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        methods: {
            // syncValue(courseModuleSku) {
            // },

            validateForm() {
                return this.$validator.validateAll();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.MgAlertComponent.displayAlert();
            },

            submit() {
                this.MgAlertComponent.closeAlert();

                this.validateForm().then(isValid => {
                    if (isValid) {
                        this.$emit('submit', this.courseModuleSku);
                    }
                })
            },
        }
    }
</script>
