import Vue from 'vue'
/* V1 */
import Vuetify from 'vuetify/lib'
// import 'vuetify/src/stylus/app.styl'
// import 'vuetify/src/stylus/components/_tabs.styl'
// import 'vuetify/src/stylus/components/_cards.styl'
// import 'vuetify/src/stylus/components/_tables.styl'
// import 'vuetify/src/stylus/components/_toolbar.styl'
// import 'vuetify/src/stylus/components/_lists.styl'
// import 'vuetify/src/stylus/components/_tooltips.styl'
// import 'vuetify/src/stylus/components/_pagination.styl'
// import 'vuetify/src/stylus/components/_data-table.styl'
// import 'vuetify/src/stylus/components/_navigation-drawer.styl'
// import 'vuetify/src/stylus/components/_expansion-panel.styl'
// import '../stylus/_uppy.styl'

/* V2 */
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'
// import 'vuetify/src/styles/main.sass'

import fr from 'vuetify/es5/locale/fr'
fr.dataTable.sortBy = 'Trier par';

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        // dark : true,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                default: {
                    base: '#25282B',     // gray-6
                    lighten5: '#F2F2F2', // gray-1
                    lighten4: '#E8E8E8', // gray-2
                    lighten3: '#CACCCF', // gray-3
                    lighten2: '#A0A4A8', // gray-4
                    lighten1: '#52575C', // gray-5
                },
                primary: '#0175E2', // #E53935
                secondary: '#29333d',
                accent: '#084565',
                creamy: '#EEEEEE',
                // creamy: '#e7e7e7',
                grey: '#29333D'
            },
            dark: {
                default: '#fff', // #E53935
                primary: '#0175E2', // #E53935
                secondary: '#29333d',
                accent: '#084565',
                creamy: '#636363',
                grey: '#29333D'
            }
        }
    },
    iconfont: 'mdi',
    lang: {
        locales: {fr},
        current: 'fr'
    },
});
