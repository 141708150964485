<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-stepper v-model="stepperIndex" vertical class="transparent elevation-0">
            <v-stepper-step :complete="stepperIndex > 1" :step="1" editable>
                Type de produit
            </v-stepper-step>

            <v-stepper-content step="1">

                <v-col cols="12">
                    <v-select
                            v-model="product.type"
                            :items="productTypesValues"
                            v-validate="'required'"
                            :error-messages="errors.collect('type')"
                            data-vv-name="type"
                            label="Type *"
                    ></v-select>

                    <v-btn color="secondary" @click="validateProductType">Suivant</v-btn>
                </v-col>
            </v-stepper-content>

            <v-stepper-step :complete="stepperIndex > 2" :step="2">
                Catégorie
            </v-stepper-step>

            <v-stepper-content step="2">

                <v-col cols="12">
                    <v-select
                            v-model="productTypeSelected"
                            :items="productTypesAvailableFiltered"
                            item-text="title"
                            v-validate="'required'"
                            return-object
                            :error-messages="errors.collect('product_type_id')"
                            data-vv-name="product_type_id"
                            label="Catégorie *"
                    ></v-select>

                    <v-btn color="secondary" @click="validateProductCategory">Suivant</v-btn>
                </v-col>

            </v-stepper-content>

            <template v-if="!productTypeSelected.full_courses_access">
                <v-stepper-step :complete="stepperIndex > 3" :step="3">
                    Cours
                </v-stepper-step>

                <v-stepper-content step="3">
                    <v-col cols="12">
                        <v-autocomplete
                                :items="availableCourses"
                                :value="selectedCourses"
                                @input="$emit('update:selectedCourses', $event)"
                                item-text="title"
                                item-value="id"
                                returnObject
                                multiple
                                cache-items
                                :search-input="searchCourse"
                                @update:search-input="$emit('update:searchCourse', $event)"
                                :error-messages="errors.collect('courses')"
                                data-vv-name="courses"
                                v-validate="'required'"
                                label="Cours *"
                        >
                            <template v-slot:selection="data">
                                <v-chip class="caption">
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-btn color="secondary" @click="validateCourses">Suivant</v-btn>
                </v-stepper-content>
            </template>

            <v-stepper-step :complete="!productTypeSelected.full_courses_access ? stepperIndex > 4 : stepperIndex > 3" :step="!productTypeSelected.full_courses_access ? 4 : 3">
                Titre & Options
            </v-stepper-step>

            <v-stepper-content :step="!productTypeSelected.full_courses_access ? 4 : 3" class="pa-0">
                <product-form
                        dark

                        submitBtn="Enregistrer"
                        submitBtnColor="secondary"

                        lightForm
                        ref="productForm"
                        v-model="product"
                        :loading="loading"
                        showEnabled
                        @submit="validateTitleAndSubmit"
                        canEditStripeId

                        :tagStylesAvailable.sync="tagStylesAvailable"
                        :tagLevelsAvailable.sync="tagLevelsAvailable"
                        :instrumentsAvailable.sync="instrumentsAvailable"
                >
                </product-form>
            </v-stepper-content>
        </v-stepper>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import _ from 'lodash'
    import ProductTypeLogic from "mg-js-core/lib/logics/ProductTypeLogic";

    export default {
        name: 'product-form-stepper',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            step: Number,

            toolbarTitle: String,

            prices: Array,

            dialog: Boolean,
            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            isDialog: Boolean,
            loading: Boolean,
            disabled: Boolean,
            dark: Boolean,

            newPlanIsLoading: Boolean,

            value: Object,

            showToolbar: {
                type: String,
                default: null
            },

            course: Object,
            showEnabled: Boolean,

            productTypesAvailable: Array,

            /* courses */
            availableCourses: Array,
            selectedCourses: Array,
            searchCourse: String,

            /* tags */
            tagStylesAvailable: Array,
            tagLevelsAvailable: Array,
            instrumentsAvailable: Array,
        },

        data: () => ({
            productTypesValues: [
                {
                    text: 'Vente par abonnement', value: 'service'
                },
                {
                    text: 'Vente à la carte', value: 'good'
                },
            ],

            productTypeSelected: ProductTypeLogic.getEmptyObject(),
        }),

        mounted() {
        },

        watch : {
            stepperIndex(value, oldValue) {
                if(value === 1 && oldValue > 1){
                    this.productTypeSelected = ProductTypeLogic.getEmptyObject();
                }
            },
            productTypeSelected(value) {
                if (value && value.id) {
                    this.product.product_type_id = value.id;
                }else{
                    this.product.product_type_id = ProductTypeLogic.getEmptyObject();
                }
            }
        },
        computed: {
            product: {
                get() {
                    return this.value;
                },
                set(value) {
                    console.error(value)
                    this.$emit('input', value)
                },
            },

            stepperIndex: {
                get() {
                    return this.step
                },
                set(step) {
                    this.$emit('update:step', step)
                }
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            productTypesAvailableFiltered() {
                return this.productTypesAvailable
            },

            current() {
                return this.$refs.MgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },
        },

        methods: {
            setIntervalCount(interval) {
                if (interval === 'quarterly') {
                    this.plan.interval = 'month';
                    this.plan.interval_count = 3;
                } else if (interval === 'semester') {
                    this.plan.interval = 'month';
                    this.plan.interval_count = 6;
                } else {
                    this.plan.interval = interval;
                    this.plan.interval_count = 1;
                }
            },
            customFilter(item, queryText) {
                const name = item.name.toLowerCase()
                const searchText = queryText.toLowerCase()

                return name.indexOf(searchText) > -1
            },

            validateProductForm() {
                return this.$validator.validateAll();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                let body = {};

                _.forIn(this.fields, (value, key) => {
                    body[key] = this.product[key]
                });

                this.$emit('submit', {
                    ...body,
                    courses: this.selectedCourses.map(course => course.id),
                });
            },

            /* Validation + submit  */
            validateProductType() {
                this.$validator.validate('type').then(isValid => {
                    if (isValid) {
                        this.stepperIndex = 2
                    }
                })
            },

            validateProductCategory() {
                if (this.productTypeSelected.id) {
                    this.stepperIndex = 3
                }
            },

            validateCourses() {
                this.$validator.validate('courses').then(isValid => {
                    if (isValid) {
                        this.stepperIndex = 4
                    }
                })
            },

            validateTitleAndSubmit(lightBody) {
                let body = {};

                _.forIn(this.fields, (value, key) => {
                    body[key] = this.product[key]
                });

                this.$emit('submit', {
                    ...body,
                    ...lightBody,
                    courses: this.selectedCourses.map(course => course.id),
                });
            },
        }
    }
</script>
