import AuthLogic from 'mg-js-core/lib/logics/AuthLogic'

export default class RouterLogic {
    static redirectAdmin(to, from, next) {
        if (AuthLogic.hasAccessToken()
            && (AuthLogic.hasRole(['admin', 'teacher', 'super-admin', 'statistic', 'statistic-advanced', 'marketing', 'support']))) {
            console.warn('redirect to admin')
            if (to.name === 'admin-layout') {
                next({name: 'admin-dashboard'})
            } else {
                next()
            }
        } else if (AuthLogic.hasAccessToken()
            && AuthLogic.hasRole('member')) {
            console.warn('redirect to member')
            // next({name: 'member-dashboard'})
            AuthLogic.logout().then(() => window.location.replace(`${process.env.VUE_APP_FRONT_HOST}`))

        } else {
            AuthLogic
                .logout()
                .then(() => next({
                    name: 'login',
                    query: {redirect: to.fullPath}
                }))
        }
    }

    static userCanAccess(to) {
        if(to.meta && to.meta.roles){
            return AuthLogic.hasRole(to.meta.roles)
        }

        return true;
    }
}

