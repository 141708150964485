<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dark dense color="secondary">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-card-text>
            <v-row  >
                <v-col cols="12">
                    <v-text-field
                            v-validate="'required'"
                            v-model="productType.title"
                            :disabled="disabled"
                            :error-messages="errors.collect('title')"
                            label="Titre *"
                            name="title"
                            data-vv-title="title"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <mg-editor
                            ref="mgEditorDescription"
                            v-model="productType.description"
                            label="Description *"
                            v-validate="'required'"
                            :error-messages="errors.collect('description')"
                            data-vv-name="description">
                    </mg-editor>
                </v-col>
                <v-col cols="12">
                    <mg-editor
                            ref="mgEditorDescriptionMpp"
                            v-model="productType.description_mpp"
                            label="Description MyPiano *"
                            v-validate="'required'"
                            :error-messages="errors.collect('description_mpp')"
                            data-vv-name="description_mpp">
                    </mg-editor>
                </v-col>
                <v-col cols="12" sm="6" v-if="showEnabled">
                    <v-checkbox
                            v-validate
                            v-model="productType.enabled"
                            :disabled="disabled"
                            :error-messages="errors.collect('enabled')"
                            label="Activé"
                            data-vv-name="enabled"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.enabled.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-checkbox
                            v-validate
                            v-model="productType.full_courses_access"
                            :error-messages="errors.collect('full_courses_access')"
                            label="Accès illimité aux cours"
                            data-vv-name="full_courses_access"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.full_courses_access.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import _ from 'lodash'

    export default {
        name: 'product-type-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            value: {
                type: Object,
                required: true,
            },

            toolbarTitle: String,

            dialog: Boolean,
            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            isDialog: Boolean,
            loading: Boolean,
            disabled: Boolean,

            showToolbar: Boolean,

            showEnabled: Boolean,
        },

        data: () => ({}),

        mounted() {

        },

        watch: {
            productType: {
                handler() {
                    this.resetValidation()
                    this.syncEditor()
                },
                deep: true
            },
        },

        computed: {
            productType: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },
        },

        methods: {
            syncEditor() {
                setTimeout(() => {
                    this.$refs.mgEditorDescription.refreshContent()
                    this.$refs.mgEditorDescriptionMpp.refreshContent()
                }, 250)
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            submit() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        let body = {};

                        _.forIn(this.fields, (value, key) => {
                            body[key] = this.productType[key]
                        });

                        if(this.productType.id){
                            body.id = this.productType.id;
                        }

                        this.$emit('submit', body);
                    }
                })
            },
        }
    }
</script>
