<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-card-text>
            <v-container grid-list-md class="pa-0 container--fluid">
                <v-row class="pt-10 font-weight-bold text-l">
                    <v-col cols="12" class="text-center">
                        {{ getSkus(order) }} <template v-if="(order.amount_admin !== null ? order.amount_admin : order.amount) / 100 > 0">({{ (order.amount_admin ? order.amount_admin : order.amount) / 100 }}€)</template>
                    </v-col>
                    <v-col cols="12">
                        <mg-datepicker
                            label="Date d'émission "
                            v-model="order.date_admin"
                            v-validate
                            name="date_admin"
                            data-vv-name="date_admin">
                        </mg-datepicker> 
                    </v-col>
                </v-row>
                <v-row v-for="(orderItem) in order.orderItems.data" :key="orderItem.id">
                    <v-col cols="8">
                        <v-text-field
                                v-validate="''"
                                v-model="orderItem.sku_title_admin"
                                ref="skuInput"
                                :error-messages="errors.collect('sku')"
                                label="SKU"
                                name="sku"
                                data-vv-title="sku_title_admin"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                                v-validate="'decimal:0'"
                                v-model="orderItem.sku_price_admin"
                                ref="priceInput"
                                :error-messages="errors.collect('sku_price_admin')"
                                label="Prix "
                                name="sku_price_admin"
                                data-vv-title="sku_price_admin"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <mg-datepicker
                            label="Date "
                            v-model="orderItem.date_admin"
                            v-validate
                            name="date"
                            data-vv-name="date">
                        </mg-datepicker> 
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-text :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="isLoading"
                   @click="submit">
                   {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import _ from 'lodash'

    export default {
        name: 'order-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,

            dialog: Boolean,
            isDialog: Boolean,
            isLoading: Boolean,
            disabled: Boolean,

            value: Object,

            showToolbar: {
                type: String,
                default: null
            },
            dark: Boolean,
        },

        data: () => ({
            
        }),


        // mounted() {
        //     this.syncValue(this.value)
        // },

        // watch: {
        //     order(newValue) {
        //         if (newValue) {
        //             this.syncValue(newValue)
        //         }
        //     }
        // },

        computed: {
            order: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },
        },

        methods: {
            getSkus(order) {
                const text = order.skus.data.map((sku) => {
                    return sku.title;
                }).join(",");
                return text || '-';
            },
            // syncValue(order) {
            //     this.orderInterval = this.getOrderInterval(order)

            //     if (order.product) {
            //         this.order.product_id = order.product.data.id
            //     }

            //     if (order.course) {
            //         this.order.course_id = order.course.data.id
            //     }

            //     if (order.courseModules && order.courseModules.data) {
            //         this.courseModulesSelectedValue = _.map(
            //             order.courseModules.data,
            //             courseModule => courseModule.id
            //         )
            //     }

            // },

            validateOrderForm() {
                return this.$validator.validateAll();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.MgAlertComponent.displayAlert();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            submit() {
                this.MgAlertComponent.closeAlert();

                this.validateOrderForm().then(isValid => {
                    if (isValid) {
                        let body = {};
                        _.forIn(this.fields, (value, key) => {
                            if (value.dirty) {
                                body[key] = this.order[key];
                            }
                            if (this.order.orderItems.data) {
                                var items = [];
                                var total = 0;
                                var price = 0;
                                this.order.orderItems.data.forEach(element => {
                                    price = element.sku_price_admin ?? element.sku.data.price;
                                    items.push({
                                        'id' : element.id,
                                        'date_admin' : element.date_admin ?? null,
                                        'sku_price_admin' : element.sku_price_admin ?? null, 
                                        'sku_title_admin' : element.sku_title_admin ?? null, 
                                    }); 
                                    total = total + parseInt(price);
                                });
                                body['orderItems'] = items;
                                body['amount_admin'] = total;
                            } 
                        });
                        this.$emit('submit', this.order, body);
                    }
                })
            },

        }
    }
</script>
