<template>
    <v-card flat>
        <v-app-bar flat dense :dark="dark" :color="toolbar" v-if="showToolbar">
            <v-btn icon
                   :dark="dark"
                   v-if="isDialog"
                   @click="$emit('update:dialog', false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <template v-if="canEdit">
                <v-menu offset-y bottom left v-if="!planIsSortable">
                    <template v-slot:activator="{ on }">
                        <v-btn icon fab small v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense class="py-0">
                        <v-list-item @click="$emit('update:dialogAdd', true)" v-if="canAdd">
                            <v-list-item-title>Ajouter</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="sortPlans">
                            <v-list-item-title>Trier</v-list-item-title>
                        </v-list-item>
                    </v-list>

                </v-menu>

                <template v-if="planIsSortable">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="cancelSortPlans">
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Annuler</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="submitSortPlans">
                                <v-icon small>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Enregistrer</span>
                    </v-tooltip>
                </template>
            </template>
        </v-app-bar>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-list v-if="plans.length > 0" class="py-0">
            <draggable v-model="plans"
                       class="dragArea"
                       :options="{sort: planIsSortable}">
                <v-list-item
                        :class="{'grabable' : planIsSortable}"
                        class="default-cursor"
                        v-for="plan in plans"
                        :ripple="false"
                        :key="plan.id">
                    <v-list-item-action
                            v-if="planIsSortable"
                            class="grabable">
                        <v-icon>mdi-drag</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>{{plan.title}}
                            <v-icon
                                    v-if="plan.vipPlan && plan.vipPlan.data && plan.vipPlan.data.deleted_at === null"
                                    color="warning" small style="padding-bottom: 2px">
                                mdi-star
                            </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{generateSubTitle(plan)}}</v-list-item-subtitle>
                    </v-list-item-content>


                    <v-list-item-action v-if="!plan.enabled">
                        <v-chip color="danger" disabledoutlined>Désactivé</v-chip>
                    </v-list-item-action>

                    <v-list-item-icon v-if="canEdit || canDelete || canShow">

                        <v-menu offset-y bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon fab small v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item v-if="canShow" @click="$emit('click:showPlan', plan)">
                                    <v-list-item-title>Voir</v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="canEdit" @click="openEditModal(plan)">
                                    <v-list-item-title>Editer</v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="canEdit" @click="togglePlan(plan)">
                                    <v-list-item-title>{{plan.enabled ? 'Désactiver' : 'Activer'}}</v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="canDelete" @click="openDialogDeleteConfirmation(plan)">
                                    <v-list-item-title class="primary--text">Supprimer</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-icon>

                </v-list-item>

            </draggable>
            <v-divider></v-divider>

        </v-list>

        <template v-else-if="canAdd">
            <v-card-text class="text-center">
                <v-btn color="secondary" @click="$emit('update:dialogAdd', true)">Ajouter un abonnement</v-btn>
            </v-card-text>
        </template>

        <template v-else>
            <v-card-text class="text-center">
                Aucun abonnement
            </v-card-text>
        </template>

        <v-dialog v-if="canAdd" :value="dialogAdd" @input="$emit('update:dialogAdd', $event)" max-width="800px">
            <plan-create-stepper
                    ref="planCreateStepper"

                    showToolbar="secondary"
                    toolbarTitle="Créer un abonnement"
                    :dark="dark"

                    canEditStripeId

                    :courseModules="courseModules"
                    @update:courseModules="$emit('update:courseModules', $event)"

                    :courseModulesSelected="courseModulesSelected"
                    @update:courseModulesSelected="$emit('update:courseModulesSelected', $event)"

                    :search="productsFiltersSearch"
                    @update:search="$emit('update:productsFiltersSearch', $event)"

                    :availableProducts="availableProducts"
                    :step.sync="stepperIndex"

                    :parentType="stepperParentType"
                    @update:parentType="$emit('update:stepperParentType', $event)"

                    :availableCourses="availableCourses"

                    :selectedCourses="selectedCourses"
                    @update:selectedCourses="$emit('update:selectedCourses', $event)"

                    :searchCourse="availableCoursesSearch"
                    @update:searchCourse="$emit('update:availableCoursesSearch', $event)"

                    :value="currentPlan"
                    @input="$emit('update:currentPlan', $event)"

                    :product.sync="product"

                    :loading.sync="newPlanLoading"

                    @submit="addOnePlan">
            </plan-create-stepper>
        </v-dialog>

        <v-dialog
                v-if="canEdit"
                :value="dialogUpdate"
                @input="$emit('update:dialogUpdate', $event)"
                max-width="800">
            <plan-form
                    ref="planEditForm"
                    dark
                    toolbarTitle="Éditer un abonnement"
                    showToolbar="secondary"
                    isDialog
                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    submitBtnColor="secondary lighten-1"

                    :dialog="dialogUpdate"
                    @update:dialog="$emit('update:dialogUpdate', $event)"

                    :isLoading="isLoadingEdit"
                    @update:isLoading="$emit('update:isLoadingEdit', $event)"

                    :value="currentPlan"
                    @input="$emit('update:currentPlan', $event)"

                    :raw="false"

                    @submit="editOnePlan"

                    :showCourseModules="!isFullAccessProduct"


                    :showProducts.sync="showProducts"


                    :selectedCourses="selectedCourses"
                    @update:selectedCourses="$emit('update:selectedCourses', $event)"
                    :availableProducts="availableProducts"
                    :showCourses.sync="showCourses"
                    :availableCourses="availableCourses"
                    :product.sync="product"

                    :courseModules="courseModules"
                    @update:courseModules="$emit('update:courseModules', $event)"

                    :courseModulesSelected="courseModulesSelected"
                    @update:courseModulesSelected="$emit('update:courseModulesSelected', $event)"
                    showVip
            >

            </plan-form>
        </v-dialog>

        <v-dialog
                :value="dialogDeleting"
                @input="$emit('update:dialogDeleting', $event)"

                hide-overlay
                persistent

                width="300">
            <v-card dark>
                <v-card-text class="pt-5">
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mt-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
                v-if="canDelete"
                :value="dialogDeleteConfirmation"
                @input="$emit('update:dialogDeleteConfirmation', $event)"
                max-width="300">
            <v-card class="text-center">
                <v-card-title class="headline justify-center">
                    Êtes-vous sur ?
                </v-card-title>
                <v-card-title class="subtitle-2 justify-center">
                    La suppresion est irréversible.
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" flat @click="$emit('update:dialogDeleteConfirmation', false)">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn flat @click="deleteOnePlan()">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import ProductLogic from "mg-js-core/lib/logics/ProductLogic";
    import PlanLogic from "mg-js-core/lib/logics/PlanLogic";
    import draggable from 'vuedraggable'
    import _ from 'lodash'

    export default {
        name: 'plan-list',

        components: {
            draggable,
        },

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            /* plans */
            value: {
                type: Array,
                required: true,
            },

            /* general */
            toolbar: String,
            showToolbar: Boolean,
            toolbarTitle: String,
            dark: Boolean,
            dialog: Boolean,
            isDialog: Boolean,
            canAdd: Boolean,
            canEdit: Boolean,
            canDelete: Boolean,
            canShow: Boolean,

            /* add (stepper) */
            dialogAdd: Boolean,
            step: {},
            newPlan: {
                type: Object,
                default() {
                    return PlanLogic.getEmptyObject()
                }
            },
            newPlanLoading: Boolean,

            /* edit - form */
            dialogUpdate: Boolean,
            isLoadingEdit: Boolean,
            currentPlan: Object,
            showProducts: Boolean,
            productsFiltersSearch: String,
            availableProducts: Array,
            stepperParentType: {
                type: String,
                default: 'product',
            },
            courseModules: Array,
            courseModulesSelected: Array,
            showCourses: Boolean,
            availableCourses: Array,
            selectedCourses: Array,
            availableCoursesSearch: String,
            product: Object,
            /* delete */
            dialogDeleting: Boolean,
            dialogDeleteConfirmation: Boolean,

            planIsSortable: Boolean,

        },

        data: () => ({
            newProduct: ProductLogic.getEmptyObject(),
            plansRaw: null,
        }),

        mounted() {

        },

        computed: {
            plans: {
                get() {
                    return this.value
                },
                set(plans) {
                    this.$emit('input', plans)
                }
            },

            hasClickShowPlan() {
                return !!(this.$listeners && this.$listeners['click:showPlan'])
            },

            stepperIndex: {
                get() {
                    return this.step
                },
                set(step) {
                    this.$emit('update:step', step)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            planEditFormComponent() {
                return this.$refs.planEditForm
            },

            planAddFormComponent() {
                return this.$refs.planCreateStepper
            },

            isFullAccessProduct() {
                return ProductLogic.isFullAccessProduct(this.product)
            },
        },

        watch: {},

        methods: {
            openEditModal(plan) {
                this.$emit('update:dialogUpdate', true);

                const vipPlan = plan.vipPlan ? plan.vipPlan.data : null;

                this.$emit('update:currentPlan', Object.assign(PlanLogic.getEmptyObject({
                    vip_allowed: !!vipPlan && vipPlan.deleted_at === null,
                    vip_price: vipPlan ? vipPlan.price : null
                }), plan));

                this.$emit('update:availableProducts', Object.assign([], [{
                    id: plan.product.data.id,
                    title: plan.product.data.title,
                }]));
            },

            togglePlan(plan) {
                this.$emit('togglePlan', plan.id, {
                    enabled: !plan.enabled
                });
                // this.$emit('editOnePlan', plan.id, {
                //     enabled: !plan.enabled
                // });
            },

            editOnePlan(planId, body) {
                this.$emit('editOnePlan', planId, body);
            },

            openDialogDeleteConfirmation(plan) {
                this.$emit('update:currentPlan', Object.assign(PlanLogic.getEmptyObject(), plan));
                this.$emit('update:dialogDeleteConfirmation', true);
            },

            deleteOnePlan() {
                this.$emit('deleteOnePlan', this.currentPlan);
            },

            addOnePlan(plan) {
                this.$emit('addOnePlan', plan);
            },

            generateSubTitle(plan) {
                let subtitle = '';

                console.warn(plan.currency === 'EUR' ? '€' : '$')

                subtitle = subtitle.concat(plan.price)
                subtitle = subtitle.concat(plan.currency === 'EUR' ? '€' : '$')

                let interval = '';

                if (plan.interval === 'week') {
                    interval = 'semaine'
                } else if (plan.interval === 'year') {
                    interval = 'année'
                } else if (plan.interval === 'month' && plan.interval_count === 6) {
                    interval = 'semestre'
                } else if (plan.interval === 'month' && plan.interval_count === 3) {
                    interval = 'trimestre'
                } else {
                    interval = 'mois'
                }

                return subtitle.concat('/' + interval);
            },

            sortPlans() {
                this.$emit('update:planIsSortable', true)
                this.plansRaw = Object.assign([], this.plans)
            },
            cancelSortPlans() {
                this.$emit('update:planIsSortable', false)
                this.plans = Object.assign([], this.plansRaw)
            },

            submitSortPlans() {
                let newOrder = _.map(this.plans, (plan, index) => {
                    return {
                        id: plan.id,
                        position: index + 1
                    }
                })

                this.$emit('submitSortPlans', newOrder)
            },
        }
    }
</script>
