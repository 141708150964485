<template>
    <router-view/>
</template>

<script>
    import {EventBus} from 'mg-js-core/lib/services/Request'
    import AuthLogic from "mg-js-core/lib/logics/AuthLogic";
    import _ from 'lodash';

    export default {
        name: 'App',

        mounted() {
            EventBus.$on('401-unauthorized', () => {
                this.redirectToLogin()
            });

            EventBus.$on('401-need-information', () => {
                this.redirectToInformation()
            });

            const accessToken = AuthLogic.getTokens()

            if (accessToken && Object.prototype.hasOwnProperty.call(accessToken, "access_token")) {
                AuthLogic.loadMe()
            }
        },

        methods: {
            redirectToLogin: _.debounce(function () {
                if (this.$route.name !== 'login') {
                    this.$router.push({name: 'login', query: {redirect: this.$route.fullPath}})
                }
            }, 300),
            redirectToInformation: _.debounce(function () {
                if (this.$route.name !== 'information') {
                    this.$router.push({name: 'information', query: {redirect: this.$route.fullPath}})
                }
            }, 300),
        }
    }
</script>
