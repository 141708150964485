<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :color="toolbarColor">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template>
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           v-if="isDialog"
                           @click="$emit('update:dialog', false);">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>


        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-card-text>
            <v-data-table
                    :options.sync="currentPagination"
                    :server-items-length="totalItems"
                    :footer-props="{
                        'items-per-page-options' : [20, 50, 100, 150]
                    }"
                    :headers="headers"
                    :loading="loading"
                    :hide-default-footer="hideDefaultFooter"
                    item-key="id"
                    :items="subscriptions">
                <template v-slot:item.status="{ item }">
                    {{ translateStatus(item) }}
                </template>
                <template v-slot:item.name="{ item }">
                    {{ item.name }}
                    <v-chip
                        small
                        v-if="item.vipPlans && item.vipPlans.data && item.vipPlans.data.length"
                        style="height: 20px;"
                        class="default lighten-4 font-weight-bold">
                        VIP
                    </v-chip>
                </template>
                <template v-slot:item.ends_at="{ item }">
                    {{ item.ends_at ? Helpers.parseDate(item.ends_at, 'DD/MM/YYYY') : '-'}}
                </template>
                <template v-slot:item.real_price="{ item }">
                    {{generateInterval(item) }}
                </template>
                <template v-slot:item.creditCard="{ item }">
                    <template v-if="item.creditCard && item.creditCard.data">
                        {{ item.creditCard.data.brand }} ****{{item.creditCard.data.last4}}
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>

                <template v-slot:item.statusPayment="{ item }">
                    <v-btn small color="primary"
                           v-if="item.status === 'incomplete' && item.pi_status === 'requires_payment_method'"
                           @click="openChangeCardModal(item)">Un moyen de
                        paiement valide est requis
                    </v-btn>

                    <v-btn small color="primary"
                           v-else-if="item.status === 'incomplete' && item.pi_status === 'requires_action'"
                           @click="completeRequiredAction(item)">Valider mon moyen de paiement
                    </v-btn>
                </template>

                <template v-slot:item.action="{ item }">
                    <v-menu
                            v-if="!item.ends_at || $moment(item.ends_at).isAfter($moment.now())"
                            offset-y bottom left>
                        <template v-slot:activator="{ on }">
                            <v-btn icon fab small v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list dense class="py-0" v-if="item.from_stripe">
                            <!--                            <v-list-item-->
                            <!--                                    v-if="!item.ends_at"-->
                            <!--                                    @click="">-->
                            <!--                                <v-list-item-title>-->
                            <!--                                    TODO - Modifier (on/off VIP + drip content + fin sku)-->
                            <!--                                </v-list-item-title>-->
                            <!--                            </v-list-item>-->

                            <!--                            <v-list-item-->
                            <!--                                    v-if="!item.ends_at"-->
                            <!--                                    @click="">-->
                            <!--                                <v-list-item-title>-->
                            <!--                                    TODO - Désactiver l'option VIP-->
                            <!--                                </v-list-item-title>-->
                            <!--                            </v-list-item>-->

                            <v-divider v-if="!item.ends_at"></v-divider>

                            <!-- <v-list-item
                                    v-if="!item.ends_at"
                                    @click="openChangeCardModal(item)">
                                <v-list-item-title>
                                    Changer de moyen de paiement
                                </v-list-item-title>
                            </v-list-item> -->

                            <v-divider v-if="!item.ends_at"></v-divider>

                            <v-list-item
                                    v-if="!item.ends_at"
                                    @click="openConfirmationModal(item)">
                                <v-list-item-title>
                                    Annuler la souscription
                                </v-list-item-title>
                            </v-list-item>


                            <v-list-item
                                    v-if="$moment(item.ends_at).isAfter($moment.now())"
                                    @click="resumeSubscription(item)">
                                <v-list-item-title>
                                    Réactiver la souscription
                                </v-list-item-title>
                            </v-list-item>

                            <v-divider></v-divider>

                            <v-list-item
                                    @click="openConfirmationModal(item, true)">
                                <v-list-item-title class="primary&#45;&#45;text">
                                    Annuler la souscription immédiatement
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                        <v-list dense class="py-0" v-else-if="item.from_paypal">
                            <v-list-item
                                    @click="openConfirmationModal(item, true)">
                                <v-list-item-title class="primary&#45;&#45;text">
                                    Annuler la souscription immédiatement
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                        <v-list dense class="py-0" v-else-if="item.custom_id">
                            <v-list-item
                                    @click="openConfirmationModal(item, true)">
                                <v-list-item-title class="primary&#45;&#45;text">
                                    Annuler la souscription immédiatement
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                        <v-list dense class="py-0" v-else>
                            <v-list-item>
                                <v-list-item-title>
                                    Merci de contacter le support <br>pour toutes actions
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>

            <!-- <v-dialog :value="changeDefaultSourceDialog"
                      v-if="changeDefaultSourceDialog"
                      @input="$emit('update:changeDefaultSourceDialog', $event)"
                      max-width="900">

                <v-card>
                    <v-app-bar flat dense :color="toolbarColor" dark>
                        <v-toolbar-title>
                            {{ dialogAddCreditCard
                            ? 'Ajouter un moyen de paiement'
                            : 'Selectionner le moyen de paiement'
                            }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom v-if="creditCards.length">
                            <template v-slot:activator="{ on }">
                                <v-menu v-on="on" offset-y bottom left>
                                    <template v-slot:activator="menuActivator">
                                        <v-btn icon fab small v-on="menuActivator.on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense class="py-0">
                                        <v-list-item @click="selectNewCard"
                                                     v-if="creditCards.length > 1 && dialogAddCreditCard">
                                            <v-list-item-title>
                                                Choisir une autre carte
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-divider v-if="creditCards.length > 1 && !dialogAddCreditCard"></v-divider>
                                        <v-list-item @click="addNewCard" v-if="!dialogAddCreditCard">
                                            <v-list-item-title>
                                                Ajouter une nouvelle carte
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                            </template>
                            <span>Options</span>
                        </v-tooltip>
                    </v-app-bar>

                    <mg-alert ref="mgAlertChangeDefaultSource"></mg-alert>

                    <mg-credit-card
                            v-if="dialogAddCreditCard || !creditCards.length"
                            ref="creditCardForm"
                            v-model="currentCreditCard"
                    >
                    </mg-credit-card>

                    <credit-card-list
                            v-else-if="dialogSelect"
                            ref="creditCardList"
                            :value="creditCards"
                            :dialog="changeDefaultSourceDialog"
                            :toolbarColor="toolbarColor"
                            :currentCreditCard.sync="currentCreditCard"
                            @click:row="currentCreditCard = $event">
                    </credit-card-list>

                    <v-card-text class="text-center pa-3">
                        <v-btn :color="toolbarColor"
                               :disabled="dialogSelect && currentCreditCard.id === null"
                               :loading="changeDefaultSourceLoading"
                               @click="changeDefaultSource">
                            Enregistrer
                        </v-btn>
                    </v-card-text>
                </v-card>

            </v-dialog> -->

            <v-dialog :value="deleteDialog" @input="$emit('update:deleteDialog', $event)" max-width="550">
                <v-card>
                    <v-card-title class="headline justify-center primary--text">Attention !</v-card-title>

                    <v-card-title class="body-2 pb-0 font-weight-regular justify-center">
                        <p class="text-center">
                            La demande de désabonnement à <span
                                class="font-weight-bold">{{ currentSubscription.name}}</span> sera prise immédiatement.
                        </p>
                    </v-card-title>

                    <template v-if="!cancelNow">
                        <v-card-title class="body-2 py-0 font-weight-regular justify-center">
                            Les services liés resteront actifs jusqu'au :
                        </v-card-title>

                        <v-card-title class="body-2 font-weight-regular justify-center">
                            <p class="font-weight-bold body-1" v-if="getEndsDate">
                                {{getEndsDate}}
                            </p>
                            <p v-else>
                                <v-progress-circular
                                        size="15"
                                        :width="2"
                                        indeterminate>
                                </v-progress-circular>
                            </p>
                        </v-card-title>

                        <v-divider></v-divider>

                        <v-card-title class="body-2 text--accent-1 pb-0 font-weight-regular">
                            <p class="text-center text--accent-1">
                                Avant cette date, si vous changez d'avis, vous pouvez réactiver votre souscription.
                            </p>
                        </v-card-title>
                        <v-card-title class="body-2 text--accent-1 py-0 font-weight-regular">
                            <p class="text-center">
                                Après cette date, vous pourrez toujours retrouver votre progression et l'accès à vos
                                leçons en vous réinscrivant (comme si vous faisiez l'inscription pour la première fois).
                            </p>
                        </v-card-title>

                        <v-divider></v-divider>
                    </template>
                    <template v-else>
                        <div class="text-center pb-3">
                            Tous les services liés seront supprimés immédiatement.
                        </div>
                    </template>

                    <v-card-actions class="">
                        <v-btn @click="closeConfirmationModal">Annuler</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" @click="cancelSubscription()">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                    :value="dialogDeleting"
                    @input="$emit('update:dialogDeleting', $event)"
                    hide-overlay
                    persistent
                    width="300">
                <v-card :color="dialogDeletingColor" dark>
                    <v-card-text class="pt-5">
                        <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card-text>

    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import SubscriptionLogic from "mg-js-core/lib/logics/SubscriptionLogic";
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import CreditCardLogic from "mg-js-core/lib/logics/CreditCardLogic";
    import series from 'async/series';

    export default {
        name: 'subscription-list',

        $_veeValidate: {
            validator: 'new',
        },
        props: {
            value: {
                type: Array,
                required: true,
            },
            filters: {
                type: Object,
            },
            pagination: {
                type: Object,
            },

            totalItems: Number,

            toolbarTitle: String,
            showToolbar: Boolean,
            hideDefaultFooter: Boolean,

            toolbarColor: {
                type: String,
                default: 'accent'
            },
            dialog: Boolean,
            isDialog: Boolean,
            canCancelNow: Boolean,
            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            loading: Boolean,

            deleteDialog: Boolean,
            dialogDeleting: Boolean,

            /* credit card */
            creditCards: {
                type: Array,
                default: () => ([])
            },
            dialogAddCreditCard: Boolean,
            changeDefaultSourceDialog: Boolean,
            changeDefaultSourceLoading: Boolean,

            dialogDeletingColor: String,

            getCurrentEndDate: {
                type: Function
            },

            showActions: Boolean,
        },

        data: () => ({
            Helpers,
            PageStore,


            // deleteDialog: false,
            cancelNow: false,
            currentSubscription: SubscriptionLogic.getEmptyObject(),
            /* subscriptions */
            // pagination: Helpers.initPagination(20, 1, 'created_at', true),
            // filters: {
            //     search: '',
            //     include: 'plans,creditCard'
            // },

            /* credits card */
            currentCreditCard: CreditCardLogic.getEmptyObject(),
            dialogSelect: true,
        }),

        computed: {
            subscriptions: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            currentPagination: {
                get() {
                    return this.pagination;
                },
                set(value) {
                    this.$emit('update:pagination', value)
                }
            },
            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            mgAlertChangeDefaultSourceComponent() {
                return this.$refs.mgAlertChangeDefaultSource
            },

            headers() {
                let headers = [
                    {
                        text: 'Abonnement',
                        align: 'left',
                        sortable: true,
                        value: 'name'
                    },
                    {
                        text: 'Statut',
                        align: 'left',
                        sortable: true,
                        value: 'status'
                    },
                    {
                        text: 'Date de fin d\'abonnement',
                        align: 'left',
                        sortable: true,
                        value: 'ends_at'
                    },
                    {
                        text: 'Prix',
                        align: 'left',
                        sortable: true,
                        value: 'real_price'
                    },
                    {
                        text: 'Moyen de paiement',
                        align: 'left',
                        sortable: false,
                        value: 'creditCard'
                    },
                    {
                        align: 'right',
                        sortable: false,
                        value: 'statusPayment',
                    },

                ];

                if (this.showActions) {
                    headers.push({text: 'Actions', value: 'action', sortable: false})
                }

                return headers;
            },

            creditCardFormComponent() {
                return this.$refs.creditCardForm
            },

            getEndsDate() {
                return this.currentSubscription && this.currentSubscription.current_period_end_estimation
                    ? this.$moment(this.currentSubscription.current_period_end_estimation).format('LL')
                    : null
            },
        },

        mounted() {

        },

        watch: {
            dialogDeleting(value) {
                if (!value) {
                    this.currentSubscription = SubscriptionLogic.getEmptyObject()
                    this.cancelNow = false
                }
            },
            changeDefaultSourceDialog(value) {
                if (!value) {
                    this.currentCreditCard = CreditCardLogic.getEmptyObject()
                    this.currentSubscription = SubscriptionLogic.getEmptyObject();
                }
            },
        },

        methods: {
            openConfirmationModal(subscription, cancelNow = false) {
                this.$emit("update:deleteDialog", true)

                this.cancelNow = cancelNow
                this.currentSubscription = subscription;

                this.$nextTick(() => {
                    this.getCurrentEndDate(this.currentSubscription)
                })
            },

            closeConfirmationModal() {
                this.$emit("update:deleteDialog", false)
                this.cancelNow = false
                this.currentSubscription = SubscriptionLogic.getEmptyObject();
            },

            cancelSubscription() {
                this.$emit("cancelSubscription", this.currentSubscription, this.cancelNow)
            },

            resumeSubscription(currentSubscription) {
                this.$emit("resumeSubscription", currentSubscription)
            },

            translateStatus(subscription) {
                if (subscription.status === 'active') {
                    return 'Actif' + (subscription.custom_id ? ' (Ajouté par un admin ou par Webhook ActiveCampaign)' : '')
                } else if (subscription.status === 'incomplete') {
                    return 'Sera actif dans quelques minutes'
                } else if (subscription.status === 'canceled') {
                    return 'Annulé'
                } else {
                    return subscription.status
                }
            },
            generateInterval(subscription) {
                let plans = subscription.plans ? subscription.plans.data : [];

                let subtitle = '';
                let subscriptionCurrency = '';
                let subscriptionInterval = '';
                let subscriptionIntervalCount = '';

                let subscriptionPrice = subscription.real_price;

                if (plans.length) {
                    subscriptionCurrency = plans[0].currency;
                    subscriptionInterval = plans[0].interval;
                }

                subtitle = subtitle.concat(subscriptionPrice)
                subtitle = subtitle.concat(subscriptionCurrency === 'EUR' ? '€' : '$')
                let interval = '';

                if (subscriptionInterval === 'week') {
                    interval = 'semaine'
                } else if (subscriptionInterval === 'year') {
                    interval = 'année'
                } else {
                    interval = 'mois'
                }

                return subtitle.concat('/' + subscriptionIntervalCount + ' ' + interval);
            },

            completeRequiredAction(subscription) {
                this.$emit("completeRequiredAction", subscription)
            },

            /* CREDIT CARDS */
            addNewCard() {
                this.$emit("update:dialogAddCreditCard", true)
                this.dialogSelect = false
                this.currentCreditCard = CreditCardLogic.getEmptyObject()
            },

            selectNewCard() {
                if (this.currentSubscription.creditCard
                    && this.currentSubscription.creditCard.data) {
                    this.currentCreditCard = Object.assign(this.currentCreditCard, this.currentSubscription.creditCard.data);
                } else {
                    this.currentCreditCard = CreditCardLogic.getEmptyObject()
                }

                this.$emit("update:dialogAddCreditCard", false)
                this.dialogSelect = true
            },

            openChangeCardModal(subscription) {
                this.$emit("update:changeDefaultSourceDialog", true)
                this.currentSubscription = subscription;
                if (subscription.creditCard && subscription.creditCard.data) {
                    this.currentCreditCard = Object.assign(this.currentCreditCard, subscription.creditCard.data);
                }
            },

            changeDefaultSource() {

                let body = {}

                series([
                    (callback) => {
                        if (this.currentCreditCard.id) {
                            body.credit_card_id = this.currentCreditCard.id
                            callback()
                        } else {
                            this.creditCardFormComponent.validateForm().then(isValid => {
                                if (isValid) {

                                    body = {
                                        ...body,
                                        ...this.creditCardFormComponent.getBody(),
                                    }

                                    callback()
                                }

                            })
                        }
                    },
                    () => {
                        this.$emit("update:changeDefaultSourceLoading", true)
                        this.$emit("changeDefaultSource", this.currentSubscription, body)
                    }
                ]);
            },
        }
    };
</script>
