<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat :dark="dark" color="secondary" dense>
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template>
                    <v-spacer></v-spacer>


                    <v-menu offset-y bottom left>
                        <template v-slot:activator="{ on }">
                            <v-btn icon fab small v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense class="py-0">
                            <v-list-item @click="openDialogDeleteConfirmation">
                                <v-list-item-title class="primary--text">
                                    Supprimer
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn icon dark
                           v-if="isDialog"
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-container fluid class="px-5 py-2">
            <v-row>
                <v-col cols="12">
                    <div class="caption">
                        Logo
                    </div>
                </v-col>

                <v-col cols="12" sm="3">
                    <v-card flat>
                        <input
                                v-validate
                                v-show="false"
                                id="files"
                                type="file"
                                accept="image/*"
                                name="file"
                                ref="logoInput"
                                @change="submitLogo"
                                :multiple="false"/>

                        <img
                                v-if="course.logo && course.logo.data"
                                width="50%"
                                style="min-height: 150px"
                                class="clickable"
                                @click="clickInput"
                                v-media-src="course.logo.data"/>
                        <v-btn
                                v-else
                                small
                                color="secondary"
                                @click="clickInput">
                            Ajouter
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <div class="caption">
                        Background
                    </div>
                </v-col>

                <v-col cols="12" sm="5">
                    <v-card flat>
                        <input
                                v-validate
                                v-show="false"
                                id="background"
                                type="file"
                                accept="image/*"
                                name="background"
                                ref="backgroundInput"
                                @change="submitBackground"
                                :multiple="false"/>

                        <img
                                v-if="course.background && course.background.data"
                                width="100%"
                                class="clickable"
                                @click="clickBackgroudInput"
                                v-media-src="course.background.data"/>
                        <v-btn
                                v-else
                                small
                                color="secondary"
                                @click="clickBackgroudInput">
                            Ajouter
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6">
                    <mg-select-platform
                        name="platform_code"
                        v-validate
                        v-model="course.platform_code">
                    </mg-select-platform>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="course.title"
                            :error-messages="errors.collect('title')"
                            label="Titre *"
                            name="title"
                            data-vv-title="title"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate
                            v-model="course.subtitle"
                            :error-messages="errors.collect('subtitle')"
                            label="Sous-titre"
                            name="subtitle"
                            data-vv-title="subtitle"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <mg-editor
                            v-model="course.description"
                            ref="mgEditorDescription"
                            label="Description"
                            v-validate
                            name="description"
                            data-vv-title="description">
                    </mg-editor>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select
                            v-model="currentTagStyles"
                            :items="tagStylesAvailable"
                            item-text="title"
                            item-value="id"
                            v-validate
                            multiple
                            :error-messages="errors.collect('tagStyles')"
                            data-vv-name="tagStyles"
                            label="Style(s)"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                            v-model="currentTagLevel"
                            :items="tagLevelsAvailable"
                            item-text="title"
                            item-value="id"
                            v-validate
                            :error-messages="errors.collect('tagLevel')"
                            data-vv-name="tagLevel"
                            label="Niveau"
                    ></v-select>
                </v-col>


                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                                v-model="currentInstruments"
                                :items="instrumentsAvailable"
                                item-text="name"
                                item-value="id"
                                v-validate
                                multiple
                                :error-messages="errors.collect('instruments')"
                                data-vv-name="instruments"
                                label="Instruments"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-select
                                v-model="productTypeSelected"
                                :items="productTypesAvailable"
                                item-text="title"
                                item-value="id"
                                return-object
                                v-validate="'required'"
                                :error-messages="errors.collect('product_type_id')"
                                data-vv-name="product_type_id"
                                label="Catégorie *"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-col cols="12" >
                    <v-text-field
                            v-model="course.active_campaign_id"
                            :error-messages="errors.collect('active_campaign_id')"
                            label="Active Campaign tag"
                            name="active_campaign_id"
                            data-vv-title="active_campaign_id"
                            v-validate
                            type="text"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" >
                    <v-text-field
                            v-model="course.learn_more_link"
                            :error-messages="errors.collect('learn_more_link')"
                            label="Lien en savoir plus"
                            name="learn_more_link"
                            data-vv-title="learn_more_link"
                            v-validate
                            type="text"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" class="pa-0">
                    <v-card flat tile color="transparent">
                        <v-card-title class="pb-0 title font-weight-bold">
                            Activer / Désactiver le cours
                        </v-card-title>
                        <v-card-text class="pb-0">
                            Permet d'activer ou de désactiver le cours.
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-checkbox
                            v-validate
                            v-model="course.enabled"
                            :error-messages="errors.collect('enabled')"
                            label="Activé"
                            data-vv-name="enabled"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.enabled.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" class="pa-0">
                    <v-card flat tile color="transparent">
                        <v-card-title class="pb-0 title font-weight-bold">
                            Notification déblocage automatique
                        </v-card-title>
                        <v-card-text class="pb-0">
                            Activer l'envoi d'une notification automatique lors ce que la prochaine leçon est automatiquement débloqué
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-checkbox
                            v-validate
                            v-model="course.notify_dripping"
                            :error-messages="errors.collect('notify_dripping')"
                            label="Oui"
                            data-vv-name="notify_dripping"
                            :true-value="true"
                            :false-value="false"
                            @change="fields.notify_dripping.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>
            </v-row>

            <v-card-actions class="justify-center">
                <v-btn color="secondary lighten-1"
                       :loading="loading"
                       :disabled="!isDirty"
                       @click="submit()">
                    {{submitBtn ? submitBtn : 'Enregistrer'}}
                </v-btn>
            </v-card-actions>
        </v-container>


        <v-dialog
                :value="dialogDeleting"
                @input="$emit('update:dialogDeleting', $event)"

                persistent
                width="300">
            <v-card dark>
                <v-card-text class="pt-5">
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <mg-confirmation-modal
                :dialog.sync="disableAlert"
                @delete="submit(true)"
                :confirmationText="course.enabled ? 'activer' : 'désactiver'"
                :maxWidth="400"
        >
            <p class="subtitle-1 font-weight-bold text-center">
                Attention, vous allez {{course.enabled ? 'activer' : 'désactiver'}} un cours
            </p>

            <ul style=" width: 80%;margin: auto;">
                <li v-if="course.enabled">
                    Tous les utilisateurs ayant souscrits à ce cours pourront de nouveau y accéder
                </li>
                <li v-else>
                    Tous les utilisateurs ayant souscrits à ce cours ne pourront plus y accéder
                </li>
            </ul>

        </mg-confirmation-modal>

        <v-dialog
                :value="dialogDeleteConfirmation"
                @input="$emit('update:dialogDeleteConfirmation', $event)"
                max-width="300">
            <v-card class="text-center">
                <v-card-title class="headline justify-center">
                    Êtes-vous sur ?
                </v-card-title>
                <v-card-title class="subtitle-2 justify-center">
                    La suppresion est irréversible.
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" flat @click="$emit('update:dialogDeleteConfirmation', false)">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn flat @click="deleteOneCourse">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import parallel from 'async/parallel';
    import _ from 'lodash'
    import MgSelectPlatform from "../platform/MgPlatformSelect";
    import ProductTypeLogic from "mg-js-core/lib/logics/ProductTypeLogic";

    export default {
        name: 'course-form',

        components: {MgSelectPlatform, MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            prices: Array,

            submitBtn: String,
            dialog: Boolean,
            isDialog: Boolean,
            loading: Boolean,
            disabled: Boolean,
            dark: Boolean,

            value: Object,

            showToolbar: Boolean,
            /* delete */
            canDelete: Boolean,
            dialogDeleting: Boolean,
            dialogDeleteConfirmation: Boolean,

            /* tags */
            tagStylesAvailable: Array,
            tagLevelsAvailable: Array,
            instrumentsAvailable: Array,
        },

        data: () => ({
            disableAlert: false,
            currentTagStyles: [],
            currentTagLevel: null,
            currentInstruments: null,
            productTypeSelected: null,
            productTypesAvailable: [],
        }),

        mounted() {
            this.syncCourse(this.value)
            this.getAllProductTypes();
        },

        watch: {
            value: {
                handler(value) {
                    this.syncCourse(value)
                },
                deep: true
            },
        },
        computed: {
            course: {
                get() {
                    return this.value;
                },
                set(value) {
                    console.error(value)
                    this.$emit('input', value)
                },
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },
        },

        methods: {
            syncCourse(course) {
                if (course.tagStyles && course.tagStyles.data) {
                    this.currentTagStyles = course.tagStyles.data.map(tagStyle => tagStyle.id)
                }

                if (course.tagLevel && course.tagLevel.data) {
                    this.currentTagLevel = course.tagLevel.data.id
                }

                if (course.instruments && course.instruments.data) {
                    this.currentInstruments = course.instruments.data.map(instrument => instrument.id)
                }

                if (course.productType && course.productType.data) {

                    this.productTypeSelected = course.productType.data ?? null;
                }

            },

            syncEditor() {
                setTimeout(() => {
                    this.$refs.mgEditorDescription.refreshContent()
                }, 250)
            },

            validateCourseForm() {
                return this.$validator.validateAll();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            openDialogDeleteConfirmation() {
                this.$emit('update:dialogDeleteConfirmation', true);
            },

            deleteOneCourse() {
                this.$emit('deleteOneCourse', this.course);
            },

            submit(force = false) {
                if (this.isDirty) {
                    this.mgAlertComponent.closeAlert();

                    parallel([
                        (callback) => {
                            this.validateCourseForm().then(isValid => {
                                isValid
                                    ? callback()
                                    : callback('invalid')
                            })
                        }
                    ], (err) => { //callback
                        if (!err) {

                            if (force === false
                                && this.fields.enabled.dirty
                            ) {
                                this.disableAlert = true;
                            } else {
                                let body = {
                                    id: this.course.id,
                                };

                                _.forIn(this.fields, (value, key) => {
                                    if(value.dirty){
                                        body[key] = this.course[key]
                                    }
                                });

                                if (this.currentTagStyles.length && this.fields.tagStyles.dirty) {
                                    body.tagStyles = this.currentTagStyles
                                } else if (body.tagStyles) {
                                    delete body.tagStyles;
                                }

                                if (this.currentTagLevel && this.fields.tagLevel.dirty) {
                                    body.tagLevel = this.currentTagLevel
                                } else if (body.tagLevel) {
                                    delete body.tagLevel;
                                }


                                if (this.currentInstruments && this.currentInstruments.length && this.fields.instruments && this.fields.instruments.dirty) {
                                    body.currentInstruments = this.currentInstruments
                                } else if (body.currentInstruments) {
                                    delete body.currentInstruments;
                                }

                                if (this.productTypeSelected) {
                                    body.product_type_id = this.productTypeSelected.id;
                                }

                                console.warn(body)

                                this.$emit('submit', body);
                            }
                        }
                    });
                }
            },


            submitLogo() {
                setTimeout(() => {
                    let formData = new FormData();

                    const file = this.$refs.logoInput.files[0];

                    formData.append('logo', file, file.name);

                    this.$emit('submitLogo', formData)

                    setTimeout(() => {
                        this.$refs.logoInput.files = null
                    }, 250)
                }, 100)
            },

            submitBackground() {
                setTimeout(() => {
                    let formData = new FormData();

                    const file = this.$refs.backgroundInput.files[0];
                    console.log(file)

                    formData.append('background', file, file.name);

                    this.$emit('submitBackground', formData)

                    setTimeout(() => {
                        this.$refs.backgroundInput.files = null
                    }, 250)
                }, 100)
            },

            clickInput() {
                this.$refs.logoInput.click()
            },

            clickBackgroudInput() {
                this.$refs.backgroundInput.click()
            },

            getAllProductTypes() {
                console.warn('getAllProductTypes')

                ProductTypeLogic
                    .getAll(Helpers.initPagination(20, 1, 'position', true), {
                        enabled: 1
                    })
                    .then(({data}) => {
                        this.productTypesAvailable = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })

            },
        }
    }
</script>
