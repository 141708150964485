<template>
    <v-card flat>
        <v-app-bar flat dense dark :color="toolbarColor" v-if="showToolbar">
            <v-btn icon
                   dark
                   v-if="isDialog"
                   @click="$emit('update:dialog', false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon v-if="canAdd"
                   @click="$emit('update:dialogAdd', true)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-app-bar>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-list two-line class="py-0" v-if="creditCards.length">
            <v-list-item-group v-model="creditCardKey" mandatory>
                <template v-for="(creditCard, index) in creditCards">
                    <v-divider :key="index"></v-divider>
                    <v-list-item
                            class="py-3"
                            :key="creditCard.id"
                            @click="hasClickRowEvent ? clickRow(creditCard) : null">
                        <template v-slot:default="{ active }">

                            <v-list-item-action v-if="hasClickRowEvent">
                                <v-checkbox
                                        readonly
                                        :input-value="active"
                                        :color="toolbarColor"
                                        :true-value="currentCreditCard"
                                ></v-checkbox>
                            </v-list-item-action>


                            <v-list-item-content>
                                <v-list-item-title>{{creditCard.brand}}</v-list-item-title>
                                <v-list-item-subtitle>**** {{creditCard.last4}} -
                                    {{creditCard.exp_month}}/{{creditCard.exp_year}}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action v-if="showDefault && creditCard.is_default">
                                <v-chip color="danger" outlined>Défaut</v-chip>
                            </v-list-item-action>


                            <v-list-item-action v-if="canEdit || canDelete || canShow">

                                <v-menu offset-y bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon fab small v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense class="py-0">
                                        <v-list-item v-if="canEdit && showDefault && !creditCard.is_default"
                                                     @click="setDefaultCard(creditCard)">
                                            <v-list-item-title>Carte par défaut
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item v-if="canDelete" @click="openDialogDeleteConfirmation(creditCard)">
                                            <v-list-item-title class="primary--text">Supprimer</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                </template>

            </v-list-item-group>
            <v-divider></v-divider>
        </v-list>

        <v-card-text v-else class="text-center">

            <v-btn :color="toolbarColor" @click="$emit('update:dialogAdd', true)">
                Ajouter une carte
            </v-btn>
        </v-card-text>

        <v-dialog
                :value="dialogAdd"
                @input="$emit('update:dialogAdd', $event)"
                max-width="900px">
            <mg-credit-card
                    ref="creditCardForm"
                    showToolbar
                    :toolbarColor="toolbarColor"
                    :dialog="dialogAdd"
                    @update:dialog="$emit('update:dialogAdd', $event)"

                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    :submitBtnColor="toolbarColor"

                    :value="currentCreditCard"
                    @input="$emit('update:currentCreditCard', $event)"
                    @submit="addCreditCard"
                    :loading.sync="newCreditCardLoading">
            </mg-credit-card>
        </v-dialog>

        <v-dialog
                :value="dialogDeleting"
                @input="$emit('update:dialogDeleting', $event)"

                hide-overlay
                persistent
                width="300">
            <v-card dark>
                <v-card-text class="pt-5">
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
                v-if="canDelete"
                :value="dialogDeleteConfirmation"
                @input="$emit('update:dialogDeleteConfirmation', $event)"
                max-width="300">
            <v-card class="text-center">
                <v-card-title class="headline justify-center">
                    Êtes-vous sur ?
                </v-card-title>
                <v-card-title class="subtitle-2 justify-center">
                    La suppresion est irréversible.
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" flat @click="$emit('update:dialogDeleteConfirmation', false)">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn flat @click="deleteOneCreditCard()">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import CreditCardLogic from "mg-js-core/lib/logics/CreditCardLogic";

    export default {
        name: 'credit-card-list',

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            /* creditCards */
            value: {
                type: Array,
                default() {
                    return []
                },
                required: true,
            },

            /* general */
            toolbar: String,
            showToolbar: Boolean,
            toolbarColor: {
                type: String,
                default: 'accent'
            },
            toolbarTitle: String,
            dark: Boolean,
            dialog: Boolean,
            isDialog: Boolean,
            canAdd: Boolean,
            canEdit: Boolean,
            canDelete: Boolean,
            canShow: Boolean,
            showDefault: Boolean,

            currentCreditCard: {
                type: Object,
                default() {
                    return CreditCardLogic.getEmptyObject()
                }
            },
            /* add (stepper) */
            dialogAdd: Boolean,
            step: {},
            newCreditCardLoading: Boolean,

            /* delete */
            dialogDeleting: Boolean,
            dialogDeleteConfirmation: Boolean,

        },

        data: () => ({
            creditCardKey: -1
        }),

        mounted() {
            if (this.currentCreditCard) {
                let creditCardKey = this.creditCards.findIndex(creditCard => {
                    return creditCard.id === this.currentCreditCard.id
                })

                if (creditCardKey > -1) {
                    this.creditCardKey = creditCardKey
                } else {
                    this.creditCardKey = -1
                }
            }
        },

        watch: {
            currentCreditCard() {
                // let creditCardKey = this.creditCards.findIndex(creditCard => {
                //     return creditCard.id === this.currentCreditCard.id
                // })
                //
                // if (creditCardKey > 1) {
                //     this.creditCardKey = creditCardKey
                // } else {
                //     this.creditCardKey = null
                // }
            }
        },
        computed: {
            creditCards: {
                get() {
                    return this.value
                },
                set(creditCards) {
                    this.$emit('input', creditCards)
                }
            },

            hasClickRowEvent() {
                return !!(this.$listeners && this.$listeners['click:row'])
            },

            mgAlertComponent() {
                return this.$refs.mgAlert
            },

            // creditCardEditFormComponent() {
            //     return this.$refs.creditCardEditForm
            // },
            //
            // creditCardAddFormComponent() {
            //     return this.$refs.creditCardCreateStepper
            // }
        },

        methods: {
            openDialogDeleteConfirmation(creditCard) {
                this.$emit('update:currentCreditCard', Object.assign(CreditCardLogic.getEmptyObject(), creditCard));
                this.$emit('update:dialogDeleteConfirmation', true);
            },

            deleteOneCreditCard() {
                this.$emit('deleteOneCreditCard', this.currentCreditCard);
            },

            addCreditCard(creditCard) {
                this.$emit('addCreditCard', creditCard)
            },

            setDefaultCard(creditCard) {
                this.$emit('setDefaultCard', creditCard)
            },

            clickRow(creditCard) {
                if (this.hasClickRowEvent) {
                    this.$emit('click:row', creditCard)
                }
            },
        }
    }
</script>
