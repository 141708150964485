<template>
    <v-row class="fill-height">

        <v-col cols="12" class="py-0">
            <v-tabs :value="tab" @change="$emit('update:tab', $event)">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab href="#summary">
                    Général
                </v-tab>
                <v-tab href="#parts">
                    Parties
                </v-tab>
            </v-tabs>
        </v-col>

        <v-col cols="12" class="pb-0" v-if="song.archived">
            <v-alert
                    tile
                    class="mb-0 text-center"
                    transition="scale-transition"
                    absolute
                    dark
                    color="warning">
                La chanson est archivée
            </v-alert>
        </v-col>

        <v-col cols="12">
            <v-tabs-items :value="tab" @change="$emit('update:tab', $event)">
                <v-tab-item value="summary">
                    <song-form
                            v-model="song"
                            submitBtn="Modifier"
                            submitBtnClass="text-center"
                            submitBtnColor="secondary"
                            @submit="updateSong"
                            :tagStylesAvailable="tagStylesAvailable"
                            :loading.sync="summaryLoading"
                            @archiveSong="archiveSong"
                            :instrumentsAvailable.sync="instrumentsAvailable"
                            showToolbar
                            toolbarTitle="Modifier une chanson"
                            showOptionsMenu>
                    </song-form>
                </v-tab-item>
                <v-tab-item value="parts">
                    <song-part-list-form
                        ref="songPartListForm"
                        :value="songParts"
                        @input="$emit('update:songParts', $event)"
                        flat
                        :addSongPartLoading="addSongPartLoading"
                        @update:addSongPartLoading="$emit('update:addSongPartLoading', $event)"

                        :currentKeyPart="currentKeyPart"
                        @update:currentKeyPart="$emit('update:currentKeyPart', $event)"

                        :addSongPartDialog="addSongPartDialog"
                        @update:addSongPartDialog="$emit('update:addSongPartDialog', $event)"

                        :deleteSongPartDialog="deleteSongPartDialog"
                        @update:deleteSongPartDialog="$emit('update:deleteSongPartDialog', $event)"
                        @deleteSongPart="deleteSongPart"

                        @addSongPart="addSongPart"

                        @updateSongPart="updateSongPart"
                        :updateSongPartLoading="updateSongPartLoading"
                        @updateCurrentKey="updateCurrentKey"
                        :tagDifficulty="tagDifficulty"
                    ></song-part-list-form>
                </v-tab-item>
            </v-tabs-items>

        </v-col>
    </v-row>
</template>

<script>
    import SongForm from '@/components/song/SongForm';
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import TagLogic from 'mg-js-core/lib/logics/TagLogic'
    import SongPartLogic from "mg-js-core/lib/logics/SongPartLogic";
    import InstrumentLogic from 'mg-js-core/lib/logics/InstrumentLogic';

    export default {
        name: 'song-form-complete',
        components: {SongForm},
        props: {
            summaryLoading: Boolean,
            value: {
                type: Object,
                required: true,
            },
            tab: {
                type: String,
                required: true,
            },

            /* parts */
            songParts: {
                type: Array,
                required: true,
            },
            currentKeyPart: Number,
            addSongPartLoading: Boolean,
            addSongPartDialog: Boolean,

            lessonPartsIsSortable: Boolean,
            deleteSongPartDialog: Boolean,

            updateSongPartLoading: Boolean,

        },
        mounted() {
            this.getAllTagStyles();
            this.getAllInstruments();
        },
        computed: {
            song: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
        },
        data: () => ({
            tagStylesAvailable: [],
            tagDifficulty: SongPartLogic.getTagDifficulty(),
            instrumentsAvailable: [],
        }),
        methods: {
            getAllTagStyles() {
                TagLogic
                    .getAllStyles(Helpers.initPagination(20, 1, 'position', true))
                    .then(({data}) => {
                        this.tagStylesAvailable = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            },
            updateSong() {
                this.$emit('updateSong');
            },
            archiveSong(songId) {
                this.$emit('archiveSong', songId);
            },

            /* parts */
            addSongPart(songPart) {
                this.$emit('addSongPart', songPart)
            },

            updateSongPart(songPart) {
                this.$emit('updateSongPart', songPart)
            },

            deleteSongPart(songPart) {
                this.$emit('deleteSongPart', songPart)
            },

            addSongPartFormError(errors) {
                this.$refs.lessonPartListForm.addSongPartFormError(errors)
            },

            addUpdateSongPartFormError(errors) {
                this.$refs.lessonPartListForm.addUpdateSongPartFormError(errors)
            },

            updateCurrentKey(key) {
                this.$emit('updateCurrentKey', key);
            },

            getAllInstruments() {
                InstrumentLogic
                    .getAll(Helpers.initPagination(20, 1, 'created_at', true))
                    .then(({data}) => {
                        this.instrumentsAvailable = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })

            },
        }
    }
</script>
