<template>
    <v-card flat>
        <v-app-bar flat dense :dark="dark" :color="toolbar" v-if="showToolbar">
            <v-btn icon
                   :dark="dark"
                   v-if="isDialog"
                   @click="$emit('update:dialog', false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

            <v-spacer></v-spacer>


            <template v-if="canAdd || canEdit">

                <v-menu offset-y bottom left v-if="!skuIsSortable">
                    <template v-slot:activator="{ on }">
                        <v-btn icon fab small v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense class="py-0">
                        <v-list-item @click="$emit('update:dialogAdd', true)" v-if="canAdd">
                            <v-list-item-title>Ajouter</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="sortSkus">
                            <v-list-item-title>Trier</v-list-item-title>
                        </v-list-item>
                    </v-list>

                </v-menu>

                <template v-if="skuIsSortable">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="cancelSortSkus">
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Annuler</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="submitSortSkus">
                                <v-icon small>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Enregistrer</span>
                    </v-tooltip>
                </template>
            </template>
        </v-app-bar>

        <mg-alert ref="mgAlert"></mg-alert>


        <v-list v-if="skus.length > 0" class="py-0">
            <draggable v-model="skus"
                       class="dragArea"
                       :options="{sort: skuIsSortable}">
                <v-list-item
                        :class="{'grabable' : skuIsSortable}"
                        v-for="sku in skus"
                        :key="sku.id">
                    <v-list-item-action
                            v-if="skuIsSortable"
                            class="grabable">
                        <v-icon>mdi-drag</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>{{sku.title}}
                            <v-icon
                                    v-if="sku.vipSku && sku.vipSku.data && sku.vipSku.data.deleted_at === null"
                                    color="warning" small style="padding-bottom: 2px">
                                mdi-star
                            </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{generateSubTitle(sku)}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="!sku.enabled">
                        <v-chip color="danger" disabledoutlined>Désactivé</v-chip>
                    </v-list-item-action>

                    <v-list-item-action v-if="canEdit || canDelete || canShow">

                        <v-menu offset-y bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon fab small v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item v-if="canShow" @click="$emit('click:showSku', sku)">
                                    <v-list-item-title>Voir</v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="canEdit" @click="openEditModal(sku)">
                                    <v-list-item-title>Editer</v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="canEdit" @click="toggleSku(sku)">
                                    <v-list-item-title>{{sku.enabled ? 'Désactiver' : 'Activé'}}</v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="canCopyLink" @click="copyLink(sku)">
                                    <v-list-item-title>Copier le lien</v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="canDelete" @click="openDialogDeleteConfirmation(sku)">
                                    <v-list-item-title class="primary--text">Supprimer</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>

            </draggable>

        </v-list>

        <template v-else-if="canAdd">
            <v-card-text class="text-center">
                <v-btn color="secondary" @click="$emit('update:dialogAdd', true)">Ajouter une vente à la carte</v-btn>
            </v-card-text>
        </template>

        <template v-else>
            <v-card-text class="text-center">
                Aucune donnée
            </v-card-text>
        </template>

        <v-dialog v-if="canAdd" :value="dialogAdd" @input="$emit('update:dialogAdd', $event)" max-width="800px">
            <sku-create-stepper
                    ref="skuCreateStepper"

                    showToolbar="secondary"
                    toolbarTitle="Créer une vente à la carte"
                    :dark="dark"

                    :courseModules="courseModules"
                    @update:courseModules="$emit('update:courseModules', $event)"

                    :courseModulesSelected="courseModulesSelected"
                    @update:courseModulesSelected="$emit('update:courseModulesSelected', $event)"

                    :search="productsFiltersSearch"
                    @update:search="$emit('update:productsFiltersSearch', $event)"

                    :availableProducts="availableProducts"
                    :step.sync="stepperIndex"

                    :parentType="stepperParentType"
                    @update:parentType="$emit('update:stepperParentType', $event)"

                    :availableCourses="availableCourses"

                    :selectedCourses="selectedCourses"
                    @update:selectedCourses="$emit('update:selectedCourses', $event)"

                    :searchCourse="availableCoursesSearch"
                    @update:searchCourse="$emit('update:availableCoursesSearch', $event)"

                    :value="currentSku"
                    @input="$emit('update:currentSku', $event)"

                    :product.sync="product"

                    :loading.sync="newSkuLoading"

                    @submit="addOneSku">
            </sku-create-stepper>
        </v-dialog>

        <v-dialog
                v-if="canEdit"
                :value="dialogUpdate"
                @input="$emit('update:dialogUpdate', $event)"
                max-width="800">
            <sku-form
                    ref="skuEditForm"

                    dark
                    toolbarTitle="Éditer une vente à la carte"
                    showToolbar="secondary"
                    isDialog
                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    submitBtnColor="secondary lighten-1"

                    :dialog="dialogUpdate"
                    @update:dialog="$emit('update:dialogUpdate', $event)"

                    :isLoading="isLoadingEdit"
                    @update:isLoading="$emit('update:isLoadingEdit', $event)"

                    :value="currentSku"
                    @input="$emit('update:currentSku', $event)"

                    :raw="false"

                    @submit="editOneSku"
                    showCourseModules
                    :showProducts.sync="showProducts"


                    :selectedCourses="selectedCourses"
                    @update:selectedCourses="$emit('update:selectedCourses', $event)"

                    :availableProducts="availableProducts"
                    :showCourses.sync="showCourses"
                    :availableCourses="availableCourses"
                    :product.sync="product"

                    :courseModules="courseModules"
                    @update:courseModules="$emit('update:courseModules', $event)"

                    :courseModulesSelected="courseModulesSelected"
                    @update:courseModulesSelected="$emit('update:courseModulesSelected', $event)"
                    :showVip="!!product.vipProduct"
            >

            </sku-form>
        </v-dialog>

        <v-dialog
                :value="dialogDeleting"
                @input="$emit('update:dialogDeleting', $event)"

                hide-overlay
                persistent
                width="300">
            <v-card dark>
                <v-card-text class="pt-5">
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
                v-if="canDelete"
                :value="dialogDeleteConfirmation"
                @input="$emit('update:dialogDeleteConfirmation', $event)"
                max-width="300">
            <v-card class="text-center">
                <v-card-title class="headline justify-center">
                    Êtes-vous sur ?
                </v-card-title>
                <v-card-title class="subtitle-2 justify-center">
                    La suppresion est irréversible.
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" flat @click="$emit('update:dialogDeleteConfirmation', false)">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn flat @click="deleteOneSku()">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import ProductLogic from "mg-js-core/lib/logics/ProductLogic";
    import SkuLogic from "mg-js-core/lib/logics/SkuLogic";
    import draggable from 'vuedraggable'
    import _ from 'lodash'

    export default {
        name: 'sku-list',

        components: {
            draggable,
        },

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            /* skus */
            value: {
                type: Array,
                required: true,
            },

            /* general */
            toolbar: String,
            showToolbar: Boolean,
            toolbarTitle: String,
            dark: Boolean,
            dialog: Boolean,
            isDialog: Boolean,
            canAdd: Boolean,
            canEdit: Boolean,
            canDelete: Boolean,
            canShow: Boolean,
            canCopyLink: Boolean,

            /* add (stepper) */
            dialogAdd: Boolean,
            step: {},
            newSku: {
                type: Object,
                default() {
                    return SkuLogic.getEmptyObject()
                }
            },
            newSkuLoading: Boolean,

            /* edit - form */
            dialogUpdate: Boolean,
            isLoadingEdit: Boolean,
            currentSku: Object,
            showProducts: Boolean,
            productsFiltersSearch: String,
            availableProducts: Array,
            stepperParentType: {
                type: String,
                default: 'product',
            },
            courseModules: Array,
            courseModulesSelected: Array,
            showCourses: Boolean,
            availableCourses: Array,
            selectedCourses: Array,
            availableCoursesSearch: String,
            product: Object,
            /* delete */
            dialogDeleting: Boolean,
            dialogDeleteConfirmation: Boolean,

            skuIsSortable: Boolean,
        },

        data: () => ({
            newProduct: ProductLogic.getEmptyObject(),
            skusRaw: null,
        }),

        mounted() {

        },

        computed: {
            skus: {
                get() {
                    return this.value
                },
                set(skus) {
                    this.$emit('input', skus)
                }
            },

            hasClickShowSku() {
                return !!(this.$listeners && this.$listeners['click:showSku'])
            },

            stepperIndex: {
                get() {
                    return this.step
                },
                set(step) {
                    this.$emit('update:step', step)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            skuEditFormComponent() {
                return this.$refs.skuEditForm
            },

            skuAddFormComponent() {
                return this.$refs.skuCreateStepper
            }
        },

        watch: {},

        methods: {
            openEditModal(sku) {
                this.$emit('update:dialogUpdate', true);

                const vipSku = sku.vipSku ? sku.vipSku.data : null;

                this.$emit('update:currentSku', Object.assign(SkuLogic.getEmptyObject({
                    vip_allowed: !!vipSku && vipSku.deleted_at === null,
                    vip_price: vipSku ? vipSku.price : null
                }), sku));

                this.$emit('update:availableProducts', Object.assign([], [{
                    id: sku.product.data.id,
                    title: sku.product.data.title,
                }]));
            },

            toggleSku(sku) {
                this.$emit('toggleSku', sku.id, {
                    enabled: !sku.enabled
                });
                // this.$emit('editOneSku', sku.id, {
                //     enabled: !sku.enabled
                // });
            },

            editOneSku(skuId, body) {
                this.$emit('editOneSku', skuId, body);
            },

            openDialogDeleteConfirmation(sku) {
                this.$emit('update:currentSku', Object.assign(SkuLogic.getEmptyObject(), sku));
                this.$emit('update:dialogDeleteConfirmation', true);
            },

            deleteOneSku() {
                this.$emit('deleteOneSku', this.currentSku);
            },

            addOneSku(sku) {
                this.$emit('addOneSku', sku);
            },

            generateSubTitle(sku) {
                let subtitle = '';


                if (!sku.price) {
                    return 'Gratuit'
                }
                console.warn(sku.currency === 'EUR' ? '€' : '$')

                subtitle = subtitle.concat(sku.price)
                subtitle = subtitle.concat(sku.currency === 'EUR' ? '€' : '$')

                return subtitle
            },


            sortSkus() {
                this.$emit('update:skuIsSortable', true)
                this.skusRaw = Object.assign([], this.skus)
            },
            cancelSortSkus() {
                this.$emit('update:skuIsSortable', false)
                this.skus = Object.assign([], this.skusRaw)
            },

            submitSortSkus() {
                let newOrder = _.map(this.skus, (sku, index) => {
                    return {
                        id: sku.id,
                        position: index + 1
                    }
                })

                this.$emit('submitSortSkus', newOrder)
            },

            copyLink(sku) {
                console.log(sku);
                console.log(process.env.VUE_APP_STRIPE_KEY)
                const url = `${process.env.VUE_APP_FRONT_HOST}/cart/${sku.product.data.slug}/product?selected_item_id=${sku.id}`;
                let tpm = document.createElement("input");
                document.body.appendChild(tpm);
                tpm.setAttribute("id", "dummy_id");
                tpm.setAttribute("value", url);
                tpm.select();
                document.execCommand("copy");
                document.body.removeChild(tpm);
            }
        }
    }
</script>
