<template>
    <v-card :flat="flat">
        <v-app-bar flat dark dense color="secondary">
            <v-spacer></v-spacer>
            <v-menu offset-y bottom left v-if="!resourceIsSortable">
                <template v-slot:activator="{ on }">
                    <v-btn icon fab small v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list dense class="py-0">
                    <v-list-item @click="$emit('update:dialog', true)">
                        <v-list-item-title>Ajouter une ressource</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item v-if="!resourceIsSortable" @click="sortResources">
                        <v-list-item-title>Trier les modules</v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-menu>
            <template v-if="resourceIsSortable">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="cancelSortResources">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Annuler</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="submitSortResources">
                            <v-icon small>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Enregistrer</span>
                </v-tooltip>
            </template>
        </v-app-bar>

        <v-list class="py-0" two-line v-if="resources.length">
            <draggable v-model="resources"
                       style="width: 100%"
                       :options="{sort: resourceIsSortable}">
                <div v-for="(resource,index) in resources" :key="index">
                    <div :key="resource.id">
                        <v-divider></v-divider>

                        <v-list-item :class="resourceIsSortable ? 'grabable' : null">
                            <v-list-item-action v-if="resourceIsSortable">
                                <v-icon>mdi-drag</v-icon>
                            </v-list-item-action>

                            <v-list-item-content class="">
                                <v-list-item-title>
                                    {{resource.title}}
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                    <a :href="resource.link">
                                        {{resource.link}}
                                    </a>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-chip color="secondary" dark small disabled
                                    v-if="!resource.enabled" outlined>
                                Désactivé
                            </v-chip>

                            <v-list-item-action v-if="!resourceIsSortable">
                                <v-menu offset-y bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon fab small v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense class="py-0">
                                        <v-list-item @click="openEditDialog(resource)">
                                            <v-list-item-title>
                                                Editer
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                        <v-list-item @click="openDeleteDialog(resource)">
                                            <v-list-item-title class="primary--text">
                                                Supprimer
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider v-if="resources.length - 1 === index"></v-divider>
                    </div>

                </div>
            </draggable>

        </v-list>

        <v-card-text v-else class="text-center">

            <v-btn color="secondary" @click="$emit('update:dialog', true)">
                Ajouter une ressource
            </v-btn>
        </v-card-text>

        <v-dialog :value="dialog"
                  @input="$emit('update:dialog', $event)"
                  max-width="600">

            <lesson-resource-form
                    ref="lessonResourceFormAdd"
                    v-model="newResource"

                    showToolbar
                    isDialog
                    :dialog="dialog"
                    @update:dialog="$emit('update:dialog', $event)"

                    dark
                    toolbarTitle="Ajouter une ressource"


                    submitBtnClass="text-center"
                    submitBtnColor="secondary"
                    submitBtn="Enregistrer"

                    :loading="dialogAddLoading"
                    @submit="addResource">
            </lesson-resource-form>
        </v-dialog>

        <v-dialog :value="dialogEdit"
                  @input="$emit('update:dialogEdit', $event)"
                  max-width="600">
            <v-card>
                <lesson-resource-form
                        ref="lessonResourceFormEdit"
                        v-model="currentResource"

                        showToolbar
                        isDialog
                        :dialog="dialogEdit"
                        @update:dialog="$emit('update:dialogEdit', $event)"

                        dark
                        toolbarTitle="Éditer une ressource"


                        submitBtnClass="text-center"
                        submitBtn="Enregistrer"
                        submitBtnColor="secondary"
                        :loading="dialogEditLoading"
                        @submit="editResource">
                </lesson-resource-form>
            </v-card>
        </v-dialog>

        <template>

            <mg-confirmation-modal :dialog.sync="dialogDelete"
                                   @delete="deleteResource"
            ></mg-confirmation-modal>

            <v-dialog
                    :value="dialogDeletingLoading"
                    @input="$emit('update:dialogDeletingLoading', $event)"
                    persistent
                    width="300">
                <v-card color="accent" dark>
                    <v-card-text>
                        Suppression en cours
                        <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
    </v-card>
</template>

<script>
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import draggable from 'vuedraggable'
    import _ from 'lodash'

    export default {
        name: 'lesson-tablature-list',

        $_veeValidate: {
            validator: 'new',
        },

        components: {
            draggable,
        },

        props: {
            value: {
                type: Array
            },
            dialog: Boolean,
            dialogEdit: Boolean,
            dialogEditLoading: Boolean,
            dialogAddLoading: Boolean,
            flat: Boolean,


            dialogDeletingLoading: Boolean,
            resourceIsSortable : Boolean,
        },

        watch: {
            dialog: {
                handler(value) {
                    if(!value){
                        this.newResource = LessonLogic.getEmptyResourceObject()
                        this.lessonResourceFormAddComponent.resetValidation()
                    }
                },
                deep: true
            },
        },

        data: () => ({
            newResource: LessonLogic.getEmptyResourceObject(),
            currentResource: LessonLogic.getEmptyResourceObject(),

            dialogDelete : false,
            resourcesRaw : null,

            fileTypesValues: [
                {text: 'Audio', value: 'audio'},
                {text: 'Fichier', value: 'file'},
                {text: 'Lien', value: 'link'},
                {text: 'Video', value: 'video'},
            ],
        }),

        computed: {
            resources: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            lessonResourceFormAddComponent(){
                return this.$refs.lessonResourceFormAdd
            },

            lessonResourceFormEditComponent(){
                return this.$refs.lessonResourceFormEdit
            }
        },

        methods: {
            // uploadResource() {
            //     this.$validator.validateAll().then(isValid => {
            //
            //         if (isValid) {
            //
            //             let formData = new FormData();
            //
            //             if (this.fields.resourceInput.dirty) {
            //                 const file = this.$refs.resourceInput.files[0];
            //                 formData.append('file', file, file.name);
            //             }
            //
            //             formData.append('title', this.newResource.title);
            //
            //             this.$emit('submit', formData)
            //         }
            //     });
            // },

            openEditDialog(resource) {
                this.$emit('update:dialogEdit', true)
                this.currentResource =  Object.assign(this.currentResource, resource)
            },

            openDeleteDialog(resource) {
                this.dialogDelete = true
                this.currentResource =  Object.assign(this.currentResource, resource)
            },

            addResource() {
                this.$emit('addResource', this.newResource)
            },

            editResource() {
                this.$emit('editResource', this.currentResource)
            },

            deleteResource() {
                this.$emit('deleteResource', this.currentResource)
            },

            addFileResource() {
                const file = this.$refs.resourceInput.files[0];
                this.newResource.file = file.name;
            },

            deleteFileResource() {
                this.newResource.file = null;
                this.$refs.resourceInput.files = null
            },

            sortResources() {
                this.$emit('update:resourceIsSortable', true)
                this.resourcesRaw = Object.assign([], this.resources)
            },

            cancelSortResources() {
                this.$emit('update:resourceIsSortable', false)
                this.resources = Object.assign([], this.resourcesRaw)
            },

            submitSortResources() {
                let newOrder = _.map(this.resources, (courseModule, index) => {
                    return {
                        id: courseModule.id,
                        position: index + 1
                    }
                })

                this.$emit('submitSortResources', newOrder)
            },
        }


    }
</script>
