<template>
    <v-card :flat="flat">
        <v-app-bar flat tabs dense dark color="secondary">
            <v-tabs v-model="tabs" background-color="transparent">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab href="#summary">
                    Partie
                </v-tab>
            </v-tabs>

            <v-spacer></v-spacer>

            <template v-if="tabs === 'summary'">
                <v-menu offset-y bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon fab small v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense class="py-0">
                        <v-list-item @click="openDeleteDialog">
                            <v-list-item-title class="primary--text">
                                Supprimer
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>

        <v-tabs-items v-model="tabs">
            <v-tab-item value="summary">
                <song-part-form
                        ref="form"
                        class="creamy"
                        submitBtnColor="secondary"
                        submitBtnClass="text-center"
                        submitBtn="Enregistrer"
                        @submit="submit"
                        :loading="loading"
                        v-model="songPart"
                        :tagDifficulty="tagDifficulty">
                </song-part-form>
            </v-tab-item>
        </v-tabs-items>


        <mg-confirmation-modal :dialog="dialogDelete"
                               @update:dialog="$emit('update:dialogDelete', $event)"
                               @delete="deleteLessonPart"
        ></mg-confirmation-modal>

    </v-card>
</template>

<style lang="scss" scoped>
</style>

<script>
    import PageStore from "mg-js-core/lib/stores/PageStore";

    export default {
        name: 'song-part-form-complete',

        props: {
            value: {
                type: Object,
                required: true,
            },

            flat: Boolean,

            loading: Boolean,
            isDialog: Boolean,
            dialog: Boolean,
            dialogDelete: Boolean,

            dialogAddResource: Boolean,
            tagDifficulty: Array,
        },


        data: () => ({
            PageStore,

            tabs: 'summary',

            newResource: {
                title: '',
                file: null,
            },
            playerHeight: null,

            /* lesson form */
            // lessonDialog: false,
            // lessonIsLoading: false,
        }),

        computed: {
            songPart: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
        },

        mounted() {
            // setTimeout(() => (this.tabs = 'summary'), 350)
        },

        methods: {
            submit() {
                this.$emit('submit', this.lessonPart);
            },

            addFormErrors(errors) {
                this.$refs.form.addFormErrors(errors)
            },

            onResize() {
                console.warn('resize')
                this.playerWitdh = this.$refs['player-block'] ? this.$refs['player-block'].offsetWidth : 400 * 1.78
                this.playerHeight = this.playerWitdh / 1.78
            },

            openDeleteDialog() {
                this.$emit('update:dialogDelete', true);
            },

            deleteLessonPart() {
                this.$emit('deleteLessonPart', this.lessonPart);
            },
        }
    };
</script>
