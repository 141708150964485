<template>
    <v-card>
        <v-app-bar flat dense dark color="secondary" v-if="toolbar">
            <v-icon>mdi-account</v-icon>
            <v-toolbar-title class="font-weight-light">
                Adresse
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon v-if="dialog" @click="resetAndCloseDialog()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-app-bar>

        <v-divider/>

        <v-container grid-list-md>
            <form>
                <v-row  >
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                v-validate="'required'"
                                v-model="address.street_number"
                                :error-messages="errors.collect('street_number')"
                                label="Numéro *"
                                data-vv-name="street_number">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                v-validate="'required'"
                                v-model="address.street_name"
                                :error-messages="errors.collect('street_name')"
                                label="Nom de la rue *"
                                data-vv-name="street_name">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                v-validate="'required'"
                                v-model="address.zip"
                                :error-messages="errors.collect('zip')"
                                label="Code postal *"
                                data-vv-name="zip">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                v-validate="'required'"
                                v-model="address.city"
                                :error-messages="errors.collect('city')"
                                label="Ville *"
                                data-vv-name="city">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                v-validate="'required'"
                                v-model="address.department"
                                :error-messages="errors.collect('department')"
                                label="Départment *"
                                data-vv-name="department">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                v-validate="'required'"
                                v-model="address.region"
                                :error-messages="errors.collect('region')"
                                label="Région *"
                                data-vv-name="region">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                v-validate="'required'"
                                v-model="address.country"
                                :error-messages="errors.collect('country')"
                                label="Pays *"
                                data-vv-name="country">
                        </v-text-field>
                    </v-col>
                </v-row>
            </form>
        </v-container>

        <v-card-text class="text-center">
            <v-btn
                    color="secondary"
                   :loading="isLoading"
                   @click="submit()">Valider
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'user-address-form',

        $_veeValidate: {
            validator: 'new'
        },

        // inject: {
        //     $validator: '$validator'
        // },

        props: {
            value: Object,
            dialog: Boolean,
            toolbar: Boolean,
            isFormDirty: Boolean,
        },

        data: () => ({
            isLoading: false,
            initialAddress: null
        }),

        watch: {
            dialog: {
                handler(newVal, oldValue) {
                    if (!oldValue && newVal) {
                        this.initialAddress = Object.assign({}, this.value)
                    }

                    if(!newVal){
                        this.resetAndCloseDialog();
                    }
                },
                deep: true
            },
        },

        computed : {
            address: {
                get() {
                   return this.value;
                },
                set(value) {
                   this.$emit('input', value)
                }
            },
            hasErrors(){
                return this.$validator.count() > 0
            }
        },

        methods: {
            touchAllInput() {
                Object.keys(this.fields).some(key => {
                    this.fields[key].dirty = true;
                });

                this.$emit('update:isFormDirty', true)
            },

            validateAll() {
                return new Promise((resolve) => {
                    this.touchAllInput();

                    this.$validator.validateAll().then(isValid => {
                        this.$emit('update:errors', this.errors)
                        resolve(isValid);
                    })
                })
            },

            submit() {
                return new Promise((resolve) => {
                    this.touchAllInput();

                    this.validateAll().then(isValid => {
                        if (isValid) {
                            this.address.formatted_address = `${this.address.street_number} ${this.address.street_name}, ${this.address.zip}, ${this.address.city}`

                            const address = Object.assign({}, this.address)

                            this.$emit('input', address)

                            this.closeDialog()

                            resolve(address);
                        }
                    })
                })
            },

            resetAndCloseDialog() {
                if (this.initialAddress) {
                    this.address = Object.assign(this.address, this.initialAddress);
                }

                this.$validator.reset();
                this.closeDialog()
            },

            closeDialog() {
                this.initialAddress = null
                this.$emit('update:dialog', false)
            },
        }
    }
</script>
