<template>
    <div id="course-list-expansion">
        <v-expansion-panels
                :id="`courseModule-${currentModule}`"
                :value="currentModule"
                accordion
                @change="$emit('update:currentModule', $event)">
            <v-expansion-panel
                    v-for="courseModuleDripContent in userCourseModules"
                    :readonly="moduleIsLocked(courseModuleDripContent) && !courseModuleDripContent.can_access"
                    :id="`courseModule-${courseModuleDripContent.id}`"
                    :key="courseModuleDripContent.id">
                <v-expansion-panel-header
                        :hide-actions="!courseModuleDripContent.can_access || !getRelatedModule(courseModuleDripContent).enabled"
                        :class="{'default-cursor' : moduleIsLocked(courseModuleDripContent) && !courseModuleDripContent.can_access}"
                        @contextmenu.prevent="openContextMenu($event, courseModuleDripContent)">
                    <v-list-item class="pa-0" :class="{'my-2' : large }"
                                 :disabled="courseModuleIsDisabled(courseModuleDripContent)">
                        <v-list-item-avatar>
                            <v-icon size="20"
                                    v-if="courseModuleIcon">
                                {{courseModuleIcon}}
                            </v-icon>
                            <v-icon size="20"
                                    disabled
                                    v-else-if="!courseModuleDripContent.can_access">
                                mdi-eye-off
                            </v-icon>
                            <v-icon v-else-if="!getRelatedModule(courseModuleDripContent).enabled"
                                    :disabled="courseModuleIsDisabled(courseModuleDripContent)">
                                mdi-close-circle-outline
                            </v-icon>
                            <v-icon v-else-if="moduleIsLocked(courseModuleDripContent)"
                                    :disabled="courseModuleIsDisabled(courseModuleDripContent)">
                                mdi-lock
                            </v-icon>
                            <v-icon v-else color="">mdi-lock-open-outline</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold" :class="{'title' : large }">
                                {{ getRelatedModule(courseModuleDripContent).title }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                                    :class="{'font-weight-bold' : courseModuleDripContent.can_access}"
                                    v-if="showStats">
                                <p class="mb-0">{{getAccessType(courseModuleDripContent)}}</p>
                                <p class="mb-0">{{getVipType(courseModuleDripContent)}}</p>
                            </v-list-item-subtitle>

                            <template v-if="$vuetify.breakpoint.mdAndDown">
                                <v-list-item-subtitle v-if="!getRelatedModule(courseModuleDripContent).enabled">
                                    Module désactivé
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                        class="font-weight-bold"
                                        v-else-if="moduleIsLocked(courseModuleDripContent) && nextCourseModule.id === courseModuleDripContent.id">
                                    {{getUnlockDiff}}
                                </v-list-item-subtitle>
                            </template>

                        </v-list-item-content>
                        <template v-if="!$vuetify.breakpoint.mdAndDown">

                            <v-list-item-action v-if="!getRelatedModule(courseModuleDripContent).enabled">
                                <v-chip color="secondary" dark disabled>
                                    Module désactivé
                                </v-chip>
                            </v-list-item-action>

                            <v-list-item-action
                                    v-else-if="moduleIsLocked(courseModuleDripContent) && course.enabled && nextCourseModule.id === courseModuleDripContent.id">
                                <v-chip color="creamy darken-1">
                                    {{getUnlockDiff}}
                                </v-chip>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                </v-expansion-panel-header>

                <v-expansion-panel-content class="px-0">
                    <v-card tile flat color="creamy">
                        <v-list class="transparent py-0 lessons-list">
                            <template
                                    v-for="(userCourseModuleLesson,index) in courseModuleDripContent.userCourseModuleLessons.data">
                                <v-divider
                                        v-if="index > 0 && index < courseModuleDripContent.userCourseModuleLessons.data.length"
                                        :key="index">
                                </v-divider>

                                <v-list-item
                                        :ref="userCourseModuleLesson.id"
                                        :key="userCourseModuleLesson.id"
                                        :id="'lesson-' + userCourseModuleLesson.id"
                                        readonly
                                        :inactive="(lessonIsLocked(courseModuleDripContent, userCourseModuleLesson)) || canEdit"
                                        :ripple="!((lessonIsLocked(courseModuleDripContent, userCourseModuleLesson)) || canEdit)"
                                        :to="getRouteNextLesson(getRelatedModule(courseModuleDripContent).slug, getRelatedLesson(userCourseModuleLesson).slug)">
                                    <v-list-item-avatar class="ml-4">
                                        <v-icon size="20"
                                                v-if="!courseModuleDripContent.can_access">
                                            mdi-eye-off
                                        </v-icon>
                                        <v-icon size="20"
                                                v-else-if="lessonIsLocked(courseModuleDripContent, userCourseModuleLesson)">
                                            mdi-lock
                                        </v-icon>
                                        <v-icon v-else size="20" :color="getColorByFinishStatus(userCourseModuleLesson.finish_status)">
                                            mdi-play-circle
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="allow-select">
                                            {{ getRelatedLesson(userCourseModuleLesson).title }}
                                        </v-list-item-title>

                                        <template v-if="showStats && userCourseModuleLesson.last_tried_on">
                                            <v-list-item-subtitle
                                                    class="">
                                                Dernier essai le
                                                {{$helpers.parseDate(userCourseModuleLesson.last_tried_on)}}
                                            </v-list-item-subtitle>

                                            <v-list-item-subtitle>
                                                {{userCourseModuleLesson.count}} tentative{{userCourseModuleLesson.count
                                                > 1 ? 's' : ''}}
                                            </v-list-item-subtitle>
                                        </template>

                                        <template v-if="!$vuetify.breakpoint.mdAndUp">
                                            <v-list-item-title
                                                    class="font-weight-bold"
                                                    v-if="nextLesson && userCourseModuleLesson.id === nextLesson.id">
                                                {{getUnlockDiff}}
                                            </v-list-item-title>
                                        </template>
                                    </v-list-item-content>


                                    <template v-if="$vuetify.breakpoint.mdAndUp">
                                        <v-list-item-action v-if="!getRelatedLesson(userCourseModuleLesson).enabled">
                                            <v-chip color="secondary" dark disabled>
                                                Désactivé
                                            </v-chip>
                                        </v-list-item-action>

                                        <v-list-item-action
                                                v-if="nextLesson && userCourseModuleLesson.id === nextLesson.id">
                                            <v-chip color="creamy darken-1">
                                                {{getUnlockDiff}}
                                            </v-chip>
                                        </v-list-item-action>
                                    </template>

                                    <v-list-item-action v-if="canEdit" class="clickable">
                                        <v-menu offset-y bottom left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon fab small v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list class="py-0" dense>
                                                <v-list-item @click="updateCurrentLesson(userCourseModuleLesson)">
                                                    <v-list-item-avatar size="16" style="min-width: 30px;">
                                                        <v-icon small>mdi-checkbox-marked-outline</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Définir comme leçon actuelle
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <v-list-item
                                                        :to="{name: 'admin-lesson-edit', params: {uuid: getRelatedLesson(userCourseModuleLesson).slug}}">
                                                    <v-list-item-avatar size="16" style="min-width: 30px;">
                                                        <v-icon small>mdi-pencil</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Voir leçon
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>

                                        </v-menu>

                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


        <vue-context ref="menu" tag="div" class="py-0" @close="onCloseContextMenu">
            <v-list class="py-0" dense>
                <v-list class="py-0" dense>
                    <v-list-item @click="toggleUserCourseModuleVip(currentRightClickCourseModule)">
                        <v-list-item-avatar size="16" style="min-width: 30px;">
                            <v-icon small>mdi-star</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-if="currentRightClickCourseModule.vip || currentRightClickCourseModule.force_vip">Désactiver VIP</v-list-item-title>
                            <v-list-item-title v-else>Activer VIP</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list-item @click="forceShowModule(currentRightClickCourseModule)"
                             v-if="!currentRightClickCourseModule.has_order && !currentRightClickCourseModule.has_subscription && !currentRightClickCourseModule.has_active_campaign_tag">
                    <v-list-item-avatar size="16" style="min-width: 30px;">
                        <v-icon small>
                            {{ currentRightClickCourseModule.can_access ? 'mdi-eye-off' : 'mdi-eye'}}
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ currentRightClickCourseModule.can_access ? 'Interdire' : 'Autoriser'}} la visibilité sans
                            produit
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-else @click="forceHideModule(currentRightClickCourseModule)">
                    <v-list-item-avatar size="16" style="min-width: 30px;">
                        <v-icon small>
                            {{ currentRightClickCourseModule.can_access ? 'mdi-eye-off' : 'mdi-eye'}}
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ currentRightClickCourseModule.can_access ? 'Interdire' : 'Autoriser'}} la visibilité avec
                            produit
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="downloadDegree(currentRightClickCourseModule)">
                    <v-list-item-avatar size="16" style="min-width: 30px;">
                        <v-icon small>
                            mdi-certificate-outline
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            Diplôme
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item :to="{
                                name: 'admin-course-edit',
                                params: {
                                        uuid: getRelatedCourse(course).slug,
                                        courseTab: 'modules',
                                        courseModuleUuid: getRelatedModule(currentRightClickCourseModule).slug,
                                    }
                                }"
                             target="_blank">
                    <v-list-item-avatar size="16" style="min-width: 30px;">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            Voir
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </vue-context>
    </div>
</template>

<style lang="scss">

    #course-list-expansion {
        .v-expansion-panel {
            border-radius: 0 !important;

            &::before {
                box-shadow: none !important;
            }
        }
    }

    .px-0 {
        .v-expansion-panel-content__wrap {
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 0 !important;
        }
    }
</style>

<script>
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import VueContext from 'vue-context';
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import CourseModuleLogic from "mg-js-core/lib/logics/CourseModuleLogic";
    import CourseDripContentModel from "mg-js-core/lib/models/CourseDripContentModel";
    import CourseLogic from "mg-js-core/lib/logics/CourseLogic";
    import UserCourseModuleLogic from "mg-js-core/lib/logics/UserCourseModuleLogic";

    export default {
        name: 'course-list-expansion',

        components: {
            VueContext
        },

        props: {
            large: Boolean,
            canRightClick: Boolean,
            showStats: Boolean,
            courseModuleIcon: Boolean,
            canEdit: Boolean,
            currentModule: Number,

            routeNameShowLesson: {
                required: true,
                validator: prop => typeof prop === 'string' || prop === null
            },

            course: {
                type: Object,
                default: () => (new CourseDripContentModel())
            },
        },

        data: () => ({
            PageStore,
            currentRightClickCourseModule: CourseModuleLogic.getEmptyObject(),
        }),

        mounted() {

        },

        watch: {},

        computed: {
            userCourseModules() {
                return this.course.courseModules;
            },

            currentCourseModule() {
                return this.course.currentCourseModule;
            },

            nextCourseModule() {
                return this.course.currentCourseModule;
            },

            currentLesson() {
                return this.course.currentLesson;
            },

            nextLesson() {
                return this.course.nextLesson;
            },

            nextUnlockableOn() {
                return this.currentLesson && this.currentLesson.next_unlockable_on
                    ? this.$moment(this.currentLesson.next_unlockable_on)
                    : null;
            },

            unlockDuration() {
                const nextUnlockableOn = this.nextUnlockableOn

                if (nextUnlockableOn) {
                    return this.$moment.duration(nextUnlockableOn.diff(this.$moment()))
                } else {
                    return null
                }
            },

            getUnlockDiff() {
                if (this.course.dripping_enabled) {
                    const unlockDuration = this.unlockDuration

                    if (unlockDuration) {
                        return `Disponible ${unlockDuration.humanize(true)}`
                    } else {
                        return `Disponible ${this.getRelatedLesson(this.currentLesson).duration} jours après le début de la leçon précédente`
                    }
                } else {
                    return `Le déblocage du contenu est désactivé`
                }
            },

            /* new */

            courseContent() {
                return this.course.courseContent
            },
        },

        methods: {
            updateCurrentLesson(userCourseModuleLesson) {
                this.$emit('updateUserCourse', this.course.id, {
                    current_lesson_id: this.getRelatedLesson(userCourseModuleLesson).id
                })
            },

            toggleLockLesson(userCourseModuleLesson) {
                this.$emit('updateUserCourse', this.course.id, {
                    toggle_lock_lesson_id: this.getRelatedLesson(userCourseModuleLesson).id
                })
            },

            resetLesson(userCourseModuleLesson) {
                this.$emit('updateUserCourse', this.course.id, {
                    reset_lesson_id: this.getRelatedLesson(userCourseModuleLesson).id
                })
            },

            toggleLockModule(userCourseModule) {
                this.$emit('updateUserCourse', this.course.id, {
                    toggle_lock_course_module_id: this.getRelatedModule(userCourseModule).id
                })
            },

            forceShowModule(userCourseModule) {
                this.$emit('updateUserCourse', this.course.id, {
                    toggle_force_show_course_module_id: this.getRelatedModule(userCourseModule).id
                })
            },

            forceHideModule(userCourseModule) {
                this.$emit('updateUserCourse', this.course.id, {
                    toggle_force_hide_course_module_id: this.getRelatedModule(userCourseModule).id
                })
            },

            resetModule(userCourseModule) {
                this.$emit('updateUserCourse', this.course.id, {
                    reset_course_module_id: this.getRelatedModule(userCourseModule).id
                })
            },

            getRouteNextLesson(courseModuleUuid, lessonUuid) {
                if (typeof this.routeNameShowLesson === 'string') {
                    return {
                        name: this.routeNameShowLesson,
                        params: {
                            courseUuid: this.courseContent.slug,
                            courseModuleUuid,
                            lessonUuid
                        }
                    }
                } else {
                    return null
                }
            },

            openContextMenu(event, courseModuleDripContent) {
                if (this.canRightClick) {
                    this.currentRightClickCourseModule = courseModuleDripContent;
                    this.$refs.menu.open(event)
                }
            },

            onCloseContextMenu() {
                this.currentRightClickCourseModule = CourseModuleLogic.getEmptyConfigObject();
            },

            courseModuleIsDisabled(courseModule) {
                return this.moduleIsLocked(courseModule) && !courseModule.can_access
            },

            getRelatedModule(courseModuleDripContent) {
                return courseModuleDripContent.courseModule && courseModuleDripContent.courseModule.data
                    ? courseModuleDripContent.courseModule.data
                    : CourseModuleLogic.getEmptyObject()
            },

            getRelatedLesson(courseModuleDripContentLesson) {
                return courseModuleDripContentLesson.lesson && courseModuleDripContentLesson.lesson.data
                    ? courseModuleDripContentLesson.lesson.data
                    : LessonLogic.getEmptyObject()
            },

            getRelatedCourse(userCourse) {
                return userCourse.course && userCourse.course.data
                    ? userCourse.course.data
                    : CourseLogic.getEmptyObject()
            },

            getAccessType(courseModuleDripContent) {

                let text = '';

                if (courseModuleDripContent.has_order || courseModuleDripContent.has_subscription|| courseModuleDripContent.has_active_campaign_tag) {
                    text = text.concat('Accessible par ')

                    if (courseModuleDripContent.has_order) {
                        text = text.concat('achat à la carte')
                    }

                    if (courseModuleDripContent.has_order && courseModuleDripContent.has_subscription) {
                        text = text.concat('et par ')
                    }

                    if (courseModuleDripContent.has_subscription) {
                        text = text.concat('abonnement')
                    }

                    if (courseModuleDripContent.has_active_campaign_tag) {
                        text = text.concat('ActiveCampaign')
                    }
                } else if (courseModuleDripContent.can_access) {
                    text = text.concat('Accès ouvert par Admin')
                } else {
                    text = text.concat('Invisible à l\'utilisateur');
                }

                return text;
            },

            getVipType(courseModuleDripContent) {
                let text = '';

                if (courseModuleDripContent.force_vip) {
                    text = text.concat('Accès VIP ouvert par Admin')
                } else if (courseModuleDripContent.vip === true) {
                    text = text.concat('Accès VIP')
                } else {
                    text = text.concat('Non VIP')
                }

                return text;
            },

            getColorByFinishStatus(status) {
                switch(status) {
                    case null:
                        return 'gray'
                    case 0:
                        return 'orange'
                    case 1:
                        return 'green'
                }
            },

            moduleIsLocked(courseModuleDripContent) {
                return courseModuleDripContent.position > this.currentCourseModule.position;
            },

            lessonIsLocked(courseModuleDripContent, userCourseModuleLesson) {
                if(this.moduleIsLocked(courseModuleDripContent)){
                    return true;
                }

                return (courseModuleDripContent.position === this.currentCourseModule.position
                    && userCourseModuleLesson.position > this.currentLesson.position);
            },

            toggleUserCourseModuleVip(userCourseModule) {
                this.$emit('updateUserCourseModule', userCourseModule.id, {
                    force_vip: !(userCourseModule.vip || userCourseModule.force_vip)
                })
            },

            downloadDegree(courseModule) {
                UserCourseModuleLogic
                    .generateDegree(courseModule.id)
                    .then(({url}) => {
                        window.open(url, '_blank');
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }
        }
    }
</script>
