import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './theme/main.scss';
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm';
import VueObserveVisibility from 'vue-observe-visibility'

Vue.config.productionTip = false

import MgJsCore from "mg-js-core/src/admin-main";

import store from '../src/stores'
import './custom-components'


Vue.use(VueObserveVisibility)

Vue.use(MgJsCore, {
    locale: process.env.VUE_APP_LOCALE,
    localeFallback: process.env.VUE_APP_FALLBACK_LOCALE,
    googleMapsKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    store
});

Vue.use(VueMeta, {
    refreshOnceOnNavigation : true
})

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID, // Your GTM ID
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
