<template>
    <v-card flat>
        <v-app-bar flat dense dark color="secondary" class="title">
            <v-spacer></v-spacer>

            <v-menu offset-y bottom left v-if="!productTypeIsSortable">
                <template v-slot:activator="{ on }">
                    <v-btn icon fab small v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list dense class="py-0">
                    <v-list-item @click="$emit('update:dialogAdd', true)">
                        <v-list-item-title>Ajouter un type de produit</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item v-if="!productTypeIsSortable" @click="sortProductTypes">
                        <v-list-item-title>Trier les type de produits</v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-menu>

            <template v-if="productTypeIsSortable">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="cancelSortProductTypes">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Annuler</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="submitSortProductTypes">
                            <v-icon small>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Enregistrer</span>
                </v-tooltip>
            </template>
        </v-app-bar>
        <!--<v-list class="py-0">-->
        <!--<v-divider></v-divider>-->

        <!--<v-list-item @click="currentProductTypeKey = key">-->
        <!--<v-list-item-content>-->
        <!--<v-list-item-title>{{ productType.title }}</v-list-item-title>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-divider v-if="productTypes.length - 1 === key"></v-divider>-->
        <!--</v-list>-->
        <mg-alert ref="mgAlert"></mg-alert>

        <v-row   class="px-1 fill-height" v-if="productTypes.length">
            <v-col md="4">
                <v-card flat style="height: 100%; max-height: 500px; overflow-y: scroll">
                    <v-list two-line class="py-0"
                            :class="productTypeIsSortable ? 'isGrabable' : null">
                        <draggable v-model="productTypes"
                                   class="dragArea"
                                   :options="{sort: productTypeIsSortable}"
                                   @change="productTypeMoved">
                            <v-list-item
                                    @click="!productTypeIsSortable ? $emit('update:currentProductTypeKey', key) : null"
                                    :class="{
                                        'creamy darken-4' : currentProductTypeKey === key && $vuetify.theme.dark,
                                        'creamy' : currentProductTypeKey === key && !$vuetify.theme.dark,
                                        'grabable' : productTypeIsSortable,
                                    }"
                                    v-for="(productType,key) in productTypes"
                                    :key="productType.id">
                                <v-list-item-action
                                        v-if="productTypeIsSortable"
                                        class="grabable">
                                    <v-icon>mdi-drag</v-icon>
                                </v-list-item-action>

                                <v-list-item-content>
                                    <v-list-item-title>{{ productType.title }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Lié à {{productType.products_count }} produit<span>{{productType.products_count > 1? 's' : ''}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-chip
                                        dark small
                                        disabledoutlined
                                        v-if="!productType.enabled">Désactivé
                                </v-chip>
                            </v-list-item>
                        </draggable>
                    </v-list>
                </v-card>
            </v-col>

            <v-col md="8">
                <v-card flat>
                    <v-app-bar flat tabs dense :color="!$vuetify.theme.dark ? 'creamy' : 'creamy darken-4'"
                               class="title font-weight-light">
                        <v-tabs v-model="tabs" background-color="transparent">
                            <v-tabs-slider color="primary"></v-tabs-slider>

                            <v-tab href="#summary" class="subheading font-weight-light text-none">
                                Type de produit
                            </v-tab>
                            <v-tab href="#product-list" class="subheading font-weight-light text-none">
                                Produits liés
                            </v-tab>
                        </v-tabs>

                        <v-spacer></v-spacer>

                        <template v-if="tabs === 'summary'">
                            <v-menu offset-y bottom left>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon fab small v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense class="py-0">
                                    <v-list-item @click="openDeleteDialog">
                                        <v-list-item-title class="primary--text">
                                            Supprimer
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>

                        <template v-if="tabs === 'product-list'">
                            <v-menu offset-y bottom left v-if="!productIsSortable">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon fab small v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense class="py-0">
                                    <v-list-item  @click="sortProducts">
                                        <v-list-item-title>Trier les produits</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <template v-else>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="cancelSortProducts">
                                            <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Annuler</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="submitSortProducts">
                                            <v-icon small>mdi-check</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Enregistrer</span>
                                </v-tooltip>
                            </template>
                        </template>

                    </v-app-bar>


                    <v-tabs-items v-model="tabs">
                        <v-tab-item value="summary">

                            <product-type-form
                                    ref="productTypeFormEdit"
                                    v-model="currentProductType"
                                    showEnabled
                                    submitBtn="Enregistrer"
                                    submitBtnClass="text-center"
                                    submitBtnColor="secondary"
                                    :loading="loadingEdit"
                                    @submit="editProductType"
                            >

                            </product-type-form>
                        </v-tab-item>
                        <v-tab-item value="product-list">
                            <v-list class="py-0"
                                    :class="productIsSortable ? 'isGrabable' : null"
                                    style="max-height: 450px; overflow-y: scroll" v-if="products.length">

                                <draggable v-model="products"
                                           style="width: 100%"
                                           :options="{sort: productIsSortable}">
                                    <div v-for="(product,index) in products" :key="index">
                                        <div :key="product.id">
                                            <v-divider></v-divider>

                                            <v-list-item :class="productIsSortable ? 'grabable' : null">

                                                <v-list-item-action v-if="productIsSortable"
                                                >
                                                    <v-icon>mdi-drag</v-icon>
                                                </v-list-item-action>

                                                <v-list-item-content class="" @click="!productIsSortable ? showProduct(product) : null">
                                                    <v-list-item-title>{{product.title}}</v-list-item-title>
                                                </v-list-item-content>

                                                <v-chip color="secondary" dark small disabled
                                                        v-if="!product.enabled" outlined>
                                                    Désactivé
                                                </v-chip>

                                                <v-chip color="secondary" dark small disabled
                                                        v-if="!product.salable" outlined>
                                                    Non visible
                                                </v-chip>
                                            </v-list-item>

                                            <v-divider v-if="products.length - 1 === index"></v-divider>
                                        </div>

                                    </div>
                                </draggable>
                            </v-list>

                            <v-card flat v-if="!products.length">
                                <v-card-text class="text-center">
                                    Aucun produit de lié
                                </v-card-text>
                            </v-card>

                        </v-tab-item>

                    </v-tabs-items>
                </v-card>

                <!--<div class="card-inline">-->
                <!--</div>-->
            </v-col>
        </v-row>

        <v-row   class="px-1 fill-height" v-if="!productTypes.length">
            <v-col cols="12">
                <v-card flat style="height: 100%; max-height: 500px; overflow-y: scroll">
                    <v-card-text class="text-center">
                        Aucun type de produit
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog :value="dialogAdd" @input="$emit('update:dialogAdd', $event)" max-width="900px">


            <product-type-form
                    ref="productTypeFormAdd"
                    v-model="newProductType"

                    showToolbar
                    isDialog
                    :dialog="dialogAdd"
                    @update:dialog="$emit('update:dialogAdd', $event)"
                    toolbarTitle="Ajouter un type de produit"

                    :loading="loadingAdd"
                    @submit="addProductType"


                    showEnabled
                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    submitBtnColor="secondary"
            >

            </product-type-form>
        </v-dialog>

        <mg-confirmation-modal :dialog="dialogDelete"
                               @update:dialog="$emit('update:dialogDelete', $event)"
                               @delete="deleteProductType"
        ></mg-confirmation-modal>

    </v-card>

</template>

<style lang="scss" scoped>
</style>

<script>
    import _ from 'lodash';
    import draggable from 'vuedraggable'
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import ProductTypeLogic from "mg-js-core/lib/logics/ProductTypeLogic";

    export default {
        name: 'product-type-fist-form',

        components: {
            draggable,
        },

        props: {
            value: {
                type: Array,
                required: true,
            },

            dialogAdd: Boolean,
            dialogDelete: Boolean,
            currentProductTypeKey: Number,

            loading: Boolean,
            loadingAdd: Boolean,
            loadingEdit: Boolean,
            deleteLoading: Boolean,
            productTypeIsSortable: Boolean,

            /* products */

            productIsSortable: Boolean,

            // tablatures: {
            //     type: Array
            // },
            //
            // resources: {
            //     type: Array
            // }
        },
        data: () => ({
            PageStore,
            tabs: 'summary',

            isDraggable: false,
            deleteUuid: null,

            productTypesRaw: null,
            newProductType: ProductTypeLogic.getEmptyObject(),

            /* products */
            productsRaw: null,
        }),

        computed: {
            productTypes: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            products : {
                get(){
                    return this.currentProductType.products
                    && this.currentProductType.products.data
                    && this.currentProductType.products.data.length > 0
                        ? this.currentProductType.products.data
                        : []
                },

                set(value){
                    this.currentProductType.products.data = value
                }
            },

            currentProductType: {
                get() {
                    return this.productTypes
                        ? Object.assign(ProductTypeLogic.getEmptyObject(), this.productTypes[this.currentProductTypeKey])
                        : ProductTypeLogic.getEmptyObject()
                },
                set(value) {
                    this.productTypes[this.currentProductTypeKey] = value;
                }
            },
            // useful for parent component
            productTypeFormAddComponent() {
                return this.$refs.productTypeFormAdd
            },
            productTypeFormEditComponent() {
                return this.$refs.productTypeFormEdit
            },

            mgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        mounted() {
            this.newProductType = ProductTypeLogic.getEmptyObject({
                position: this.productTypes.length ? _.last(this.productTypes).position + 1 : 0
            })
        },

        watch: {
            dialogAdd: {
                handler(value) {
                    if (!value) {
                        this.newProductType = ProductTypeLogic.getEmptyObject({
                            position: this.productTypes.length ? _.last(this.productTypes).position + 1 : 0
                        })
                        this.productTypeFormAddComponent.syncEditor()
                        this.productTypeFormAddComponent.resetValidation()
                    }
                },
                deep: true
            },
        },

        methods: {
            displayAlert() {
                this.mgAlertComponent.displayAlert();
            },

            sortProductTypes() {
                this.$emit('update:productTypeIsSortable', true)
                this.productTypesRaw = Object.assign([], this.productTypes)
            },
            cancelSortProductTypes() {
                this.$emit('update:productTypeIsSortable', false)
                this.productTypes = Object.assign([], this.productTypesRaw)
            },

            submitSortProductTypes() {
                let newOrder = _.map(this.productTypes, (productType, index) => {
                    return {
                        id: productType.id,
                        position: index + 1
                    }
                })

                this.$emit('submitSortProductTypes', {
                    order: newOrder
                })
            },

            productTypeMoved(value) {
                console.warn('productTypeMoved')
                console.warn(value.moved)
                console.warn(value.moved.newIndex)

                this.$emit('update:currentProductTypeKey', value.moved.newIndex)
            },

            updateProductType(productType) {
                this.$emit('updateProductType', productType);
            },

            addProductType(productType) {
                this.$emit('addProductType', productType);
            },

            productTypeEditFormErrors(errors) {
                this.productTypeFormEditComponent.addFormErrors(errors)
            },

            productTypeAddFormErrors(errors) {
                this.productTypeFormAddComponent.addFormErrors(errors)
            },

            editProductType(productType) {
                this.$emit('editProductType', productType);
            },

            deleteProductType() {
                this.$emit('deleteProductType', this.currentProductType);
            },

            openDeleteDialog() {
                this.$emit('update:dialogDelete', true);
            },

            /* products */
            showProduct(product) {
                this.$emit('showProduct', product)
            },

            sortProducts() {
                this.$emit('update:productIsSortable', true)
                this.productsRaw = Object.assign([], this.products)
            },

            cancelSortProducts() {
                this.$emit('update:productIsSortable', false)
                this.products = Object.assign([], this.productsRaw)
            },

            submitSortProducts() {
                let newOrder = _.map(this.products, (product, index) => {
                    return {
                        id: product.id,
                        position: index + 1
                    }
                })

                this.$emit('submitSortProducts', this.currentProductType, newOrder)
            },
        }
    };
</script>
