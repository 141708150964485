<template>
    <v-card flat>
        <v-app-bar flat dense dark color="secondary">
            <v-toolbar-title class="font-weight-light">
                Quête
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-container class="container--fluid pa-10" grid-list-lg>
            <v-row>
                <v-col cols="12" sm="5">
                    <v-row>
                        <v-col>
                            <p class="pb-0 title font-weight-bold">
                                Plateforme
                            </p> 
                            {{ questLocal.platform_code }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="pb-0 title font-weight-bold">
                                Etapes à compléter
                            </p>
                            <v-list>
                                <v-list-item
                                    v-for="(item, index) in steps"
                                    :key="index"
                                >
                                    <v-list-item-avatar size="16" style="min-width: 30px;">
                                        <v-icon small>mdi-check</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>
                                        {{ index }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                    v-validate="'required'"
                                    v-model="questLocal.title"
                                    :error-messages="errors.collect('title')"
                                    label="Titre (ne pas mettre de majuscule au début) *"
                                    name="title"
                                    data-vv-title="title"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                    v-validate="'required'"
                                    v-model="questLocal.incentive"
                                    :error-messages="errors.collect('incentive')"
                                    label="Phrase d'encouragement à compléter la quête *"
                                    name="incentive"
                                    data-vv-title="incentive"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                    v-validate
                                    v-model="questLocal.cta_link"
                                    :error-messages="errors.collect('cta_link')"
                                    label="Lien de redirection au clic sur le bouton"
                                    name="cta_link"
                                    data-vv-title="cta_link"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                    v-validate
                                    v-model="questLocal.cta_text"
                                    :error-messages="errors.collect('cta_text')"
                                    label="Texte du bouton"
                                    name="cta_text"
                                    data-vv-title="cta_text"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                    v-validate="'required'"
                                    v-model="questLocal.congratulations_phrase"
                                    :error-messages="errors.collect('congratulations_phrase')"
                                    label="Phrase de félicitaions pour avoir complété la quête *"
                                    name="congratulations_phrase"
                                    data-vv-title="congratulations_phrase"
                                    required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <v-card-actions class="justify-center pb-10">
            <v-btn color="secondary" @click="submit()">
                Enregistrer
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import _ from 'lodash';

    export default {
        name: 'quest-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            quest: Object,
        },

        data: () => ({
           
        }),

        watch: {
        },

        computed: {
            questLocal: {
                get() {
                    return this.quest;
                },
                set(value) {
                    console.error(value)
                    this.$emit('input', value)
                },
            },

            steps() {
                try {
                    return JSON.parse(this.questLocal.steps);
                } catch (err){
                    return "";
                }
            },

            mgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        methods: {
            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        let body = {};
                        _.forIn(this.fields, (value, key) => {
                            if (value.dirty) {
                                body[key] = this.questLocal[key]
                            }
                        });
                        this.$emit('submit', body);
                        this.$validator.reset();
                    }
                });
            },
        }
    }
</script>
