<template>
    <v-row>
        <v-col>
            <v-card flat>
                <v-app-bar flat dense color="secondary" dark v-if="showToolbar">
                    <v-icon v-if="toolbarIcon">{{toolbarIcon}}</v-icon>
                    <v-toolbar-title class="font-weight-light">{{pageTitle}}</v-toolbar-title>

                    <v-spacer></v-spacer>
                </v-app-bar>

                <template v-if="Object.keys(localFilters).length > 0">
                    <v-row justify="center">
                        <v-col cols="12" sm="6" md="4" v-if="localFilters.hasOwnProperty('search')">
                            <v-text-field
                                    v-model="localFilters.search"
                                    ref="search"
                                    prepend-icon="mdi-magnify"
                                    @click:prepend="$refs.search.focus()"
                                    clearable
                                    label="Titre"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                </template>

                <v-data-table
                        hide-default-footer
                        :items-per-page="200"
                        :headers="headers"
                        :items="courses">

                    <template v-slot:item.course="{ item }">
                        {{ item.course && item.course.title ? item.course.title : '-'}}
                    </template>
                    <template v-slot:item.lesson="{ item }">
                        {{ item.lesson && item.lesson.title ? item.lesson.title : '-'}}
                    </template>
                    <template v-slot:item.is_import="{ item }">
                        <v-chip :color="item.is_import ? 'green' : 'red'" dark>
                            {{ item.is_import ? 'Oui' : 'Non'}}
                        </v-chip>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <!--                        <v-menu-->
                        <!--                                offset-y bottom left>-->
                        <!--                            <template v-slot:activator="{ on }">-->
                        <!--                                <v-btn icon fab small v-on="on">-->
                        <!--                                    <v-icon>mdi-dots-vertical</v-icon>-->
                        <!--                                </v-btn>-->
                        <!--                            </template>-->

                        <!--                            <v-list dense class="py-0">-->
                        <!--                                <v-list-item :disabled="item.is_import" @click="openDialogImport(item.course)">-->
                        <!--                                    <v-list-item-title>-->
                        <!--                                        Importer-->
                        <!--                                    </v-list-item-title>-->
                        <!--                                </v-list-item>-->
                        <!--                            </v-list>-->
                        <!--                        </v-menu>-->
                        <v-btn small :disabled="item.is_import" @click="openDialogImport(item.course)">
                            Importer
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

        <v-dialog :value="dialogImport"
                  max-width="600px"
                  persistent
                  color="creamy"
                  @input="$emit('update:dialogImport', $event)">

            <v-card loading="primary" flat tile color="creamy" class="pb-4">
                <!--                <v-app-bar tile flat dense dark color="secondary">-->
                <!--                    <v-btn icon small fab dark @click="$emit('update:dialogImport', false)">-->
                <!--                        <v-icon>mdi-close</v-icon>-->
                <!--                    </v-btn>-->
                <!--                    <div class="flex-grow-1"></div>-->
                <!--                </v-app-bar>-->

                <v-card flat color="creamy">
                    <v-card-title class="title justify-center">
                        Importation en cours de :
                    </v-card-title>
                    <!--                    <v-card-actions class="justify-center pb-5">-->
                    <!--                        <v-btn :color="color"-->
                    <!--                               class="lighten-1"-->
                    <!--                               :loading="loading"-->
                    <!--                               @click="createResetPasswordToken">Rafraichir-->
                    <!--                        </v-btn>-->
                    <!--                    </v-card-actions>-->
                </v-card>
                <v-card flat class="ma-2 white text-center">
                    <v-card-title class="subtitle-1 justify-center">
                        {{selectedCourse.title}}
                    </v-card-title>
                </v-card>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

    export default {
        name: 'course-import-table',
        props: {
            courses: Array,
            selectedCourse: Object,
            headers: Array,
            filters: {
                type: Object,
                default() {
                    return {}
                }
            },
            currentSelectedIndex: Number,

            loading: Boolean,
            dialogImport: Boolean,
            pageTitle: String,
            toolbarIcon: String,

            showToolbar: {
                type: Boolean,
                default: false
            },

        },
        data: () => ({}),


        mounted() {
        },

        computed: {
            localFilters: {
                get() {
                    return this.filters;
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },
            isLoading: {
                get() {
                    return this.loading;
                },

                set(value) {
                    this.$emit('update:loading', value)
                }
            },
        },

        watch: {},

        methods: {
            openDialogImport(course) {
                this.$emit('update:dialogImport', true)
                this.$emit('update:selectedCourse', course)
                this.importCourse(course)
            },

            importCourse(course) {
                this.$emit('importCourse', course)
            }
        }
    };
</script>
