<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar dense flat :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-container class="container--fluid" grid-list-lg :key="formKey">
            <v-row  >
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="lessonPart.title"
                            :error-messages="errors.collect('title')"
                            label="Titre *"
                            name="title"
                            data-vv-title="title"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate
                            v-model="lessonPart.subtitle"
                            :error-messages="errors.collect('subtitle')"
                            label="Sous-titre"
                            name="subtitle"
                            data-vv-title="subtitle"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                            :items="types"
                            v-validate="'required'"
                            v-model="lessonPart.type"
                            item-value="id"
                            item-text="name"
                            :error-messages="errors.collect('type')"
                            label="Type *"
                            name="type"
                            data-vv-title="type"
                            :disabled="typeof(lessonPart.id) !== 'undefined'"
                            v-on:change="lessonPart.lesson_part_type_id=lessonPart.type"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                </v-col>
                <template v-if="lessonPart.lesson_part_type_id == types.find(element => element.name == 'Vidéo').id">
                    <v-col cols="12" sm="6">
                        <v-select
                                :items="providerValues"
                                v-validate
                                v-model="lessonPart.provider"
                                :error-messages="errors.collect('provider')"
                                label="Hébergeur *"
                                name="provider"
                                data-vv-title="provider"
                                key="provider-input"
                                @change="forceRerender(); lessonPart.is_video_downloadable = false"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                                v-validate
                                v-model="lessonPart.video_id"
                                :error-messages="errors.collect('video_id')"
                                label="Vidéo ID *"
                                name="video_id"
                                data-vv-title="video_id"
                                key="video-id-input"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-show="value.provider == 'vimeo'">
                        <v-checkbox
                                v-validate=""
                                v-model="lessonPart.is_video_downloadable"
                                :error-messages="errors.collect('is_video_downloadable')"
                                label="Est téléchargeable"
                                data-vv-name="is_video_downloadable"
                                :true-value="true"
                                :false-value="false"
                                color="secondary"
                                key="is-video-downloadable-input"
                        ></v-checkbox>
                    </v-col>
                </template>
                <template v-if="lessonPart.lesson_part_type_id == types.find(element => element.name == 'Lien externe').id">
                    <v-col cols="12" sm="12">
                        <mg-editor
                            ref="mgEditorDescription"
                            v-validate
                            v-model="lessonPart.description"
                            :error-messages="errors.collect('description')"
                            label="Description *"
                            name="description"
                            data-vv-title="description"
                            key="description-input"
                        >
                        </mg-editor>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                                v-validate="'url'"
                                v-model="lessonPart.link"
                                :error-messages="errors.collect('link')"
                                label="Premier lien externe *"
                                name="link"
                                data-vv-title="link"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                                v-validate
                                v-model="lessonPart.cta_text"
                                :error-messages="errors.collect('cta_text')"
                                label="Texte du premier bouton *"
                                name="cta_text"
                                data-vv-title="cta_text"
                                key="cta-text-input"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                                v-validate="'url'"
                                v-model="lessonPart.link2"
                                :error-messages="errors.collect('link2')"
                                label="Deuxième lien externe"
                                name="link2"
                                data-vv-title="link2"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                                v-validate
                                v-model="lessonPart.cta_text2"
                                :error-messages="errors.collect('cta_text2')"
                                label="Texte du deuxième bouton"
                                name="cta_text2"
                                data-vv-title="cta_text2"
                                key="cta-text-input2"
                        ></v-text-field>
                    </v-col>
                </template>
                <!-- <v-col cols="12" sm="6" v-show="lessonPart.lesson_part_type_id == types.find(element => element.name == 'Lien externe').id">
                    <v-text-field
                            v-validate
                            v-model="lessonPart.cta_text"
                            :error-messages="errors.collect('cta_text')"
                            label="Texte du bouton *"
                            name="cta_text"
                            data-vv-title="cta_text"
                            key="cta-text-input"
                    ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6">
                    <v-checkbox
                            v-validate="'required'"
                            v-model="lessonPart.enabled"
                            :error-messages="errors.collect('enabled')"
                            label="Activé"
                            data-vv-name="enabled"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.enabled.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";

    export default {
        name: 'lesson-part-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            prices: Array,

            dialog: Boolean,
            isDialog: Boolean,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            loading: Boolean,

            disabled: Boolean,
            dark: Boolean,

            value: Object,

            showToolbar: {
                type: String,
                default: null
            },

            types: Array,
        },

        data: () => ({
            providerValues : [
                {
                    text : 'Youtube',
                    value : "youtube"
                },
                {
                    text : 'Viméo',
                    value : "vimeo"
                },
            ], 
            formKey: 0,
        }),

        mounted() {
            if (this.value) {
                this.syncLessonPart(this.value)
            }
        },

        watch: {
            value: {
                handler(value, oldValue) {
                    this.syncLessonPart(value)
                    if (value.id !== oldValue.id) {
                        this.syncEditor()
                    }
                },
                deep: true
            },
        },
        computed: {
            lessonPart: {
                get() {
                    return this.value;
                },
                set(value) {
                    console.error(value)
                    this.$emit('input', value)
                },
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },
        },

        methods: {
            syncEditor() {
                setTimeout(() => {
                    this.$refs.mgEditorDescription.refreshContent()
                }, 250)
            },

            syncLessonPart() {
            },

            validateLessonPartForm() {
                return this.$validator.validateAll();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                this.mgAlertComponent.closeAlert();

                this.validateLessonPartForm().then(isValid => {
                    if (isValid) {
                        this.$emit('submit');
                    }
                })
            },

            forceRerender() { 
                this.formKey += 1;
            },
        }
    }
</script>
