<template>
    <div>
        <v-card flat class="">
            <v-app-bar dense flat :dark="dark" :color="toolbar">
                <v-btn icon dark
                       v-if="isDialog"
                       @click="$emit('update:dialogAdd', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}} <span
                        v-if="showDataCount">({{totalItems}})</span></v-toolbar-title>

                <v-spacer></v-spacer>

                <v-tooltip bottom v-if="hasClickAddProduct">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on"
                               @click="$emit('update:dialogAdd', true)"
                               icon>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Créer un produit</span>
                </v-tooltip>
            </v-app-bar>


            <v-card-text>
                <v-row v-if="filtersValue" class="justify-center">
                    <v-col cols="4" v-if="showSearchFilter && filtersValue.hasOwnProperty('search')">
                        <v-text-field
                                v-model="filtersValue.search"
                                ref="search"
                                prepend-icon="mdi-magnify"
                                @click:prepend="$refs.search.focus()"
                                clearable
                                label="Nom"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="showTypeFilter && filtersValue.hasOwnProperty('type')">
                        <v-select
                                v-model="filtersValue.type"
                                :items="productTypesValues"
                                data-vv-name="type"
                                hide-details
                                label="Type"
                                clearable
                        ></v-select>
                    </v-col>
                    <v-col cols="4" v-if="showProductTypeFilter && filtersValue.hasOwnProperty('product_types')">
                        <v-autocomplete
                                :items="productTypesAvailable"
                                v-model="filtersValue.product_types"
                                item-text="title"
                                item-value="id"
                                multiple
                                chip
                                label="Catégorie(s)"
                        >
                            <template v-slot:selection="data">
                                <v-chip small class="caption">
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider v-if="filtersValue"></v-divider>

            <mg-alert ref="mgAlert"></mg-alert>

            <v-card-text>
                <v-data-table
                        :options.sync="paginationValue"
                        :server-items-length="totalItems"
                        :footer-props="{
                            'items-per-page-options' : [20, 50, 100, 150]
                        }"
                        :headers="headers"
                        :loading="false"
                        item-key="id"
                        :show-expand="showExpand"
                        :items="products">

                    <template v-slot:item.title="{ item }">
                        <router-link class="default--text"
                                     :to="{name: editRouteName, params: {uuid: item.id}}">
                            {{ item.title }}
                        </router-link>
                    </template>
                    <template v-slot:item.type="{ item }">
                        {{ item.type === 'service' ? 'Vente par abonnement' : 'Vente à la carte' }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ Helpers.parseDate(item.created_at) }}
                    </template>
                    <template v-slot:item.enabled="{ item }">
                        <v-icon v-if="item.enabled" color="green">mdi-check</v-icon>
                        <v-icon v-else color="error">mdi-close</v-icon>
                    </template>
                    <template v-slot:item.salable="{ item }">
                        <v-icon v-if="item.salable" color="green">mdi-check</v-icon>
                        <v-icon v-else color="error">mdi-close</v-icon>
                    </template>
                    <template v-slot:item.default_good="{ item }">
                        <v-icon  v-if="item.pivot && item.pivot.default_good" color="green">mdi-check</v-icon>
                        <v-icon v-else color="error">mdi-close</v-icon>
                    </template>
                    <template v-slot:item.default_service="{ item }">
                        <v-icon v-if="item.pivot && item.pivot.default_service" color="green">mdi-check</v-icon>
                        <v-icon v-else color="error">mdi-close</v-icon>
                    </template>
                    <template v-slot:item.special_deal="{ item }">
                        <v-icon v-if="item.pivot && item.pivot.special_deal" color="green">mdi-check</v-icon>
                        <v-icon v-else color="error">mdi-close</v-icon>
                    </template>
                    <template v-slot:item.productType="{ item }">
                        {{ item.productType && item.productType.data ? item.productType.data.title : '-' }}
                    </template>
                    <template v-slot:item.actionForCourse="{ item }">
                        <v-menu
                                dense
                                offset-y bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon fab small v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense class="py-0">
                                <v-list-item @click="setAsDefaultProduct(item, 'service')"
                                             v-if="item.type === 'service'">
                                    <v-list-item-title :class="{'primary--text' : item.pivot.default_service}">
                                        {{item.pivot.default_service ? 'Supprimer' : 'Définir'}} comme abonnements par default
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="setAsDefaultProduct(item, 'good')"
                                             v-if="item.type === 'good'">
                                    <v-list-item-title :class="{'primary--text' : item.pivot.default_good}">
                                        {{item.pivot.default_good ? 'Supprimer' : 'Définir'}}  comme achat à la carte par default
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item @click="setAsDefaultProduct(item, 'deal')"
                                             v-if="item.type === 'good'">
                                    <v-list-item-title :class="{'primary--text' : item.pivot.special_deal}">
                                        {{item.pivot.special_deal ? 'Supprimer' : 'Définir'}} comme offre spéciale
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }" v-if="showExpand">
                        <td class="py-2" :colspan="headers.length">
                            <plan-list
                                    showToolbar
                                    toolbar="secondary"
                                    toolbarTitle="Abonnements"

                                    v-if="item.type === 'service'"
                                    ref="planListComponent"
                                    dark
                                    :value="item.plans && item.plans.data ? item.plans.data : []"
                                    :product="item">
                            </plan-list>

                            <sku-list
                                    v-if="item.type === 'good'"

                                    ref="skuListComponent"
                                    dark
                                    showToolbar
                                    toolbar="secondary"
                                    toolbarTitle="Vente à la carte"
                                    :value="item.skus && item.skus.data ? item.skus.data : []"
                            >

                            </sku-list>
                        </td>
                    </template>
                </v-data-table>

            </v-card-text>
        </v-card>

        <v-dialog :value="dialogAdd" @input="$emit('update:dialogAdd', $event)"
                  max-width="800px">

            <v-card flat v-if="dialogAdd">
                <v-app-bar flat dense :dark="dark" :color="toolbar">
                    <v-toolbar-title class="font-weight-light">
                        Création d'un produit
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialogAdd', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-card-text>
                    <product-form-stepper
                            ref="productForm"

                            showSelectCourses

                            submitBtnClass="text-center"
                            submitBtn="Créer"
                            submitBtnColor="secondary"
                            @update:dialog="$emit('update:dialogAdd', $event)"

                            :loading.sync="newProductIsLoading"
                            v-model="newProduct"
                            @submit="addProduct"
                            :productTypesAvailable.sync="productTypesAvailable"
                            :step="productStepperIndex"
                            @update:step="$emit('update:productStepperIndex', $event)"

                            :searchCourse="searchCourse"
                            @update:searchCourse="$emit('update:searchCourse', $event)"

                            :availableCourses="availableCourses"
                            @update:availableCourses="$emit('update:availableCourses', $event)"

                            :selectedCourses="selectedCourses"
                            @update:selectedCourses="$emit('update:selectedCourses', $event)"

                            :tagStylesAvailable.sync="tagStylesAvailable"
                            :tagLevelsAvailable.sync="tagLevelsAvailable"
                            :instrumentsAvailable.sync="instrumentsAvailable"

                    >
                    </product-form-stepper>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import ProductLogic from "mg-js-core/lib/logics/ProductLogic";
    import PlanLogic from "mg-js-core/lib/logics/PlanLogic";
    import _ from 'lodash'

    export default {
        name: 'product-table',

        props: {
            toolbar: String,
            toolbarTitle: String,
            showSearchFilter: Boolean,
            showTypeFilter: Boolean,
            showProductTypeFilter: Boolean,

            isDialog: Boolean,
            dark: Boolean,
            isLoading: Boolean,

            dialogAdd: Boolean,
            showExpand: Boolean,

            newProductIsLoading: Boolean,

            courseModulesSelected: Array,
            courseModules: Array,


            value: {
                type: Array,
                required: true,
            },
            canAdd: {
                type: Boolean,
                default: false
            },
            showDataCount: {
                type: Boolean,
                default: false
            },
            filters: {
                type: Object,
                // required: true
            },
            pagination: {
                type: Object,
                // required: true,
            },
            editRouteName: {
                type: String,
                default : 'admin-product-edit'
            },

            selectAll: null,
            totalItems: Number,
            headers: Array,


            /* for product add stepper */
            availableCourses: Array,
            selectedCourses: Array,
            searchCourse: String,

            productTypesAvailable: Array,
            productStepperIndex: Number,

            /* tags */
            tagStylesAvailable: Array,
            tagLevelsAvailable: Array,
            instrumentsAvailable: Array,
        },
        data: () => ({
            Helpers,

            newPlan: PlanLogic.getEmptyObject(),

            /* srripe product*/
            newProduct: ProductLogic.getEmptyObject(),
            productDialog: false,

            organizationId: '',




            productTypesValues: [
                {
                    text: 'Vente par abonnement', value: 'service'
                },
                {
                    text: 'Vente à la carte', value: 'good'
                },
            ]
        }),

        watch: {
            dialogAdd(value) {
                if (!value) {
                    this.$emit('update:productStepperIndex', 1);
                    this.newProduct = ProductLogic.getEmptyObject()
                }
            }
        },
        computed: {
            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            productFormComponent() {
                return this.$refs.productForm
            },

            products: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            hasClickRowEvent() {
                return !!(this.$listeners && this.$listeners['click:row'])
            },

            hasClickAddProduct() {
                return !!(this.$listeners && this.$listeners['click:addProduct'])
            },

            paginationValue: {
                get() {
                    return this.pagination;
                },
                set(value) {
                    this.$emit('update:pagination', value)
                }
            },
            filtersValue: {
                get() {
                    return this.filters;
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },
        },

        methods: {
            addProduct(product) {
                this.$emit('click:addProduct', product)
            },

            selectOneCourse(courseId) {
                let course = _.first(this.availableCourses, (course) => {
                    return course.id === courseId
                });

                console.warn(course)
                this.$emit('update:courseModules', course.courseModules.data)
            },

            clickRow(commercialProduct) {
                if (this.hasClickRowEvent) {
                    this.$emit('click:row', commercialProduct)
                }
            },

            setAsDefaultProduct(product, type) {
                this.$emit('setAsDefaultProduct', product, type)
            },

            changeSort(header) {
                if (header.sortable) {
                    if (this.paginationValue.sortBy === header.value && !this.paginationValue.descending) {
                        this.paginationValue.descending = true
                    } else if (this.paginationValue.sortBy === header.value && this.paginationValue.descending) {
                        this.paginationValue.sortBy = null
                        this.paginationValue.descending = null
                    } else {
                        this.paginationValue.sortBy = header.value
                        this.paginationValue.descending = false
                    }
                }
            }
        }
    };
</script>
