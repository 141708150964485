<template>
    <v-card flat>
        <v-app-bar flat tabs dense dark color="secondary">
            <v-tabs v-model="tabs" background-color="transparent">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab href="#config">
                    Configuration
                </v-tab>
                <v-tab href="#summary">
                    Modules
                </v-tab>
                <v-tab href="#products"
                       v-if="course.subscriptions.data.length || course.orders.data.length">
                    Produits associés
                </v-tab>
            </v-tabs>

            <v-spacer></v-spacer>

            <v-menu offset-y bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn icon fab small v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list dense class="py-0">
                    <v-list-item class="text--primary" :to="{
                                name: 'admin-course-edit',
                                params: {
                                        uuid: getRelatedCourse(course).slug,
                                    }
                                }"
                                 target="_blank">
                        <v-list-item-title class="text--primary">Voir le cours</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list-item v-if="canDelete" @click="openDialogDeleteConfirmation(course)">
                        <v-list-item-title class="text--danger">Supprimer le cours</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-tabs-items v-model="tabs" :class="{'transparent' : $vuetify.theme.dark}">
            <v-tab-item value="config" :class="{'transparent' : $vuetify.theme.dark}">
                <course-drip-content-form
                        flat
                        :class="{'creamy' : !$vuetify.theme.dark, 'transparent' : $vuetify.theme.dark}"
                        :value="course"
                        @input="$emit('update:course', $event)"

                        submitBtn="Enregistrer"
                        submitBtnClass="text-center"
                        submitBtnColor="secondary"

                        @submit="updateUserCourse"

                        :loading="loading"

                        :courseModules="courseModules"
                >
                </course-drip-content-form>
            </v-tab-item>
            <v-tab-item value="summary">
                <v-card tile
                        flat
                        style="min-height: 200px;max-height: 700px; overflow: scroll">
                    <course-list-expansion
                            canRightClick
                            canEdit
                            routeNameShowLesson=""
                            :course.sync="course"

                            showStats
                            @updateUserCourse="updateUserCourse"
                            @updateUserCourseModule="updateUserCourseModule"
                            :currentModule.sync="currentCourseModuleKeyValue"
                    >
                    </course-list-expansion>

                </v-card>
            </v-tab-item>
            <v-tab-item value="products">
                <template v-if="course.subscriptions.data.length">
                    <v-card flat tile color="transparent">
                        <v-card-title class="pb-0 subtitle-1 font-weight-bold">
                            Abonnements
                        </v-card-title>
                    </v-card>

                    <subscription-list
                            ref="subscriptionListComponent"
                            hideDefaultFooter
                            :value="course.subscriptions.data">

                    </subscription-list>
                </template>

                <template v-if="course.orders.data.length">

                    <v-card flat tile color="transparent">
                        <v-card-title class="pb-0 subtitle-1 font-weight-bold">
                            Achats à la carte
                        </v-card-title>
                    </v-card>

                    <order-table
                            ref="ordersTableComponent"
                            hideDefaultFooter
                            :value="course.orders.data">

                    </order-table>
                </template>

            </v-tab-item>
        </v-tabs-items>


        <v-dialog
                :value="dialogDeletingLoading"
                @input="$emit('update:dialogDeletingLoading', $event)"

                persistent
                width="300">
            <v-card dark>
                <v-card-text class="pt-5">
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <mg-confirmation-modal
                :dialog="dialogDelete"
                @update:dialog="$emit('update:dialogDelete', $event)"

                @delete="deleteCourse"
                :maxWidth="400"
        >
            <p class="text-center">
                Attention, cette action est irréversible.
            </p>
            <ul style=" width: 80%;margin: auto;">
                <li>
                    Tout l'historique sera effacé définitivement
                </li>
                <li>
                    Toutes les souscriptions seront annulées immédiatement
                </li>
                <li>
                    Tous les achats à la carte seront bloqués
                </li>
            </ul>

        </mg-confirmation-modal>
    </v-card>
</template>

<style lang="scss" scoped>
</style>

<script>
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import CourseDripContentModel from "mg-js-core/lib/models/CourseDripContentModel";
    import CourseLogic from "mg-js-core/lib/logics/CourseLogic";

    export default {
        name: 'user-course-module-list-form',

        props: {
            value: {
                type: Array,
                default: () => ([]),
                required: true,
            },
            course: {
                type: Object,
                default: () => (new CourseDripContentModel()),
                required: true,
            },

            currentLesson: Object,
            currentCourseModuleKey: Number,
            loading: Boolean,
            canDelete: Boolean,
            dialogDelete: Boolean,
            dialogDeletingLoading: Boolean,
        },
        data: () => ({
            PageStore,
            tabs: 'config',
            // currentCourseModuleKey: 0,
        }),

        computed: {
            courseModules: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            currentCourseModuleKeyValue: {
                get() {
                    return this.currentCourseModuleKey
                },
                set(value) {
                    this.$emit('update:currentCourseModuleKey', value)
                }
            },

            currentCourseModule: {
                get() {
                    return this.courseModules[this.currentCourseModuleKey]
                },
                set(value) {
                    this.courseModules[this.currentCourseModuleKey] = value;
                }
            },

            userCourseModules() {
                return this.course.courseModules;
            },

            mgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        mounted() {

        },

        watch: {},

        methods: {
            updateUserCourse(id, body) {
                this.$emit('updateUserCourse', id, body)
            },

            updateUserCourseModule(id, body) {
                this.$emit('updateUserCourseModule', id, body)
            },

            deleteCourse() {
                this.$emit('deleteCourse', this.course.id);
            },

            getRelatedCourse(userCourse) {
                return userCourse.course && userCourse.course.data
                    ? userCourse.course.data
                    : CourseLogic.getEmptyObject()
            },

            openDialogDeleteConfirmation() {
                this.$emit('update:dialogDelete', true);
            },
        }
    };
</script>
