<template>
    <v-card flat tile class="mg-comment-content transparent mb-2">
        <v-hover v-slot:default="{ hover }">
            <v-list>
                <v-list-item>
                        <v-list-item-avatar
                            class="white--text align-self-start"
                            v-if="showAvatar">
                            <img v-if="commentUser.id && commentUser.avatar"
                                 v-media-src="commentUser.avatar.data">
                            <v-img v-else
                                   :src="require('@/assets/mg-avatar-logo.svg')">
                            </v-img>
                        </v-list-item-avatar>

                    <v-list-item-content>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <div class="pb-1 text-body-2">
                                    <span class="black--text text-body-2" :class="[commentUser.is_staff ? 'font-weight-bold' : '']">{{ comment.user ? comment.user.data.username : '' }} 
                                        <span
                                            v-if="(currentComment.user && currentComment.user.data.id === currentUser.id)"
                                            class="font-weight-bold">
                                            (Toi)
                                        </span>
                                    </span>
                                    <span style="color:#A0A4A8">
                                    - Il y a {{ $moment.duration($moment(currentComment.created_at).diff($moment())).humanize() }}
                                        <span v-if="showModule && comment.module">dans <span class="font-weight-bold">{{ comment.module.data.title }}</span></span>
                                        <span class="text-caption">
                                            {{ currentComment.is_edited ? '(édité)' : '' }}
                                        </span>
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="12" class="pt-2" v-if="currentComment.message && !isEditing" style="max-width: 800px;">
                                <v-card-text class="pa-0">
                                    <v-list-item class="pa-0">
                                        <v-list-item-content :style="`max-width: ${maxWidth}`">
                                            <v-card class="pt-3 pb-0 "
                                                    :class="{'px-3' : outlined, 'primary-light-bg blue-border' : commentUser.is_staff && !$vuetify.theme.dark, 'blue-1-bg blue-1-border' : commentUser.is_staff && $vuetify.theme.dark, 'grey-bg grey-border' : !currentComment.enabled}"
                                                    flat :outlined="outlined">

                                                <div v-if="showRating && currentComment.rating !== null && !isEditing" class="ma-0 mb-2">
                                                    <v-rating
                                                        dense readonly
                                                        color="star"
                                                        style="margin-left: -4px"
                                                        background-color="star"
                                                        v-model="currentComment.rating">
                                                    </v-rating>
                                                </div>

                                                <v-card flat class="text-subtitle-1 lato-font transparent"
                                                        v-html="currentComment.message">
                                                </v-card>
                                                <div class="d-flex align-center">
                                                    <v-btn
                                                        color="secondary lighten-3"
                                                        icon rounded depressed x-small
                                                        @click="currentComment.is_liked ? unlike() : like()">
                                                        <v-icon small>
                                                            {{ currentComment.is_liked ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <div class="ml-1">{{ currentComment.like_count }}</div>
                                                    <!-- <div
                                                        v-if="canAnswer"
                                                        class="black--text text-body-2 lato-font ml-5"
                                                        style="cursor: pointer"
                                                        @click="showSubThread">
                                                        Répondre
                                                    </div> -->
                                                </div>
                                            </v-card>
                                        </v-list-item-content>
                                        <v-list-item-action class="my-0 ml-2" v-if="hover && (commentUser.id === user.id || $authLogic.hasRole(['teacher', 'admin', 'super-admin', 'marketing', 'support']))">
                                            <v-item-group>
                                                <v-tooltip
                                                    bottom small :color="accentColor">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn small icon
                                                               v-on="on"
                                                               @click="editComment">
                                                            <v-icon small>mdi-pencil-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Éditer</span>
                                                </v-tooltip>

                                                <!-- <v-tooltip
                                                    bottom small :color="accentColor">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn small icon
                                                               v-on="on"
                                                               @click="comment.enabled ? disable() : enable()">
                                                            <v-icon small>{{ comment.enabled ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ comment.enabled ? 'Désactiver' : 'Activer' }}</span>
                                                </v-tooltip> -->

                                                <v-tooltip
                                                    v-if="canDelete"
                                                    bottom small :color="accentColor">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn small icon
                                                               v-on="on"
                                                               @click="openDeleteDialog">
                                                            <v-icon small>mdi-trash-can-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Supprimer</span>
                                                </v-tooltip>
                                            </v-item-group>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-card-text>
                            </v-col>
                        </v-row>

                        <v-card-text class="pa-0" v-show="currentComment.message && isEditing">
                            <mg-comment-editor
                                required
                                :max-width="maxWidth"
                                ref="mgCommentEditor"
                                v-model="currentComment.message"
                                :initRating="showRating ? currentComment.rating : null"
                                @submitMessage="updateComment(currentComment)"
                                @updateRating="updateRating"
                                @cancel="cancelEditing"
                                :loading="isLoading"
                                :canRate="showRating">
                            </mg-comment-editor>
                        </v-card-text>

                        <v-card-text class="px-0 pt-1 pb-3"
                                     v-if="(currentComment.answers && currentComment.answers.data && currentComment.answers.data.length > 0 && showCount) || canAnswer">
                            <v-chip
                                v-if="currentComment.answers && currentComment.answers.data && currentComment.answers.data.length > 0 && showCount"
                                :color="accentColor"
                                class="mx-1"
                                label small
                                @click="showSubThread"
                                text-color="white">
                                {{ currentComment.answers.data.length }} réponse{{ currentComment.answers.data.length > 1 ? 's' : '' }}
                            </v-chip>
                            <template v-else-if="canAnswer">

                                <mg-comment-editor
                                    required
                                    :max-width="$vuetify.breakpoint.mdAndUp ? '75%' : '100%'"
                                    v-if="commentAnswerIdShow === currentComment.id"
                                    class="mt-3"
                                    :loading="isLoading"
                                    @cancel="$emit('update:commentAnswerIdShow', null)"
                                    @submitMessage="addMessage">
                                </mg-comment-editor>
                            </template>
                        </v-card-text>

                        <mg-confirmation-modal
                            v-if="commentUser.id === user.id || $authLogic.hasRole(['teacher', 'admin'])"
                            :dialog.sync="deleteDialog"
                            @delete="deleteComment(currentComment)"
                        ></mg-confirmation-modal>
                    </v-list-item-content>

                </v-list-item>
                <v-list-item
                    class="px-0 pt-4"
                    v-if="showDescendants && currentComment.answers && currentComment.answers.data && currentComment.answers.data.length">
                    <v-list-item-avatar></v-list-item-avatar>

                    <v-list-item-content>
                        <mg-comment
                            :max-width="`calc(${maxWidth} + 58px`"
                            v-for="subComment in currentComment.answers.data"
                            :id="subComment.id"
                            :key="subComment.id"
                            :canHover="canHover"
                            canDelete
                            :user="user"
                            :outlined="outlined"
                            :ref="`mgSubComment-${subComment.id}`"
                            @deleteComment="deleteComment(subComment)"
                            @updateComment="updateComment(subComment, true)"
                            @refresh="refresh"
                            :comment="subComment">
                        </mg-comment>
                    </v-list-item-content>


                </v-list-item>
            </v-list>

        </v-hover>
    </v-card>

</template>

<style lang="scss">
.blue-border {
    border: 2px solid var(--v-primary-lighten3) !important;
}

.grey-border {
    border: 2px solid grey;
}

.primary-light-bg {
    background-color: var(--v-primary-lighten4) !important;
}

.grey-bg {
    background-color: lightgrey !important;
}

.blue-1-bg {
    background-color: var(--v-blue-1-base) !important;
}

.blue-1-border {
    border: 2px solid var(--v-blue-1-lighten2) !important;
}
</style>

<script>
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import UserLogic from "mg-js-core/lib/logics/UserLogic";
    import MgCommentEditor from "@/components/community/MgCommentEditor";
    import CommentLogic from "mg-js-core/lib/logics/CommentLogic";
    import AuthLogic from 'mg-js-core/lib/logics/AuthLogic';

    export default {
        name: 'mg-comment',

        components: {MgCommentEditor},

        props: {
            comment: Object,
            maxWidth: String,
            canAnswer: Boolean,
            showDescendants: Boolean,
            showRating: Boolean,
            canHover: Boolean,
            showCount: Boolean,
            commentAnswerIdShow: String,

            canDelete: {
                type: Boolean,
                default: false
            },

            outlined: {
                type: Boolean,
                default: true
            },

            showAvatar: {
                type: Boolean,
                default: true
            },

            user: {
                type: Object,
                default: () => (UserLogic.getEmptyObject())
            },
            showModule: {
                type: Boolean,
                default: false,
            }
        },

        data: () => ({
            PageStore,
            rawComment: CommentLogic.getEmptyObject(),

            isEditing: false,
            isLoading: false,
            deleteDialog: false,
            currentUser: AuthLogic.me(),
        }),

        mounted() {
            if (this.comment) {
                this.rawComment = Object.assign(this.rawComment, this.comment)
            }
        },

        computed: {
            currentComment: {
                get() {
                    return this.comment
                },
                set(value) {
                    this.$emit('update:comment', value)
                },
            },
            hasAvatar() {
                return this.currentComment
                    && this.currentComment.user
                    && this.currentComment.user.data
                    && this.currentComment.user.data.avatar
                    && this.currentComment.user.data.avatar.data
            },

            accentColor() {
                return !this.$vuetify.theme.dark ? 'warning' : 'secondary'
            },

            commentUser() {
                return this.currentComment
                && this.currentComment.user
                && this.currentComment.user.data
                    ? this.currentComment.user.data
                    : UserLogic.getEmptyObject()
            },
        },

        methods: {
            reset() {
                this.isEditing = false;
                this.isLoading = false;
                this.rawComment = Object.assign(this.rawComment, this.comment)
            },
            refresh() {
                this.$emit('refresh')
                this.$emit('update:commentAnswerIdShow', null);
            },

            editComment() {
                this.isEditing = true;
                this.$refs.mgCommentEditor.onFocus();
            },

            updateComment(comment, isSubComment = false) {
                this.isLoading = true;
                // this.currentComment.is_edited = true;
                this.$emit('updateComment', comment, isSubComment ? this.currentComment : null)
            },

            cancelEditing() {
                this.currentComment = Object.assign(this.currentComment, this.rawComment)
                this.$nextTick(() => {
                    this.isEditing = false;
                })
            },

            openDeleteDialog() {
                this.deleteDialog = true
            },

            deleteComment(comment) {
                this.$emit('deleteComment', comment)

                setTimeout(() => {
                    this.deleteDialog = false
                }, 500)
            },

            addMessage(htmlMessage) {
                this.$emit('addMessage', htmlMessage)
            },

            showSubThread() {
                this.$emit('showSubThread', this.currentComment)
            },

            updateRating(rating) {
                this.currentComment.rating = rating;
            },

            like() {
                CommentLogic
                    .like(this.currentComment.id)
                    .then(({data}) => {
                        this.currentComment.is_liked = data.is_liked;
                        this.currentComment.like_count = data.like_count;
                    })
                    .catch((err) => {
                        // this.isLoading = false;
                        console.error(err)
                    })
            },

            unlike() {
                CommentLogic
                    .unlike(this.currentComment.id)
                    .then(({data}) => {
                        this.currentComment.is_liked = data.is_liked;
                        this.currentComment.like_count = data.like_count;
                    })
                    .catch((err) => {
                        // this.isLoading = false;
                        console.error(err)
                    })
            },
        }
    }
</script>
