<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template>
                    <v-spacer></v-spacer>


                    <v-menu offset-y bottom left>
                        <template v-slot:activator="{ on }">
                            <v-btn icon fab small v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense class="py-0">
                            <v-list-item @click="deleteOnePopup">
                                <v-list-item-title class="primary--text">
                                    Supprimer
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn icon dark
                           v-if="isDialog"
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-card-text>
            <v-row>
                <template v-if="!lightForm">

                    <v-col cols="12">
                        <div class="caption">
                            Logo
                        </div>
                    </v-col>

                    <v-col cols="12" sm="3">
                        <v-card flat>
                            <input
                                    v-validate
                                    v-show="false"
                                    id="files"
                                    type="file"
                                    accept="image/*"
                                    name="file"
                                    ref="logoInput"
                                    @change="submitLogo"
                                    :multiple="false"/>

                            <img
                                    v-if="product.logo && product.logo.data"
                                    width="50%"
                                    style="min-height: 150px"
                                    class="clickable"
                                    @click="clickInput"
                                    v-media-src="product.logo.data"/>

                            <v-btn
                                    v-else
                                    small
                                    color="secondary"
                                    @click="clickInput">
                                Ajouter
                            </v-btn>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <div class="caption">
                            Background
                        </div>
                    </v-col>

                    <v-col cols="12" sm="3">
                        <v-card flat>
                            <input
                                    v-validate
                                    v-show="false"
                                    id="files"
                                    type="file"
                                    accept="image/*"
                                    name="file"
                                    ref="backgroundInput"
                                    @change="submitBackground"
                                    :multiple="false"/>

                            <img
                                    v-if="product.background && product.background.data"
                                    width="100%"
                                    class="clickable"
                                    @click="clickBackgroundInput"
                                    v-media-src="product.background.data"/>

                            <v-btn
                                    v-else
                                    small
                                    color="secondary"
                                    @click="clickBackgroundInput">
                                Ajouter
                            </v-btn>
                        </v-card>
                    </v-col>
                </template>

                <v-col cols="12">
                    <v-text-field
                            v-validate="'required'"
                            v-model="product.title"
                            :disabled="disabled"
                            :error-messages="errors.collect('title')"
                            label="Nom *"
                            name="title"
                            data-vv-title="title"
                            required
                    ></v-text-field>
                </v-col>

                <v-col cols="12">
                    <mg-select-platform
                        name="platform_code"
                        v-validate
                        v-model="product.platform_code">
                    </mg-select-platform>
                </v-col>

                <v-col cols="12" sm="4" v-if="canEditStripeId">
                    <v-checkbox
                        v-validate
                        v-model="product.import_from_stripe"
                        :error-messages="errors.collect('import_from_stripe')"
                        label="Import depuis stripe"
                        data-vv-name="import_from_stripe"
                        required
                        :true-value="true"
                        :false-value="false"
                        @change="fields.import_from_stripe.dirty = true"
                        color="secondary"
                        :disabled="!canEditStripeId"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" :sm="canEditStripeId ? 8 : 12">
                    <v-text-field
                        :disabled="canEditStripeId === false || product.import_from_stripe === false"
                        v-model="product.stripe_id"
                        label="Stripe Id"
                        v-validate
                        data-vv-name="stripe_id"
                        :error-messages="errors.collect('stripe_id')"
                    >
                    </v-text-field>
                </v-col>

                <template v-if="!lightForm">
                    <v-col cols="12">
                        <v-select
                                v-model="product.type"
                                :items="productTypesValues"
                                v-validate="'required'"
                                :error-messages="errors.collect('type')"
                                data-vv-name="type"
                                label="Type *"
                        ></v-select>
                    </v-col>

                    <v-col cols="12">
                        <v-select
                                v-model="productTypeSelected"
                                :items="productTypesAvailable"
                                item-text="title"
                                item-value="id"
                                return-object
                                v-validate="'required'"
                                :error-messages="errors.collect('product_type_id')"
                                data-vv-name="product_type_id"
                                label="Catégorie *"
                        ></v-select>

                    </v-col>

                    <v-col cols="12" v-if="!isFullAccessProduct">
                        <v-autocomplete
                            :items="availableCourses"
                            :value="selectedCourses"
                            @input="$emit('update:selectedCourses', $event)"
                            item-text="title"
                            item-value="id"
                            returnObject
                            multiple
                            cache-items
                            :search-input="searchCourse"
                            @update:search-input="$emit('update:searchCourse', $event)"
                            :error-messages="errors.collect('courses')"
                            data-vv-name="courses"
                            v-validate="'required'"
                            label="Cours *"
                        >
                            <template v-slot:selection="data">
                                <v-chip class="caption">
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="12" v-if="isFullAccessProduct">
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="product.course_selector_type"
                                    :items="unlimitedCategoryValues"
                                    v-validate="'required'"
                                    :error-messages="errors.collect('course_selector_type')"
                                    data-vv-name="course_selector_type"
                                    label="Choix des cours"
                                ></v-select>
                            </v-col>
                            <v-col cols="12"
                                   v-if="product.course_selector_type !== 'ALL'">
                                <v-autocomplete
                                    :items="availableCourses"
                                    :value="selectedCourses"
                                    @input="$emit('update:selectedCourses', $event)"
                                    item-text="title"
                                    item-value="id"
                                    returnObject
                                    multiple
                                    cache-items
                                    :search-input="searchCourse"
                                    @update:search-input="$emit('update:searchCourse', $event)"
                                    :error-messages="errors.collect('courses')"
                                    data-vv-name="courses"
                                    v-validate="'required'"
                                    label="Cours *"
                                >
                                    <template v-slot:selection="data">
                                        <v-chip class="caption">
                                            {{ data.item.title }}
                                        </v-chip>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                    </v-col>

                    <template v-if="!product.wf_course_id">
                        <v-col cols="12">
                            <mg-editor
                                ref="mgEditorDescription"
                                v-model="product.description"
                                label="Description"
                                v-validate
                                data-vv-name="description">
                            </mg-editor>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-validate="'url'"
                                v-model="product.external_link"
                                :disabled="disabled"
                                :error-messages="errors.collect('external_link')"
                                label="Lien extérieur"
                                name="external_link"
                                data-vv-title="external_link"
                            ></v-text-field>
                        </v-col>
                    </template>
                </template>

                <v-col cols="12" sm="4">
                    <v-checkbox
                            v-validate
                            v-model="product.vip_allowed"
                            :disabled="disabled"
                            :error-messages="errors.collect('vip_allowed')"
                            label="Option VIP"
                            data-vv-name="vip_allowed"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.vip_allowed.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="4">
                    <v-checkbox
                            v-validate
                            v-model="product.salable"
                            :disabled="disabled"
                            :error-messages="errors.collect('salable')"
                            label="Visible dans les offres"
                            data-vv-name="salable"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.salable.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="4" v-if="showEnabled">
                    <v-checkbox
                            v-validate
                            v-model="product.enabled"
                            :disabled="disabled"
                            :error-messages="errors.collect('enabled')"
                            label="Activé"
                            data-vv-name="enabled"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.enabled.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-select
                            v-model="currentTagStyles"
                            :items="tagStylesAvailable"
                            item-text="title"
                            item-value="id"
                            v-validate
                            multiple
                            :error-messages="errors.collect('tagStyles')"
                            data-vv-name="tagStyles"
                            label="Style(s)"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-select
                            v-model="currentTagLevel"
                            :items="tagLevelsAvailable"
                            item-text="title"
                            item-value="id"
                            v-validate
                            :error-messages="errors.collect('tagLevel')"
                            data-vv-name="tagLevel"
                            label="Niveau"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-select
                            v-model="currentInstruments"
                            :items="instrumentsAvailable"
                            item-text="name"
                            item-value="id"
                            v-validate
                            multiple
                            :error-messages="errors.collect('instruments')"
                            data-vv-name="instruments"
                            label="Instruments"
                    ></v-select>
                </v-col>

                <v-col cols="12">
                    <v-text-field
                        v-validate="'url'"
                        v-model="product.thrivecart_url"
                        :disabled="disabled"
                        :error-messages="errors.collect('thrivecart_url')"
                        label="Lien thrivecart"
                        name="thrivecart_url"
                        data-vv-title="thrivecart_url"
                    ></v-text-field>
                    <p>Si vous renseignez un lien Thrivecart, l'utilisateur sera redirigé vers Thrivecart pour finaliser l'achat.</p>
                    <v-checkbox
                        v-validate
                        v-model="product.thrivecart_enabled"
                        :disabled="disabled"
                        :error-messages="errors.collect('thrivecart_enabled')"
                        label="Redirection Thrivecart activée"
                        data-vv-name="thrivecart_enabled"
                        required
                        :true-value="true"
                        :false-value="false"
                        color="secondary"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12">
                    <v-checkbox
                        v-validate
                        v-model="product.starter"
                        :disabled="disabled"
                        :error-messages="errors.collect('starter')"
                        label="Produit cours débutant"
                        data-vv-name="starter"
                        required
                        :true-value="true"
                        :false-value="false"
                        color="secondary"
                    ></v-checkbox>
                    <p>Permet de définir le produit actuel comme étant le produit "pour débutant" qui se trouve sur la page d'acceuil de l'espace membre après l'inscription d'un nouveau membre. Il ne peut y avoir qu'un seul produit "pour débutant".</p>
                </v-col>

            </v-row>
        </v-card-text>

        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
            <v-alert
                class="mt-2"
                color="red"
                type="error"
                v-if="categoryError"
            >Merci de renseigner une categorie.</v-alert>
        </v-card-text>

        <v-dialog :value="dialogDeleteConfirmation" @input="toggleDialogDeleteConfirmation" max-width="300"
                  class="text-center">
            <v-card style="text-align: center">
                <v-card-title class="headline justify-center">
                    Êtes-vous sur ?
                </v-card-title>
                <v-card-title class="subtitle-2 justify-center">
                    La suppresion est irréversible.
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" flat @click="closeDialogDeleteConfirmation()">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="deleteProduct()">Oui</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
                :value="dialogDeleting"
                @input="toggleDialogDeleting"
                persistent
                width="300">
            <v-card>
                <v-card-text class="pt-5">
                    <v-progress-linear
                            indeterminate
                            color="primary"
                            class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCannotDelete" max-width="300"
                  class="text-center">
            <v-card>
                <v-card-title class="headline">Suppression impossible !</v-card-title>
                <v-card-text>
                    Ce produit est lié à des abonnements.
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="secondary" @click="dialogCannotDelete = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import _ from 'lodash'
    import {mapActions, mapState} from 'vuex'
    import MgSelectPlatform from "../platform/MgPlatformSelect";
    import ProductLogic from "mg-js-core/lib/logics/ProductLogic";

    export default {
        name: 'product-form',

        components: {MgSelectPlatform, MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            prices: Array,

            toolbarTitle: String,
            showToolbar: {
                type: String,
                default: null
            },
            canEditStripeId: {
                type: Boolean,
                default: false
            },
            dialog: Boolean,
            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            isDialog: Boolean,
            loading: Boolean,
            disabled: Boolean,
            lightForm: Boolean,
            dark: Boolean,

            newPlanIsLoading: Boolean,

            value: Object,


            course: Object,
            showEnabled: Boolean,

            productTypesAvailable: Array,
            /* courses */
            availableCourses: Array,
            selectedCourses: {
                type: Array,
                default: () => ([]),
            },
            searchCourse: String,

            tagStylesAvailable: Array,
            tagLevelsAvailable: Array,
            instrumentsAvailable: Array,
        },

        data: () => ({
            hasPlan: null,

            planInterval: null,
            dialogCannotDelete: false,
            search: '',

            pricesCurrency: 'EUR',
            currencies: [
                {
                    text: 'Euro (€)', value: 'EUR'
                },
                {
                    text: 'Dollar ($)', value: 'USD'
                },
            ],

            productTypesValues: [
                {
                    text: 'Vente par abonnement', value: 'service'
                },
                {
                    text: 'Vente à la carte', value: 'good'
                },
            ],

            unlimitedCategoryValues: [
                {
                    text: 'Tous', value: 'ALL'
                },
                {
                    text: 'Tous sauf...', value: 'EXCEPT'
                },
                {
                    text: 'Je choisis manuellement', value: 'ONLY'
                },
            ],

            currentTagCategory: null,
            currentTagStyles: null,
            currentTagLevel: null,
            currentTagValue: null,
            productTypeSelected: null,
            categoryError: false,
            currentInstruments: null,
        }),

        mounted() {
            if (this.value) {
                this.syncValue(this.value)
            }
        },

        watch: {
            search(val) {
                this.$emit('update:availableCoursesSearch', val)
            },
            product(newValue) {
                if (newValue) {
                    this.syncValue(newValue)
                }
            },
            productTypeSelected(value) {
                if (value && value.id) {
                    this.product.productType.data = value;
                    this.product.product_type_id = value.id;
                } else {
                    this.product.product_type_id = null;
                }
            }
        },
        computed: {
            ...mapState('ProductStore', {
                dialogDeleteConfirmation: state => state.dialogDeleteConfirmation,
                dialogDeleting: state => state.dialogDeleting,
            }),

            product: {
                get() {
                    return this.value;
                },
                set(value) {
                    console.error(value)
                    this.$emit('input', value)
                },
            },

            isFullAccessProduct() {
                return ProductLogic.isFullAccessProduct(this.product)
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            current() {
                return this.$refs.MgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },
        },

        methods: {
            ...mapActions('ProductStore', [
                'openDialogDeleteConfirmation',
                'closeDialogDeleteConfirmation',
                'toggleDialogDeleteConfirmation',

                'openDialogDeleting',
                'toggleDialogDeleting'
            ]),

            syncValue(product) {
                if (product.courses) {
                    this.$emit('update:selectedCourses', product.courses.data)

                    let availableCourses = Object.assign([], this.availableCourses)

                    product.courses.data.forEach((course) => {
                        availableCourses.unshift(course)
                    })

                    this.$emit('update:availableCourses', availableCourses)
                }


                if (product.productType && product.productType.data) {
                    this.productTypeSelected = product.productType.data
                }


                if (product.tagStyles && product.tagStyles.data) {
                    this.currentTagStyles = product.tagStyles.data.map(tagStyle => tagStyle.id)
                }

                if (product.instruments && product.instruments.data) {
                    this.currentInstruments = product.instruments.data.map(instrument => instrument.id)
                }

                if (product.tagLevel && product.tagLevel.data) {
                    this.currentTagLevel = product.tagLevel.data.id
                }
            },

            // syncEditor() {
            //     setTimeout(() => {
            //         this.$refs.mgEditorDescription.refreshContent()
            //     }, 500)
            // },

            setIntervalCount(interval) {
                if (interval === 'quarterly') {
                    this.plan.interval = 'month';
                    this.plan.interval_count = 3;
                } else if (interval === 'semester') {
                    this.plan.interval = 'month';
                    this.plan.interval_count = 6;
                } else {
                    this.plan.interval = interval;
                    this.plan.interval_count = 1;
                }
            },
            customFilter(item, queryText) {
                const name = item.name.toLowerCase()
                const searchText = queryText.toLowerCase()

                return name.indexOf(searchText) > -1
            },

            validateProductForm() {
                if (this.product.product_type_id || this.productTypeSelected.id) {
                    return this.$validator.validateAll();
                } else {
                    this.categoryError = true;
                }
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submitLogo() {
                setTimeout(() => {
                    let formData = new FormData();

                    const file = this.$refs.logoInput.files[0];

                    formData.append('logo', file, file.name);

                    this.$emit('submitLogo', formData)

                    setTimeout(() => {
                        this.$refs.logoInput.files = null
                    }, 250)
                }, 100)
            },

            submitBackground() {
                setTimeout(() => {
                    let formData = new FormData();

                    const file = this.$refs.backgroundInput.files[0];

                    formData.append('background', file, file.name);

                    this.$emit('submitBackground', formData)

                    setTimeout(() => {
                        this.$refs.backgroundInput.files = null
                    }, 250)
                }, 100)
            },

            submit() {
                this.validateProductForm().then(isValid => {
                    if (isValid) {
                        let body = {};

                        _.forIn(this.fields, (value, key) => {
                            body[key] = this.product[key]
                        });

                        if (this.selectedCourses.length && this.fields.courses && this.fields.courses.dirty) {
                            body.courses = this.selectedCourses.map(course => course.id)
                        } else if (body.courses) {
                            delete body.courses;
                        }

                        if (this.currentTagStyles && this.currentTagStyles.length && this.fields.tagStyles && this.fields.tagStyles.dirty) {
                            body.tagStyles = this.currentTagStyles
                        } else if (body.tagStyles) {
                            delete body.tagStyles;
                        }

                        if (this.currentInstruments && this.currentInstruments.length && this.fields.instruments && this.fields.instruments.dirty) {
                            body.currentInstruments = this.currentInstruments
                        } else if (body.currentInstruments) {
                            delete body.currentInstruments;
                        }

                        if (this.currentTagLevel && this.currentTagLevel.length && this.fields.tagLevel && this.fields.tagLevel.dirty) {
                            body.tagLevel = this.currentTagLevel
                        } else if (body.tagLevel) {
                            delete body.tagLevel;
                        }

                        console.warn(body);

                        this.$emit('submit', body);
                    }
                })
            },


            deleteOnePopup() {
                if (this.product.plans_count) {
                    this.dialogCannotDelete = true
                } else {
                    this.openDialogDeleteConfirmation();
                }
            },

            deleteProduct() {
                this.closeDialogDeleteConfirmation()
                this.openDialogDeleting()
                const product = Object.assign({}, this.product);
                this.$emit('deleteProduct', product)
            },

            clickInput() {
                this.$refs.logoInput.click()
            },

            clickBackgroundInput() {
                this.$refs.backgroundInput.click()
            },
        }
    }
</script>
