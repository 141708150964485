<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar dense flat :dark="dark" :color="colorToolbar" v-if="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-container fluid class="px-5 py-4">
            <v-row>
                <template v-if="showCurrentLesson">
                    <v-col cols="12" class="py-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pl-0 pb-0 title font-weight-bold">
                                Leçon actuelle
                            </v-card-title>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-autocomplete
                                style="padding-top: 0"
                                :items="lessonsByCourseModules"
                                v-validate="'required'"
                                v-model="currentLesson"
                                :error-messages="errors.collect('current_lesson_id')"
                                data-vv-name="current_lesson_id"
                                :item-text="generateTitle"
                                return-object>
                            <template v-slot:selection="data">
                                <v-chip small class="caption" color="secondary">
                                    {{ data.item.title }} - {{ data.item.method_id }}
                                </v-chip>
                            </template>
                            <template v-slot:item="data">
                                <v-list-item-content
                                        v-if="typeof data.item !== 'object'"
                                        class="font-weight-bold"
                                        v-text="data.item">
                                </v-list-item-content>

                                <v-list-item-content v-else>
                                    <v-list-item-title
                                            class="">
                                        {{ data.item.title }} - {{ data.item.method_id }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action v-if="!data.item.enabled">
                                    <v-chip color="secondary" dark x-small>
                                        Désactivé
                                    </v-chip>
                                </v-list-item-action>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </template>

                <v-col cols="12" class="pa-0">
                    <v-card flat tile color="transparent">
                        <v-card-title class="pb-0 title font-weight-bold">
                            Accessible
                        </v-card-title>
                        <v-card-text class="pb-0">
                            Autoriser l'accessibilité dans l'espace membre
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-checkbox
                            v-validate
                            v-model="userCourse.visible"
                            :error-messages="errors.collect('visible')"
                            label="Oui"
                            data-vv-name="visible"
                            :true-value="true"
                            :false-value="false"
                            @change="fields.visible.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6">
                    <mg-datepicker
                            :label="userCourse.visible ? 'Inaccessible à partir du' : 'Accessible à partir du'"
                            v-model="userCourse.toggle_visible_on"
                            v-validate
                            persistent-hint
                            :hint="'Aucune date = ne s\'inverse jamais'"
                            :min="new Date().toISOString()"
                            data-vv-name="toggle_enabled_on">
                    </mg-datepicker>
                </v-col>

                <v-col cols="12" class="pa-0">
                    <v-card flat tile color="transparent">
                        <v-card-title class="pb-0 title font-weight-bold">
                            Activer le cours
                        </v-card-title>
                        <v-card-text class="py-0">
                            Autoriser l'utilisateur à accéder à ses leçons
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-checkbox
                            v-validate
                            v-model="userCourse.enabled"
                            :error-messages="errors.collect('enabled')"
                            label="Oui"
                            data-vv-name="enabled"
                            :true-value="true"
                            :false-value="false"
                            @change="fields.enabled.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>


                <v-col cols="12" sm="6">
                    <mg-datepicker
                            :label="userCourse.enabled ? 'Inaccessible à partir du' : 'Accessible à partir du'"
                            v-model="userCourse.toggle_enabled_on"
                            persistent-hint
                            v-validate
                            :hint="'Aucune date = ne s\'inverse jamais'"
                            :min="new Date().toISOString()"
                            data-vv-name="toggle_enabled_on">
                    </mg-datepicker>
                </v-col>

                <v-col cols="12" class="pa-0">
                    <v-card flat tile color="transparent">
                        <v-card-title class="pb-0 title font-weight-bold">
                            Déblocage progressif
                        </v-card-title>
                        <v-card-text class="pb-0">
                            Autoriser le déblocage automatique de la leçon suivante
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-checkbox
                            v-validate
                            v-model="userCourse.dripping_enabled"
                            :error-messages="errors.collect('dripping_enabled')"
                            label="Oui"
                            data-vv-name="dripping_enabled"
                            :true-value="true"
                            :false-value="false"
                            @change="fields.dripping_enabled.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6">
                    <mg-datepicker
                            :label="userCourse.dripping_enabled ? 'À désactiver le' : 'À activer le'"
                            v-model="userCourse.toggle_dripping_enabled_on"
                            v-validate
                            persistent-hint
                            :hint="'Aucune date = ne s\'inverse jamais'"
                            :min="new Date().toISOString()"
                            data-vv-name="toggle_dripping_enabled_on">
                    </mg-datepicker>
                </v-col>

                <template v-if="showVipCheckbox">
                    <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Vip
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Autoriser l'utilisateur à accéder aux ressources VIP
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                            v-validate
                            v-model="userCourse.vip"
                            :error-messages="errors.collect('vip')"
                            label="Oui"
                            data-vv-name="vip"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.vip.dirty = true"
                            color="secondary"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <mg-datepicker
                            :label="userCourse.vip ? 'À désactiver le' : 'À activer le'"
                            v-model="userCourse.toggle_vip_on"
                            v-validate
                            persistent-hint
                            :hint="'Aucune date = ne s\'inverse jamais'"
                            :min="new Date().toISOString()"
                            data-vv-name="toggle_vip_on">
                        </mg-datepicker>
                    </v-col>
                </template>
                <template v-if="userCourse.dripping_enabled">
                    <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Notification déblocage automatique
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Activer l'envoi d'une notification automatique lors ce que la prochaine leçon est automatiquement débloqué
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                                v-validate
                                v-model="userCourse.notify_dripping"
                                :error-messages="errors.collect('notify_dripping')"
                                label="Oui"
                                data-vv-name="notify_dripping"
                                :true-value="true"
                                :false-value="false"
                                @change="fields.notify_dripping.dirty = true"
                                color="secondary"
                        ></v-checkbox>
                    </v-col>
                </template>
            </v-row>
        </v-container>

        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import CourseLogic from "mg-js-core/lib/logics/CourseLogic";
    import _ from 'lodash'

    export default {
        name: 'course-drip-content-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            prices: Array,

            dialog: Boolean,
            isDialog: Boolean,
            showVipCheckbox: Boolean,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            loading: Boolean,
            showCurrentLesson: Boolean,

            disabled: Boolean,
            dark: Boolean,

            value: Object,
            courseModules: Array,

            colorToolbar: {
                type: String,
                default: null
            },

            showToolbar: {
                type: String,
                default: null
            },
        },

        data: () => ({
            currentLesson: null
        }),

        mounted() {
            if (this.value && this.value.currentLesson) {
                this.currentLesson = this.getRelatedLesson(this.value.currentLesson);
            }
        },

        watch: {
            value: {
                handler(value, oldValue) {
                    if (value.id !== oldValue.id) {
                        this.resetValidation()
                    }
                },
                deep: true
            },
        },
        computed: {
            userCourse: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            currentCourseModule() {
                return this.userCourse.currentCourseModule;
            },

            lessonsByCourseModules() {
                let lessonsByCourseModules = [];

                _.each(this.courseModules, (courseModule, key) => {
                    lessonsByCourseModules.push({
                        header: courseModule.title
                    })

                    if (courseModule.lessons && courseModule.lessons.data) {
                        _.each(courseModule.lessons.data, (lesson) => {
                            lessonsByCourseModules.push(lesson)
                        })
                    }

                    if (key + 1 < this.courseModules.length) {
                        lessonsByCourseModules.push({
                            divider: true
                        })
                    }

                })

                return lessonsByCourseModules;
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },
        },

        methods: {
            generateTitle(item) {
                return `${item.title} ${item.method_id}`
            },

            getRelatedLesson(courseModuleDripContentLesson) {
                return courseModuleDripContentLesson && courseModuleDripContentLesson.lesson && courseModuleDripContentLesson.lesson.data
                    ? courseModuleDripContentLesson.lesson.data
                    : LessonLogic.getEmptyObject()
            },

            validateCourseDripContentForm() {
                return this.$validator.validateAll();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                this.mgAlertComponent.closeAlert();

                this.validateCourseDripContentForm().then(isValid => {
                    if (isValid) {
                        let body = {};

                        _.forIn(this.fields, (value, key) => {
                            if (value.dirty && key !== 'current_lesson_id') {
                                body[key] = this.userCourse[key]
                            } else if (value.dirty && key === 'current_lesson_id') {
                                body['current_lesson_id'] = this.currentLesson.id
                            }
                        });

                        this.$emit('submit', this.userCourse.id, body);

                        this.$nextTick(() => {
                            this.$validator.reset();
                        })
                    }
                })
            },

            getRelatedCourse(userCourse) {
                return userCourse.course && userCourse.course.data
                    ? userCourse.course.data
                    : CourseLogic.getEmptyObject()
            },
        }
    }
</script>
