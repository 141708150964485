<template>
    <v-row>
        <v-col>
            <v-card>
                <v-app-bar flat dense color="secondary" class="mb-3" dark v-if="showToolbar">
                    <v-icon v-if="toolbarIcon">{{toolbarIcon}}</v-icon>
                    <v-toolbar-title class="font-weight-light">{{pageTitle}}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <template v-if="canAdd">

                        <v-btn icon
                               @click="$emit('update:dialogAdd', true)">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>

                    </template>
                </v-app-bar>

                <v-divider v-if="Object.keys(localFilters).length > 0"></v-divider>

                <v-data-table
                        :height="tableHeight"
                        v-model="selectedItems"
                        :options.sync="localPagination"
                        :server-items-length="totalItems"
                        show-select
                        :loading="isLoading"
                        :footer-props="{
                            'items-per-page-options' : [5, 10, 20, 50, 100]
                        }"
                        :headers="headers"
                        :items="comments"
                        :fixed-header="fixedHeader"
                >

                    <template v-slot:top>
                        <v-card flat color="creamy" tile class="mb-4">
                            <v-card-text class="py-0" v-if="Object.keys(localFilters).length > 0">
                                <v-row justify="center">
                                    <v-col cols="12" sm="4" md="2" v-if="localFilters.hasOwnProperty('search')">
                                        <v-text-field
                                                v-model="localFilters.search"
                                                ref="search"
                                                prepend-icon="mdi-magnify"
                                                @click:prepend="$refs.search.focus()"
                                                clearable
                                                label="Titre"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col class="align-self-center" cols="12" sm="4" md="1" v-if="localFilters.hasOwnProperty('is_read')">
                                        <v-select
                                                v-model="localFilters.is_read"
                                                :items="readItems"
                                                item-text="text"
                                                item-value="value"
                                                label="Statut de lecture"
                                                hide-details
                                                dense>
                                        </v-select>
                                    </v-col>

                                    <v-col class="align-self-center" cols="12" sm="4" md="1" v-if="localFilters.hasOwnProperty('enabled')">
                                        <v-select
                                                v-model="localFilters.enabled"
                                                :items="enabledItems"
                                                item-text="text"
                                                item-value="value"
                                                label="Activé/Désactivé"
                                                hide-details
                                                dense>
                                        </v-select>
                                    </v-col>

                                    <v-col class="align-self-center" cols="12" sm="4" md="1" v-if="localFilters.hasOwnProperty('is_answer')">
                                        <v-select
                                                v-model="localFilters.is_answer"
                                                :items="answerItems"
                                                item-text="text"
                                                item-value="value"
                                                label="Statut de réponse"
                                                hide-details
                                                dense>
                                        </v-select>
                                    </v-col>

                                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="localFilters.hasOwnProperty('from_date')">
                                        <v-menu
                                                v-model="fromDateMenu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        class="py-0 my-0"
                                                        label="Après le"
                                                        readonly
                                                        :value="humanizeDate(localFilters.from_date)"
                                                        v-on="on"
                                                        hide-details
                                                        clearable
                                                        @click:clear="localFilters.from_date = null"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    locale="en-in"
                                                    v-model="localFilters.from_date"
                                                    no-title
                                                    first-day-of-week="1"
                                                    @input="fromDateMenu = false"
                                                ></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="localFilters.hasOwnProperty('platform_code')">
                                        <mg-select-platform
                                            name="platform_code"
                                            v-validate
                                            clearable
                                            v-model="localFilters.platform_code">
                                        </mg-select-platform>
                                    </v-col>

                                    <v-col cols="12" sm="4" md="2" v-if="localFilters.hasOwnProperty('product_types')">
                                        <v-autocomplete
                                                :items="productTypeItems"
                                                v-model="localFilters.product_types"
                                                item-text="title"
                                                item-value="id"
                                                multiple
                                                chip
                                                label="Catégorie(s)"
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip small class="caption">
                                                    {{ data.item.title }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>

                                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="localFilters.hasOwnProperty('course_id')">
                                        <v-autocomplete
                                                v-model="localFilters.course_id"
                                                :items="courseItems"
                                                item-text="title"
                                                item-value="id"
                                                label="Cours"
                                                clearable
                                        >
                                            <template v-slot:selection="data">
                                                    {{ data.item.title }}
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text v-if="selectedItems.length">
                                <v-btn small
                                       v-if="!localFilters.is_read"
                                       :disabled="!selectedItems.length"
                                       @click="markAsRead(selectedItems)">
                                    Marquer comme lu
                                </v-btn>
                                <v-btn small
                                       v-else
                                       :disabled="!selectedItems.length"
                                       @click="markAsUnread(selectedItems)">
                                    Marquer comme non lu
                                </v-btn>
                                <v-btn small
                                       class="ml-4"
                                       v-if="!localFilters.is_read"
                                       :disabled="!selectedItems.length"
                                       @click="disable(selectedItems)">
                                    Désactiver
                                </v-btn>
                                <v-btn small
                                       class="ml-4"
                                       v-if="!localFilters.is_read"
                                       :disabled="!selectedItems.length"
                                       @click="remove(selectedItems)">
                                    Supprimer
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </template>

                    <template v-slot:item.user="{ item }">
                        <router-link class="default--text"
                                     target="_blank"
                                     v-if="item.user && item.user.data"
                                     :to="{name: userEditRouteName, params: {uuid: item.user.data.id}}">
                            {{ item.user && item.user.data ? item.user.data.username : '-'}}
                        </router-link>
                    </template>
                    <template v-slot:item.lesson="{ item }">
                        <router-link class="default--text"
                                     target="_blank"
                                     v-if="item.lesson && item.lesson.data"
                                     :to="{name: lessonEditRouteName, hash: '#'+ item.id, params: {uuid: item.lesson.data.id, lessonTab: 'comments'}}">
                            {{ item.lesson.data.title ? item.lesson.data.title : '-'}}
                        </router-link>
                        <div v-else class="default--text">-</div>
                    </template>
                    <template v-slot:item.lesson_method_id="{ item }">
                        <template v-if="item.lesson && item.lesson.data">
                            {{ item.lesson.data.method_id ? item.lesson.data.method_id : ''}}
                        </template>
                        <div v-else class="default--text">-</div>
                    </template>
                    <template v-slot:item.course="{ item }">
                        <router-link class="default--text"
                                     target="_blank"
                                     v-if="item.course && item.course.data"
                                     :to="{name: courseEditRouteName, params: {uuid: item.course.data.id}}">
                            {{ item.course.data.title ? item.course.data.title : '-'}}
                        </router-link>
                        <div v-else class="default--text">-</div>
                    </template>
                    <template v-slot:item.message="{ item }">
                        <div class="py-3" v-html="item.message"></div>
                    </template>
                    <template v-slot:item.rating="{ item }">
                        <v-rating dense small readonly
                                  background-color="primary"
                                  :value="item.rating">
                        </v-rating>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ Helpers.parseDate(item.created_at) }}
                    </template>
                    <template v-slot:item.enabled="{ item }">
                        {{ item.enabled ? 'Oui' : 'Non' }}
                    </template>
                    <template v-slot:item.is_answer="{ item }">
                        {{ item.is_answer ? 'Oui' : 'Non' }}
                    </template>
                    <template v-slot:item.is_read="{ item }">
                        {{ item.is_read ? 'Oui' : 'Non' }}
                    </template>
                    <template v-slot:item.is_liked="{ item }">
                        {{ item.is_liked ? 'Oui' : 'Non' }}
                    </template>
                    <template v-slot:item.action="{ item }">

                        <v-menu offset-y bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon fab small v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense class="py-0">
                                <template>
                                    <v-list-item @click="openModerationModal(item)">
                                        <v-list-item-title v-if="item.course">
                                            Editer
                                        </v-list-item-title>
                                        <v-list-item-title v-else>
                                            Répondre / Editer
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </template>

                                <v-list-item @click="item.is_read ? markAsUnread([item]) : markAsRead([item])">
                                    <v-list-item-title>
                                        {{ item.is_read ? 'Marquer non comme lu' : 'Marquer comme lu'}}
                                    </v-list-item-title>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item @click="item.is_liked ? unlike(item) : like(item)">
                                    <v-list-item-title>
                                        {{ item.is_liked ? 'Unliker' : 'Liker'}}
                                    </v-list-item-title>
                                </v-list-item>

                                <v-divider></v-divider>


                                <v-list-item @click="updateComment(item, {enabled : !item.enabled})">
                                    <v-list-item-title>
                                        {{item.enabled ? 'Désactiver' : 'Activer'}}
                                    </v-list-item-title>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item @click="deleteComment(item)">
                                    <v-list-item-title class="primary--text">
                                        Supprimer
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

        <comment-course-dialog
                v-if="as === 'course'"
                :key="currentComment"
                ref="commentCourseDialog"
                :dialogEdit="dialogEdit"
                @update:dialogEdit="$emit('update:dialogEdit', $event)"
                :currentComment="currentComment"
                @update:currentComment="$emit('update:currentComment', $event)"
                :currentCourse="currentCourse"

                @refresh="openModerationModal"

                @addSubMessage="addSubMessage"
                @deleteComment="deleteComment"
                @updateComment="updateComment"
                >
        </comment-course-dialog>

        <comment-lesson-dialog
                v-if="as === 'lesson'"
                ref="commentLessonDialog"
                :dialogEdit="dialogEdit"
                @update:dialogEdit="$emit('update:dialogEdit', $event)"
                :currentComment="currentComment"
                @update:currentComment="$emit('update:currentComment', $event)"
                :parentComment="parentComment"
                @update:parentComment="$emit('update:parentComment', $event)"
                :currentLesson="currentLesson"
                @update:currentLesson="$emit('update:currentLesson', $event)"
                :lessons="lessons"
                @update:lessons="$emit('update:lessons', $event)"
                :currentLessonParts="currentLessonParts"
                @update:currentLessonParts="$emit('update:currentLessonParts', $event)"

                @refresh="openModerationModal"

                @addSubMessage="addSubMessage"
                @deleteComment="deleteComment"
                @updateComment="updateComment"
        >

        </comment-lesson-dialog>
    </v-row>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import CommentLogic from "mg-js-core/lib/logics/CommentLogic";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import CourseLogic from "mg-js-core/lib/logics/CourseLogic";
    import MgSelectPlatform from "../platform/MgPlatformSelect";
    import CommentCourseDialog from './CommentCourseDialog.vue';

    export default {
        name: 'comment-table',

        components: {MgSelectPlatform, CommentCourseDialog},

        props: {
            comments: Array,
            headers: Array,
            as: {
                type: String,
                default: 'lesson'
            },
            filters: {
                type: Object,
                default() {
                    return {}
                }
            },
            totalItems: Number,
            selectedComments: Array,

            pagination: Object,
            loading: Boolean,
            pageTitle: String,
            toolbarIcon: String,

            showToolbar: {
                type: Boolean,
                default: false
            },

            fixedHeader: {
                type: Boolean,
                default: false
            },

            tableHeight: {
                type: String,
                default: '65vh'
            },

            routeNameShowComment: {
                type: String,
            },

            userEditRouteName: {
                type: String,
                default: 'admin-user-edit'
            },

            lessonEditRouteName: {
                type: String,
                default: 'admin-lesson-edit'
            },

            courseEditRouteName: {
                type: String,
                default: 'admin-course-edit'
            },

            /* add */
            canAdd: Boolean,
            dialogEdit: Boolean,
            addLoading: Boolean,
            newComment: Object,

            currentCourse: {
                type: Object,
                default: () => (CourseLogic.getEmptyObject())
            },
            currentComment: {
                type: Object,
                default: () => (CommentLogic.getEmptyObject())
            },
            parentComment: {
                type: Object,
                default: () => (CommentLogic.getEmptyObject())
            },
            currentLesson: {
                type: Object,
                default: () => (LessonLogic.getEmptyObject())
            },
            lessons: {
                type: Array,
                default: () => ([])
            },
            currentLessonParts: {
                type: Array,
                default: () => ([])
            },
            courseItems: {
                type: Array,
                default: () => ([])
            },
            productTypeItems: {
                type: Array,
                default: () => ([])
            },
        },

        data: () => ({
            Helpers,

            readItems: [
                {text: 'Tous', value: null},
                {text: 'Lu', value: 1},
                {text: 'Non Lu', value: 0},
            ],

            answerItems: [
                {text: 'Tous', value: null},
                {text: 'Répondu', value: 1},
                {text: 'Non Répondu', value: 0},
            ],

            enabledItems: [
                {text: 'Tous', value: null},
                {text: 'Activé', value: 1},
                {text: 'Désactivé', value: 0},
            ],

            fromDateMenu: false,
        }),


        mounted() {
        },

        computed: {
            localPagination: {
                get() {
                    return this.pagination;
                },

                set(value) {
                    this.$emit('update:pagination', value)
                }
            },
            localFilters: {
                get() {
                    return this.filters;
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },
            isLoading: {
                get() {
                    return this.loading;
                },

                set(value) {
                    this.$emit('update:loading', value)
                }
            },
            selectedItems: {
                get() {
                    return this.selectedComments;
                },

                set(value) {
                    this.$emit('update:selectedComments', value)
                }
            },

            // useful for parent component
            commentFormAddComponent() {
                return this.$refs.commentFormAdd
            },

            commentLessonDialog() {
                return this.$refs.commentLessonDialog
            },
        },

        watch: {},

        methods: {
            openModerationModal(comment) {
                if (this.as === 'lesson') {
                    this.$emit('openCommentLessonDialog', comment.parent && comment.parent.data ? comment.parent.data : comment)
                } else if (this.as === 'course') {
                    this.$emit('openCommentCourseDialog', comment)
                } else {
                    alert('type inconnu')
                }
            },

            addSubMessage(htmlMessage) {
                this.$emit('addSubMessage', htmlMessage)
            },

            deleteComment(comment) {
                this.$emit('deleteComment', comment)
            },

            updateComment(currentComment, body = {}) {
                this.$emit('updateComment', currentComment, body)
            },

            /* NEW */
            markAsRead(selectedItems) {
                this.$emit('markAsRead', selectedItems)
            },

            markAsUnread(selectedItems) {
                this.$emit('markAsUnread', selectedItems)
            },

            remove(selectedItems) {
                this.$emit('removeSelection', selectedItems)
            },

            like(comment) {
                this.$emit('like', comment);
            },

            unlike(comment) {
                this.$emit('unlike', comment);
            },

            disable(selectedItems) {
                selectedItems.forEach((item) => this.$emit('updateComment', item, {enabled : false}));
            },

            humanizeDate(value) {
                return value ? this.$moment(value).format('DD/MM/YYYY') : null;
            },
        }
    };
</script>
