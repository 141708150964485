<template>
    <v-card flat>
        <v-app-bar flat dark dense color="secondary" class="title">
            <v-spacer></v-spacer>
            <v-menu offset-y bottom left v-if="!coursesIsSortable">
                <template v-slot:activator="{ on }">

                    <v-btn fab small icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list v-if="isInit" dense class="py-0">
                    <v-list-item @click="$emit('update:dialogAddCourseDripContent', true)">
                        <v-list-item-title>Ajouter un cours</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('update:dialogAddProductDripContent', true)">
                        <v-list-item-title>Ajouter un produit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('update:dialogAddSubscriptionDripContent', true)">
                        <v-list-item-title>Ajouter un produit par abonnement</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <mg-alert ref="mgAlert"></mg-alert>

        <template v-if="userCourses.length">
            <v-alert
                    tile
                    v-if="!getRelatedCourse(currentCourse).enabled"
                    class="my-0 text-center"
                    dark
                    color="error">
                <v-icon class="mr-2" size="20">mdi-alert</v-icon>
                Le cours est inaccessible car celui-ci est désactivé du contenu disponible.
            </v-alert>
            <v-alert
                    tile
                    v-else-if="!currentCourse.enabled"
                    class="my-0 text-center"
                    dark
                    color="warning">
                <v-icon class="mr-2" size="20">mdi-alert</v-icon>
                Le cours est désactivé. L'utilisateur ne peut plus passer de leçon.
            </v-alert>
        </template>

        <v-row class="px-1" v-if="!userCourses.length">
            <v-col cols="12">
                <v-card v-if="isInit" flat style="height: 100%; max-height: 500px; overflow-y: scroll">
                    <v-card-text class="text-center">
                        <v-btn color="secondary"
                               @click="$emit('update:dialogAddCourseDripContent', true)">Ajouter un cours
                        </v-btn>
                    </v-card-text>
                </v-card>

                <v-card flat tile
                        v-else
                        class="text-center">
                    <v-row>
                        <v-col align-self="center">
                            <v-progress-circular
                                    indeterminate
                                    color="red"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-container fluid v-else>
            <v-row>
                <v-col md="4">
                    <v-card flat style="max-height : 60vh; overflow-y: scroll"
                            :style="$vuetify.breakpoint.mdAndUp ? 'min-height : 50vh;' : null">

                        <v-list two-line class="py-0">
                            <v-list-item-group mandatory v-model="currentCourseKeyValue">
                                <div v-for="userCourse in userCourses"
                                     :key="userCourse.id">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ getRelatedCourse(userCourse).title }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ Math.ceil(userCourse.percentage_achievement) }}%
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <v-progress-linear
                                                        class="ma-0"
                                                        v-if="userCourse.id"
                                                        :value="userCourse.percentage_achievement"
                                                        :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
                                                        height="7">
                                                </v-progress-linear>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>


                                        <v-list-item-action v-if="!userCourse.visible">
                                            <v-chip color="secondary" dark>
                                                Invisible
                                            </v-chip>
                                        </v-list-item-action>

                                        <v-list-item-action
                                                v-else-if="!userCourse.enabled || !getRelatedCourse(userCourse).enabled">
                                            <v-chip color="secondary" dark>
                                                Désactivé
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>

                                </div>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>

                <v-col md="8">
                    <user-course-module-list-form
                            flat
                            v-if="currentCourse"
                            ref="updateForm"
                            @updateUserCourse="updateUserCourse"
                            @updateUserCourseModule="updateUserCourseModule"

                            :canDelete="canDelete"

                            :dialogDelete="dialogDelete"
                            @update:dialogDelete="$emit('update:dialogDelete', $event)"

                            :dialogDeletingLoading="dialogDeletingLoading"
                            @update:dialogDeletingLoading="$emit('update:dialogDeletingLoading', $event)"

                            @deleteCourse="deleteCourse"
                            :loading="updateCourseLoading"
                            :value="courseModules"
                            :currentCourseModuleKey="currentCourseModuleKey"

                            :currentLesson.sync="currentLesson"

                            :userCourseModules.sync="userCourseModules"
                            :course="currentCourse"
                    ></user-course-module-list-form>
                </v-col>
            </v-row>
        </v-container>


        <v-dialog :value="dialogAddCourseDripContent"
                  @input="$emit('update:dialogAddCourseDripContent', $event)"
                  max-width="900px">
            <course-drip-content-stepper
                    dark
                    isDialog
                    ref="addForm"
                    :dialog="dialogAddCourseDripContent"
                    @update:dialog="$emit('update:dialogAddCourseDripContent', $event)"
                    toolbarTitle="Ajouter du contenu"
                    showToolbar="secondary"

                    v-model="newCourseDripContent"

                    :availableCourses="availableCourses"
                    @update:availableCourses="$emit('update:availableCourses', $event)"
                    :selectedCourse.sync="newCourseDripContent"
                    :searchCourse="searchCourse"
                    @update:searchCourse="$emit('update:searchCourse', $event)"
                    :step="step"
                    @update:step="$emit('update:step', $event)"
                    :courseModulesSelected="courseModulesSelected"
                    @update:courseModulesSelected="$emit('update:courseModulesSelected', $event)"
                    :currentLesson.sync="newCurrentLesson"

                    :loading="addCourseDripContentLoading"
                    @update:loading="$emit('update:addCourseDripContentLoading', $event)"

                    @submit="addCourseDripContent"
            >
            </course-drip-content-stepper>
        </v-dialog>

        <v-dialog :value="dialogAddProductDripContent"
                  @input="$emit('update:dialogAddCourseDripContent', $event)"
                  max-width="900px">
            <product-drip-content-stepper
                    dark
                    isDialog
                    ref="addForm"
                    :dialog="dialogAddCourseDripContent"
                    @update:dialog="$emit('update:dialogAddProductDripContent', $event)"
                    toolbarTitle="Ajouter du contenu"
                    showToolbar="secondary"

                    v-model="newCourseDripContent"

                    :availableProducts="availableProducts"
                    @update:availableProducts="$emit('update:availableProducts', $event)"
                    :selectedProduct.sync="newProductDripContent"
                    :searchProduct="searchProduct"
                    @update:searchProduct="$emit('update:searchProduct', $event)"
                    :step="step"
                    @update:step="$emit('update:step', $event)"

                    :loading="addProductDripContentLoading"

                    @submit="addProductDripContent"
            >
            </product-drip-content-stepper>
        </v-dialog>

        <v-dialog :value="dialogAddSubscriptionDripContent"
            @input="$emit('update:dialogAddSubscriptionDripContent', $event)"
            max-width="900px">
            <plan-drip-content-stepper
                    dark
                    isDialog
                    ref="addForm"
                    :dialog="dialogAddSubscriptionDripContent"
                    @update:dialog="$emit('update:dialogAddSubscriptionDripContent', $event)"
                    toolbarTitle="Ajouter du contenu"
                    showToolbar="secondary"

                    :availableSubscriptionProducts="availableSubscriptionProducts"
                    @update:availableSubscriptionProducts="$emit('update:availableSubscriptionProducts', $event)"
                    :selectedSubscriptionProduct.sync="newSubscriptionProduct"
                    :searchSubscriptionProduct="searchSubscriptionProduct"
                    @update:searchSubscriptionProduct="$emit('update:searchSubscriptionProduct', $event)"
                    @update:selectedSubscriptionProduct="$emit('update:selectedSubscriptionProduct', $event)"


                    :availablePlans="availablePlans"
                    @update:availablePlans="$emit('update:availablePlans', $event)"
                    :selectedPlan.sync="newPlanDripContent"
                    :searchPlan="searchPlan"
                    @update:searchPlan="$emit('update:searchPlan', $event)"
                    @update:selectedPlan="$emit('update:selectedPlan', $event)"
                    :step="step"
                    @update:step="$emit('update:step', $event)"

                    :loading="addSubscriptionDripContentLoading"

                    @submit="addSubscriptionDripContent"
            >
            </plan-drip-content-stepper>
        </v-dialog>
    </v-card>
</template>

<style lang="scss" scoped>
</style>

<script>
    import _ from 'lodash';
    import CourseLogic from "mg-js-core/lib/logics/CourseLogic";
    import ProductLogic from "mg-js-core/lib/logics/ProductLogic";
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import CourseDripContentModel from "mg-js-core/lib/models/CourseDripContentModel";
    import PlanDripContentStepper from './PlanDripContentStepper.vue';
    import PlanLogic from "mg-js-core/lib/logics/PlanLogic";

    export default {
        name: 'course-drip-content-list',

        props: {
            value: {
                type: Array,
                required: true,
            },

            courseModules: {
                type: Array,
                default: () => ([])
            },

            userCourseModules: {
                type: Array,
                default: () => ([])
            },

            flat: Boolean,

            currentCourseKey: {
                type: Number,
                default: 0
            },

            isInit: Boolean,
            canDelete: Boolean,
            dialogDelete: Boolean,
            coursesIsSortable: Boolean,

            availableCourses: Array,
            availableProducts: Array,
            availablePlans: Array,
            availableSubscriptionProducts: Array,
            courseModulesSelected: Array,
            selectedCourse: Object,
            selectedProduct: Object,
            selectedPlan: Object,
            selectedSubscriptionProduct: Object,
            searchCourse: String,
            searchProduct: String,
            searchPlan: String,
            searchSubscriptionProduct: String,
            step: Number,
            addCourseDripContentLoading: Boolean,
            addProductDripContentLoading: Boolean,
            dialogAddCourseDripContent: Boolean,
            dialogAddProductDripContent: Boolean,
            dialogAddSubscriptionDripContent: Boolean,
            addSubscriptionDripContentLoading: Boolean,

            currentCourseModuleKey: Number,

            updateCourseLoading: Boolean,
            dialogDeletingLoading: Boolean,
        },

        components: {
            PlanDripContentStepper,
        },

        data: () => ({
            PageStore,

            newResource: {
                title: '',
                file: null,
            },

            newCourseDripContent: CourseLogic.getEmptyConfigObject(),
            newProductDripContent: CourseLogic.getEmptyObject(),
            newPlanDripContent: PlanLogic.getEmptyObject(),
            newSubscriptionProduct: ProductLogic.getEmptyObject(),
            newCurrentLesson: LessonLogic.getEmptyObject(),
            currentLesson: LessonLogic.getEmptyObject(),
        }),

        watch: {
            currentCourse: {
                handler(value) {
                    this.currentLesson = value.currentUserCourseModuleLesson && value.currentUserCourseModuleLesson.data
                        ? value.currentUserCourseModuleLesson.data
                        : LessonLogic.getEmptyObject()
                },
                deep: true
            },
            dialogAddCourseDripContent: {
                handler() {
                    this.newCourseDripContent = CourseLogic.getEmptyConfigObject();
                    this.newCurrentLesson = CourseLogic.getEmptyObject();
                },
                deep: true
            },
            dialogAddProductDripContent: {
                handler() {
                    this.newProductDripContent = ProductLogic.getEmptyObject();
                },
                deep: true
            },
            dialogAddSubscriptionDripContent: {
                handler() {
                    this.newSubscriptionProduct = ProductLogic.getEmptyObject();
                    this.newPlanDripContent = PlanLogic.getEmptyObject();
                },
                deep: true
            },
            newSubscriptionProduct: {
                handler() {
                    this.newPlanDripContent = PlanLogic.getEmptyObject();
                },
                deep: true
            }
        },

        computed: {
            userCourses: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            currentCourseKeyValue: {
                get() {
                    return this.currentCourseKey
                },
                set(value) {
                    this.$emit('update:currentCourseKey', value)
                },
            },

            currentCourse() {
                return this.userCourses && this.userCourses.length
                    ? this.userCourses[this.currentCourseKey]
                    : new CourseDripContentModel()
            },

            currentCourseModule() {
                return this.currentCourse.currentCourseModule;
            },

            addFormComponent() {
                return this.$refs.addForm
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            updateFormComponent() {
                return this.$refs.updateForm
            },

            currentUserCourseModules() {
                return this.currentCourse && this.currentCourse.userCourseModules && this.currentCourse.userCourseModules.data
                    ? this.currentCourse.userCourseModules.data
                    : []
            },
        },

        mounted() {

        },

        methods: {
            showLesson(lesson) {
                this.$emit('showLesson', lesson)
            },

            currentLessons(lessons) {
                return _.sortBy(lessons, ['position'])
            },

            addCourseDripContent(body) {
                this.$emit('addCourseDripContent', body);
            },

            addProductDripContent(body) {
                this.$emit('addProductDripContent', body);
            },

            addSubscriptionDripContent(body) {
                this.$emit('addSubscriptionDripContent', body);
            },

            deleteCourse(courseId) {
                this.$emit('deleteCourse', courseId);
            },

            updateUserCourse(id, body) {
                this.$emit('updateUserCourse', id, body)
            },

            updateUserCourseModule(id, body) {
                this.$emit('updateUserCourseModule', id, body)
            },

            addCourseFormError(errors) {
                this.addFormComponent.addFormErrors(errors)
            },

            /* NEW */
            getRelatedCourse(userCourse) {
                return userCourse.course && userCourse.course.data
                    ? userCourse.course.data
                    : CourseLogic.getEmptyObject()
            },
        }
    };
</script>
