<template>
    <v-dialog :value="dialogEdit"
              fullscreen hide-overlay transition="dialog-bottom-transition"
              color="creamy"
              @input="$emit('update:dialogEdit', $event)">

        <v-card flat tile color="creamy">
            <v-app-bar tile flat dense dark color="secondary">
                <v-btn icon small fab dark @click="$emit('update:dialogEdit', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="flex-grow-1"></div>
            </v-app-bar>

            <v-row
                    v-if="currentLesson && currentLesson.id"
                    class="pt-3"
                    justify="center">
                <v-col cols="12" md="11" xl="9">
                    <v-card flat class="transparent">
                        <v-row>
                            <v-col class="px-1">

                                <v-tabs

                                        v-model="currentIndexResourceTab"
                                        :background-color="'secondary'"
                                        dark
                                        show-arrows
                                        slider-color="primary">
                                    <v-tab
                                            style="transition: none;"
                                            v-for="resourceTab in resourceTabs"
                                            :key="resourceTab.title"
                                            ripple>
                                        <v-icon class="pr-2">{{resourceTab.icon}}</v-icon>
                                        {{resourceTab.title}}
                                    </v-tab>
                                    <template v-for="resourceTab in resourceTabs">
                                        <v-tab-item
                                                class="mb-3"
                                                style="max-height: 700px; overflow: scroll"
                                                color="creamy"
                                                :key="resourceTab.title">
                                            <v-card class="lesson-part-tabs"
                                                    id="contentComments"
                                                    style="min-height: 200px;"
                                                    v-if="resourceTab.id === 'comments'">

                                                <mg-comment-refact
                                                        canValidate
                                                        canAnswer
                                                        showDescendants

                                                        ref="mgCommentRefact"
                                                        @addMessage="addSubMessage"
                                                        @deleteComment="deleteComment"


                                                        @updateComment="updateComment"

                                                        :commentAnswerIdShow.sync="commentAnswerId"
                                                        :lesson.sync="currentLesson"
                                                        @refresh="refresh(currentComment)"
                                                        focusCurrent
                                                        @showSubThread="commentAnswerId = $event.id"
                                                        :comment="parentComment"
                                                        @update:comment="$emit('update:parentComment', $event)"
                                                >
                                                </mg-comment-refact>
                                            </v-card>
                                        </v-tab-item>
                                    </template>
                                </v-tabs>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="12" md="11" xl="9">
                    <v-card flat class="transparent">
                        <v-row>
                            <v-col class="pl-1 pr-1"
                                   v-resize="onResize"

                                   cols="12" md="9" lg="8" order-md="2"
                                   ref="player-block">
                                <iframe
                                        v-if="currentLessonPart && currentLessonPart.provider === 'youtube'"
                                        width="100%" :height="playerHeight"
                                        :src="`//www.youtube.com/embed/${currentLessonPart.video_id}`"
                                        frameborder="0" allowfullscreen></iframe>
                                <iframe
                                        v-else-if="currentLessonPart && currentLessonPart.provider === 'vimeo'"
                                        :src="`https://player.vimeo.com/video/${currentLessonPart.video_id}`"
                                        width="100%" :height="playerHeight"
                                        frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                                </iframe>
                                <v-card v-else-if="!currentLessonPart" flat
                                        :color="!$vuetify.theme.dark ? 'creamy' : 'dark'"
                                        width="100%" :height="playerHeight">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <div class="text-center">
                                            <v-icon size="100">mdi-lock-outline</v-icon>
                                        </div>
                                    </v-row>
                                </v-card>

                            </v-col>

                            <v-col class="pl-1 pr-1"
                                   cols="12" md="3" lg="4"
                                   order-md="1">
                                <v-tabs
                                        v-model="currentTab"
                                        :background-color="'secondary'"
                                        dark
                                        slider-color="primary">
                                    <v-tab ripple class="body-1 text-none">
                                        Leçon
                                    </v-tab>

                                    <v-tab-item>
                                        <v-card flat tile
                                                id="contentTab"
                                                :style="`height : ${playerHeight - 48}px; overflow:scroll`">
                                            <lesson-list-expansion-light
                                                    :lessons.sync="lessons"
                                                    :currentLessonParts.sync="currentLessonParts"
                                                    :currentLessonKey.sync="currentLessonKey"
                                                    :currentLessonPartIndex.sync="currentLessonPartIndex"
                                            ></lesson-list-expansion-light>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>

</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import CommentLogic from "mg-js-core/lib/logics/CommentLogic";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";

    export default {
        name: 'comment-lesson-dialog',

        props: {
            dialogEdit: Boolean,

            currentComment: {
                type: Object,
                default: () => (CommentLogic.getEmptyObject())
            },
            parentComment: {
                type: Object,
                default: () => (CommentLogic.getEmptyObject())
            },
            currentLesson: {
                type: Object,
                default: () => (LessonLogic.getEmptyObject())
            },
            lessons: {
                type: Array,
                default: () => ([])
            },
            currentLessonParts: {
                type: Array,
                default: () => ([])
            },
        },
        data: () => ({
            Helpers,


            currentTab: null,
            playerHeight: null,

            currentLessonKey: 0,
            currentLessonPartIndex: 0,
            currentIndexResourceTab: 0,
            commentAnswerId: null,

            resourceTabs: [
                {
                    id: 'comments',
                    title: 'Commentaires',
                    icon: 'mdi-comment-outline'
                },
            ],
        }),


        mounted() {
            setTimeout(() => {
                this.onResize();
            }, 1000)
        },

        computed: {
            /* DIALOG */

            mgComment() {
                return this.$refs.mgCommentRefact
            },

            currentLessonPart() {
                return typeof this.currentLessonPartIndex === 'number'
                && this.currentLessonParts[this.currentLessonPartIndex]
                    ? this.currentLessonParts[this.currentLessonPartIndex]
                    : LessonLogic.getEmptyLessonPartObject();
            },
        },

        watch: {
        },

        methods: {
            refresh(comment) {
                this.$emit('refresh', comment)
            },

            addSubMessage(htmlMessage) {
                this.$emit('addSubMessage', htmlMessage)
            },

            deleteComment(comment) {
                this.$emit('deleteComment', comment)
            },

            updateComment(currentComment, body = {}) {
                this.$emit('updateComment', currentComment, body)
            },

            /* NEW */
            onResize() {
                this.playerWitdh = this.$refs['player-block'] && this.$refs['player-block'].offsetWidth > 0 ? 
                    this.$refs['player-block'].offsetWidth : 
                    400 * 1.78
                this.playerHeight = this.playerWitdh / 1.78
            },
        }
    };
</script>
