import { render, staticRenderFns } from "./ProductTypeListForm.vue?vue&type=template&id=6fa9abd1&scoped=true&"
import script from "./ProductTypeListForm.vue?vue&type=script&lang=js&"
export * from "./ProductTypeListForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa9abd1",
  null
  
)

export default component.exports