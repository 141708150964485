<template>
    <v-card flat>
        <v-app-bar flat tabs dense dark color="secondary">
            <v-spacer></v-spacer>

            <v-menu offset-y bottom left v-if="!courseModuleIsSortable">
                <template v-slot:activator="{ on }">
                    <v-btn icon fab small v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list dense class="py-0">
                    <v-list-item @click="sortCourseModules">
                        <v-list-item-title>Trier les modules</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item @click="$emit('update:dialogAdd', true)">
                        <v-list-item-title>Ajouter un module</v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-menu>

            <template v-if="courseModuleIsSortable">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="cancelSortCourseModules">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Annuler</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="submitSortCourseModules">
                            <v-icon small>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Enregistrer</span>
                </v-tooltip>
            </template>
        </v-app-bar>
        <!--<v-list class="py-0">-->
        <!--<v-divider></v-divider>-->

        <!--<v-list-item @click="currentKeyModule = key">-->
        <!--<v-list-item-content>-->
        <!--<v-list-item-title>{{ courseModule.title }}</v-list-item-title>-->
        <!--</v-list-item-content>-->
        <!--</v-list-item>-->
        <!--<v-divider v-if="courseModules.length - 1 === key"></v-divider>-->
        <!--</v-list>-->

        <mg-alert ref="mgAlert"></mg-alert>

        <v-container fluid>
            <v-row v-if="courseModules.length">
                <v-col md="4">
                    <v-card flat style="height: 100%; max-height: 1000px; overflow-y: scroll">
                        <v-list two-line class="py-0"
                                :class="courseModuleIsSortable ? 'isGrabable' : null">
                            <draggable v-model="courseModules"
                                       class="dragArea"
                                       :options="{sort: courseModuleIsSortable}"
                                       @change="courseModuleMoved">
                                <v-list-item
                                        :input-value="currentKeyModule === key"
                                        @click="!courseModuleIsSortable ? $emit('update:currentKeyModule', key) : null"
                                        :class="{'grabable' : courseModuleIsSortable}"
                                        v-for="(courseModule,key) in courseModules"
                                        :key="courseModule.id">
                                    <v-list-item-action
                                            v-if="courseModuleIsSortable"
                                            class="grabable">
                                        <v-icon>mdi-drag</v-icon>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ courseModule.title }}</v-list-item-title>
                                    </v-list-item-content>

                                    <v-chip dark small
                                            v-if="!courseModule.enabled">
                                        Désactivé
                                    </v-chip>

                                </v-list-item>

                            </draggable>

                        </v-list>
                    </v-card>
                </v-col>

                <v-col md="8">
                    <v-card flat>
                        <v-app-bar flat tabs dense dark color="secondary">
                            <v-tabs background-color="transparent">
                                <v-tabs-slider color="primary"></v-tabs-slider>

                                <v-tab exact :to="{
                                        name: 'admin-course-edit', params: {
                                            uuid: course.slug,
                                            courseTab: 'modules',
                                            courseModuleUuid: this.currentCourseModule.slug,
                                            courseModuleTab: null,
                                        }
                                    }">
                                    Général
                                </v-tab>
                                <v-tab exact :to="{
                                        name: 'admin-course-edit', params: {
                                            uuid: course.slug,
                                            courseTab: 'modules',
                                            courseModuleUuid: this.currentCourseModule.slug,
                                            courseModuleTab: 'lessons',
                                        }
                                    }">
                                    Leçons
                                </v-tab>
                            </v-tabs>

                            <v-spacer></v-spacer>

                            <template v-if="tabs === 'summary'">
                                <v-menu offset-y bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon fab small v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense class="py-0">
                                        <v-list-item @click="openDeleteDialog">
                                            <v-list-item-title class="primary--text">
                                                Supprimer
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>

                            <template v-if="tabs === 'lessons'">
                                <v-btn icon v-if="!lessons.length" @click="$emit('update:lessonAddDialog', true)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>

                                <v-menu offset-y bottom left v-if="!lessonIsSortable && lessons.length">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon fab small v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense class="py-0">
                                        <v-list-item v-if="!lessonIsSortable" @click="sortLessons">
                                            <v-list-item-title>Trier les leçons</v-list-item-title>
                                        </v-list-item>

                                        <v-divider></v-divider>

                                        <v-list-item @click="$emit('update:lessonAddDialog', true)">
                                            <v-list-item-title>Ajouter une leçon</v-list-item-title>
                                        </v-list-item>
                                    </v-list>

                                </v-menu>
                            </template>

                            <template v-if="lessonIsSortable">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon @click="cancelSortLessons">
                                            <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Annuler</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon @click="submitSortLessons">
                                            <v-icon small>mdi-check</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Enregistrer</span>
                                </v-tooltip>
                            </template>
                        </v-app-bar>

                        <v-tabs-items :value="tabs">
                            <v-tab-item value="summary">
                                <course-module-form
                                        class="creamy"
                                        v-if="currentCourseModule"
                                        submitBtn="Enregistrer"
                                        submitBtnClass="text-center"
                                        submitBtnColor="secondary lighten-1"
                                        :loading="loading"
                                        showContent
                                        :availableRelatedSkus="availableRelatedSkus"
                                        @submit="updateCourseModule"
                                        v-model="currentCourseModule"
                                        :tagStylesAvailable.sync="tagStylesAvailable"
                                        :tagLevelsAvailable.sync="tagLevelsAvailable"
                                        :instrumentsAvailable.sync="instrumentsAvailable">

                                </course-module-form>
                            </v-tab-item>
                            <v-tab-item value="lessons">
                                <v-list color="creamy"
                                        two-line
                                        class="py-0">
                                    <draggable v-model="lessons"
                                               class="dragArea"
                                               style="max-height: 1000px; overflow-y: scroll"
                                               :options="{sort: lessonIsSortable}">
                                        <div v-for="(lesson,index) in lessons" :key="index">
                                            <div :key="lesson.id">
                                                <v-divider></v-divider>

                                                <v-list-item :inactive="lessonIsSortable"
                                                             :class="lessonIsSortable ? 'grabable' : null">
                                                    <v-list-item-action v-if="lessonIsSortable">
                                                        <v-btn inactive icon fab small>
                                                            <v-icon>mdi-drag</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>

                                                    <v-list-item-content>
                                                        <v-list-item-title>{{lesson.title}}</v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-chip color="secondary" dark
                                                            v-if="!lesson.enabled">
                                                        Désactivé
                                                    </v-chip>

                                                    <v-list-item-action v-if="!lessonIsSortable">
                                                        <v-menu offset-y bottom left>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn icon fab small v-on="on">
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list dense class="py-0">
<!--                                                                <v-list-item disabled @click="updateCourseModuleLesson(lesson)">-->
<!--                                                                    <v-list-item-title>-->
<!--                                                                        {{lesson.enabled ? 'Désactiver du module' :-->
<!--                                                                        'Activé'}}-->
<!--                                                                    </v-list-item-title>-->
<!--                                                                </v-list-item>-->

<!--                                                                <v-divider></v-divider>-->


                                                                <v-list-item
                                                                        :to="{name: routeNameShowLesson, params: {uuid: lesson.slug}}"
                                                                        target="_blank">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            Voir leçon
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>

                                                                <v-divider></v-divider>

                                                                <v-list-item @click="detachCourseModuleLesson(lesson)">
                                                                    <v-list-item-title class="primary--text">
                                                                        Supprimer du module
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </v-list-item-action>
                                                </v-list-item>

                                                <v-divider v-if="lessons.length - 1 === index"></v-divider>
                                            </div>

                                        </div>
                                    </draggable>
                                </v-list>

                                <v-card flat v-if="!lessons.length">
                                    <v-card-text class="text-center">
                                        Aucune leçon
                                    </v-card-text>
                                </v-card>

                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>

                    <!--<div class="card-inline">-->
                    <!--</div>-->
                </v-col>
            </v-row>

            <v-row class="px-1 fill-height" v-if="!courseModules.length">
                <v-col cols="12">
                    <v-card flat style="height: 100%; max-height: 500px; overflow-y: scroll">
                        <v-card-text class="text-center">
                            <v-btn color="secondary"
                                   @click="$emit('update:dialogAdd', true)">Ajouter un module
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog :value="dialogAdd" @input="$emit('update:dialogAdd', $event)" max-width="900px">


            <course-module-form
                    ref="courseModuleFormAdd"
                    dark
                    isDialog
                    :dialog="dialogAdd"
                    @update:dialog="$emit('update:dialogAdd', $event)"
                    toolbarTitle="Ajouter un module"
                    showToolbar="secondary"

                    :loading="addLoading"
                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    submitBtnColor="secondary lighten-1"
                    @submit="addCourseModule"
                    v-model="newCourseModule"

                    showRelatedProducts

                    :products="products"
                    :lessonSelectedIds="lessonSelectedIds"
                    @update:lessonSelectedIds="$emit('update:lessonSelectedIds', $event)"
                    :availableLessons="availableLessons"
                    :lessonSearch="lessonSearch"
                    @update:lessonSearch="$emit('update:lessonSearch', $event)"

                    :tagStylesAvailable.sync="tagStylesAvailable"
                    :tagLevelsAvailable.sync="tagLevelsAvailable"
                    :instrumentsAvailable.sync="instrumentsAvailable"
            >
            </course-module-form>
        </v-dialog>

        <mg-confirmation-modal :dialog="dialogDelete"
                               @update:dialog="$emit('update:dialogDelete', $event)"
                               @delete="deleteCourseModule"
        ></mg-confirmation-modal>
    </v-card>

</template>

<style lang="scss" scoped>
</style>

<script>
    import CourseLogic from 'mg-js-core/lib/logics/CourseLogic'
    import _ from 'lodash';
    import draggable from 'vuedraggable'
    import PageStore from "mg-js-core/lib/stores/PageStore";

    export default {
        name: 'course-module-list-form',

        components: {
            draggable,
        },

        props: {
            value: {
                type: Array,
                required: true,
            },

            products: {
                type: Array,
            },
            availableRelatedSkus: {
                type: Array,
            },
            availableLessons: {
                type: Array,
            },
            lessonSelectedIds: {
                type: Array,
            },
            lessonSearch: {
                type: String,
            },

            course: {
                type: Object,
                required: true,
            },

            routeNameShowLesson: {
                type: String,
            },

            tabs: {
                type: String,
                default: 'summary'
            },

            dialogAdd: Boolean,
            lessonAddDialog: Boolean,
            dialogDelete: Boolean,
            currentKeyModule: Number,

            loading: Boolean,
            addLoading: Boolean,
            deleteLoading: Boolean,
            courseModuleIsSortable: Boolean,
            lessonIsSortable: Boolean,

            tagLevelsAvailable: Array,
            tagStylesAvailable: Array,
            instrumentsAvailable: Array,

            // tablatures: {
            //     type: Array
            // },
            //
            // resources: {
            //     type: Array
            // }
        },
        data: () => ({
            PageStore,

            isDraggable: false,
            deleteUuid: null,
            // currentKeyModule: 0,
            currentKeyLesson: 0,

            /* course form */
            // courseDialog: false,
            lessonsRaw: null,
            courseModulesRaw: null,

            newCourseModule: CourseLogic.getEmptyObjectModule(),
            currentCourseModuleSku: CourseLogic.getEmptyObjectModule(),
            // courseIsLoading: false,
        }),

        computed: {
            courseModules: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            currentCourseModule: {
                get() {
                    return this.courseModules
                        ? Object.assign({}, this.courseModules[this.currentKeyModule])
                        : null
                },
                set(value) {
                    let courseModules = Object.assign([], this.courseModules);

                    courseModules[this.currentKeyModule] = Object.assign({}, this.courseModules[this.currentKeyModule], value);

                    this.courseModules = courseModules;
                }
            },

            lessons: {
                get() {
                    return this.currentCourseModule && this.currentCourseModule.lessons
                        ? this.currentCourseModule.lessons.data
                        : []
                },
                set(value) {
                    this.currentCourseModule.lessons.data = value
                    console.error(value)
                }
            },

            currentLesson() {
                return this.lessons.length
                    ? this.lessons[this.currentKeyLesson]
                    : null
            },

            mgAlertComponent() {
                return this.$refs.mgAlert
            },

            currentLessonParts() {
                return this.currentLesson
                    ? this.currentLesson.lessonParts.data
                    : null
            },
            // useful for parent component
            courseModuleFormAddComponent() {
                return this.$refs.courseModuleFormAdd
            },
        },

        mounted() {

        },

        watch: {
            course: {
                handler(value) {
                    if (value) {
                        this.newCourseModule = CourseLogic.getEmptyObjectModule({
                            course_id: value.id,
                            position: this.courseModules.length ? _.last(this.courseModules).position + 1 : 0
                        })
                    }
                },
                deep: true
            },
        },

        methods: {
            sortCourseModules() {
                this.$emit('update:courseModuleIsSortable', true)
                this.courseModulesRaw = Object.assign([], this.courseModules)
            },
            cancelSortCourseModules() {
                this.$emit('update:courseModuleIsSortable', false)
                this.courseModules = Object.assign([], this.courseModulesRaw)
            },

            submitSortCourseModules() {
                let newOrder = _.map(this.courseModules, (courseModule, index) => {
                    return {
                        id: courseModule.id,
                        position: index + 1
                    }
                })

                this.$emit('submitSortCourseModules', newOrder)
            },

            sortLessons() {
                this.$emit('update:lessonIsSortable', true)
                this.lessonsRaw = Object.assign([], this.lessons)
            },

            cancelSortLessons() {
                this.$emit('update:lessonIsSortable', false)
                this.lessons = Object.assign([], this.lessonsRaw)
            },

            submitSortLessons() {
                let newOrder = _.map(this.lessons, (lesson, index) => {
                    return {
                        id: lesson.id,
                        position: index + 1
                    }
                })

                this.$emit('submitSortLessons', this.currentCourseModule, newOrder)
            },

            showLesson(lesson) {
                this.$emit('showLesson', lesson)
            },
            currentLessons(lessons) {
                return _.sortBy(lessons, ['position'])
            },

            courseModuleMoved(value) {
                console.warn('courseModuleMoved')
                console.warn(value.moved)
                console.warn(value.moved.newIndex)

                this.$emit('update:currentKeyModule', value.moved.newIndex)
            },

            updateCourseModule(courseModule) {
                this.$emit('updateCourseModule', courseModule);
            },

            addCourseModule(courseModule) {
                this.$emit('addCourseModule', courseModule);
            },

            deleteCourseModule() {
                this.$emit('deleteCourseModule', this.currentCourseModule);
            },

            openDeleteDialog() {
                this.$emit('update:dialogDelete', true);
            },

            updateCourseModuleLesson(lesson) {
                this.$emit('updateCourseModuleLesson', this.currentCourseModule, {
                    lesson: {
                        id: lesson.id,
                        enabled: !lesson.enabled
                    }
                });
            },

            detachCourseModuleLesson(lesson) {
                this.$emit('detachCourseModuleLesson', this.currentCourseModule, {
                    lesson_delete: {
                        id: lesson.id,
                    }
                });
            },
        }
    };
</script>
