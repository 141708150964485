import LazyLoad from '@/services/LazyLoad'

export default [
    {
        path: '/login',
        name: 'login',
        // component: LoginView,
        component: LazyLoad.localView('auth/Login'),
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     // component: LoginView,
    //     component: LazyLoad.localView('auth/Register'),
    // },
    // {
    //     path: '/information',
    //     name: 'information',
    //     component: LazyLoad.localView('auth/Information'),
    // },
    {
        path: '/password/reset',
        name: 'reset-password',
        component: LazyLoad.localView('auth/ResetPassword'),
    },
    {
        path: '/password/update/:token',
        name: 'update-password',
        component: LazyLoad.localView('auth/UpdatePassword'),
    },
    {
        // not found handler
        name: 'AccessDenied',
        path: '/403',
        // component: DenyView,
        component: LazyLoad.localView('Deny'),
    },
    {
        // not found handler
        name: 'NotFound',
        path: '/404',
        // component: NotFoundView,
        component: LazyLoad.localView('NotFound'),
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        // component: LoginView,
        component: LazyLoad.localView('Maintenance'),
    },
    {
        // not found handler
        name: 'redirect',
        path: '*',
        redirect: { name: 'admin-dashboard' },
    }
]
