<template>
    <v-list two-line>
        <v-list-item v-for="courseModule in courseModules" :key="courseModule.id" class="clickable">
            <v-list-item-action>
                <v-checkbox v-model="courseModulesSelected"
                            color="secondary"
                            @change="$emit('change', $event)"
                            :ref="courseModule.id"
                            :value="courseModule.id"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content @click="selectCourseModule(courseModule)">
                <v-list-item-title>{{courseModule.title}}</v-list-item-title>

                <v-list-item-subtitle>
                    <template v-if="courseModule.lessons && courseModule.lessons.data">
                        {{courseModule.lessons.data.length}} leçon<span
                            v-if="courseModule.lessons.data.length > 1">s</span>
                    </template>
                    <template v-else>
                        0 leçon
                    </template>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>


<style scoped>
    .v-progress-linear {
        position: absolute;
        bottom: 0;
        border-radius: 0 !important;
    }
</style>

<script>
    import _ from 'lodash'

    export default {
        name: 'course-module-list',

        props: {
            value: Array,
            selected: Array
        },

        data: () => ({}),

        mounted() {
        },

        computed: {
            courseModules: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            courseModulesSelected: {
                get() {
                    return this.selected
                },
                set(value) {
                    this.$emit('update:selected', value)
                }
            }
        },

        watch: {},
        methods: {
            selectCourseModule(courseModule) {
                const index = _.findIndex(this.courseModulesSelected, selectedCourseModule => {
                    return selectedCourseModule === courseModule.id
                });

                if (index > -1) {
                    this.courseModulesSelected.splice(index, 1);

                } else {
                    this.courseModulesSelected.push(courseModule.id);
                }

                this.$emit('change', this.courseModulesSelected)
            }
        }
    }
</script>
