<template>
    <v-card :flat="flat">
        <v-app-bar flat dark dense color="secondary" class="title">
            <v-spacer></v-spacer>
            <v-menu offset-y bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn icon fab small v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list dense class="py-0">
                    <v-list-item @click="$emit('update:addSongPartDialog', true)">
                        <v-list-item-title>Ajouter une partie</v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-menu>
        </v-app-bar>

        <v-container fluid>
            <v-row v-if="songParts.length">
                <v-col md="4">
                    <v-card flat style="height: 100%; max-height: 500px; overflow-y: scroll">
                        <v-list two-line class="py-0">
                            <v-list-item
                                    @click="updateCurrentKey(key)"
                                    :input-value="currentKeyPart === key"
                                    v-for="(songPart,key) in songParts"
                                    :key="songPart.id"
                            >

                                <v-list-item-content>
                                    <v-list-item-title>{{ songPart.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>

                <v-col md="8">
                    <song-part-form-complete
                        flat
                        ref="updateForm"
                        @submit="updateSongPart"
                        @deleteLessonPart="deleteSongPart"
                        :loading="updateSongPartLoading"
                        :dialogDelete="deleteSongPartDialog"
                        @update:dialogDelete="$emit('update:deleteSongPartDialog', $event)"
                        v-model="currentSongPart"
                        :key="currentKeyPart"
                        :tagDifficulty="tagDifficulty"
                    ></song-part-form-complete>
                </v-col>
            </v-row>

            <v-row class="px-1 fill-height" v-if="!songParts.length">
                <v-col cols="12">
                    <v-card flat style="height: 100%; max-height: 500px; overflow-y: scroll">
                        <v-card-text class="text-center">
                            <v-btn color="secondary"
                                   @click="$emit('update:addSongPartDialog', true)">Ajouter une partie
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog :value="addSongPartDialog" @input="$emit('update:addSongPartDialog', $event)"
                  max-width="900px">

            <song-part-form
                    dark
                    isDialog
                    ref="addForm"
                    :dialog="addSongPartDialog"
                    @update:dialog="$emit('update:addSongPartDialog', $event)"
                    toolbarTitle="Ajouter une partie"
                    showToolbar="secondary"

                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    submitBtnColor="secondary lighten-1"
                    @submit="addSongPart"
                    :loading="addSongPartLoading"
                    v-model="newSongPart"
                    :tagDifficulty="tagDifficulty">
            </song-part-form>
        </v-dialog>
    </v-card>


</template>

<style lang="scss" scoped>
</style>

<script>
    import _ from 'lodash';
    import SongPartLogic from "mg-js-core/lib/logics/SongPartLogic";
    import PageStore from "mg-js-core/lib/stores/PageStore";

    export default {
        name: 'song-part-list-form',

        components: {
        },

        props: {
            value: {
                type: Array,
                required: true,
            },

            flat: Boolean,
            currentKeyPart: Number,

            addSongPartLoading: Boolean,
            addSongPartDialog: Boolean,

            updateSongPartLoading: Boolean,
            deleteSongPartDialog: Boolean,
            tagDifficulty: Array,
        },


        data: () => ({
            // currentKeyPart: 0,
            PageStore,

            newResource: {
                title: '',
                file: null,
            },


            newSongPart: SongPartLogic.getEmptyObject(),
            /* lesson form */
            // lessonDialog: false,
            // lessonIsLoading: false,
        }),

        computed: {
            songParts: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            currentSongPart() {
                return this.songParts
                    ? Object.assign({}, this.songParts[this.currentKeyPart])
                    : SongPartLogic.getEmptyObject()
            },
        },

        mounted() {
            if (this.songParts.length) {
                this.newSongPart = SongPartLogic.getEmptyObject();
            }
        },

        methods: {
            showSong(song) {
                this.$emit('showSong', song)
            },
            currentSongs(songs) {
                return _.sortBy(songs, ['position'])
            },

            addSongPart() {
                this.$emit('addSongPart', this.newSongPart);
            },

            updateSongPart() {
                this.$emit('updateSongPart', this.currentSongPart);
            },

            deleteSongPart() {
                this.$emit('deleteSongPart', this.currentSongPart);
            },

            addSongPartFormError(errors) {
                this.$refs.addForm.addFormErrors(errors)
            },

            addUpdateLessonPartFormError(errors) {
                this.$refs.updateForm.addFormErrors(errors)
            },

            updateCurrentKey(key) {
                this.$emit('updateCurrentKey', key);
            }
        }
    };
</script>
