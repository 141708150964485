<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar dense flat :dark="dark" color="secondary">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-card-text>

            <v-row  >
                <v-col cols="12">
                    <v-text-field
                            v-validate="'required'"
                            v-model="tablature.title"
                            :error-messages="errors.collect('title')"
                            label="Titre *"
                            data-vv-name="title"
                            required>
                    </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-select
                            v-model="tablature.file_type"
                            :items="fileTypesValues"
                            v-validate="'required'"
                            :error-messages="errors.collect('file_type')"
                            data-vv-name="file_type"
                            label="Type *"
                    ></v-select>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                            v-validate="'required'"
                            v-model="tablature.link"
                            :error-messages="errors.collect('link')"
                            label="Lien *"
                            data-vv-name="link"
                            required>
                    </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                            v-validate
                            v-model="tablature.enabled"
                            :error-messages="errors.collect('enabled')"
                            label="Activé"
                            data-vv-name="enabled"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.enabled.dirty = true"
                            color="secondary"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";

    export default {
        name: 'lesson-tablature-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            showToolbar: Boolean,
            toolbarTitle: String,

            dialog: Boolean,
            isDialog : Boolean,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            loading: Boolean,

            disabled: Boolean,
            dark: Boolean,

            value: Object,

        },

        data: () => ({
            fileTypesValues: [
                {text: 'Audio', value: 'audio'},
                {text: 'Fichier', value: 'file'},
                {text: 'Lien', value: 'link'},
                {text: 'Video', value: 'video'},
            ],
        }),

        mounted() {

        },

        watch: {
            // value: {
            //     handler(value) {
            //     },
            //     deep: true
            // },
        },

        computed: {
            tablature: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },
        },

        methods: {
            validateForm() {
                return this.$validator.validateAll();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                this.mgAlertComponent.closeAlert();

                this.validateForm().then(isValid => {
                    if (isValid) {
                        this.$emit('submit', this.tablature);
                    }
                })
            },
        }
    }
</script>
