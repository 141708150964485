<template>
    <v-app class="admin">
        <v-app-bar app
                   class="elevation-1 px-2"
                   v-if="PageStore.showToolbarApp"
                   dark
                   color="secondary">
            <v-app-bar-nav-icon
                    v-if="PageStore.showDrawer"
                    @click.stop="drawer = !drawer"></v-app-bar-nav-icon>


            <router-link :to="{name : 'admin-dashboard'}"
                         style="text-decoration: none"
                         :style="{color: $vuetify.theme.dark ? 'white' : 'white'}">

                <v-toolbar-title class="headline">
                    <span class="font-weight-bold">MyGuitare</span> Admin
                </v-toolbar-title>
            </router-link>
            <v-spacer></v-spacer>


            <!--<v-btn icon>-->
            <!--<v-icon>mdi-email-outline</v-icon>-->
            <!--</v-btn>-->

            <!--<v-menu offset-y left-->
            <!--:close-on-content-click="false">-->
            <!--&lt;!&ndash;<v-btn&ndash;&gt;-->
            <!--&lt;!&ndash;color="primary"&ndash;&gt;-->
            <!--&lt;!&ndash;dark>&ndash;&gt;-->
            <!--&lt;!&ndash;Dropdown&ndash;&gt;-->
            <!--&lt;!&ndash;</v-btn>&ndash;&gt;-->
            <!--<v-btn slot="activator"-->
            <!--icon>-->
            <!--<v-badge-->
            <!--overlap-->
            <!--color="orange">-->
            <!--<span slot="badge">5</span>-->
            <!--<v-icon>mdi-bell</v-icon>-->
            <!--</v-badge>-->
            <!--</v-btn>-->
            <!--<v-card>-->
            <!--<v-list two-line>-->
            <!--<template v-for="(notification, index) in notifications">-->
            <!--<v-list-item-->
            <!--:key="notification.subtitle"-->
            <!--ripple-->
            <!--@click="toggle(index)">-->
            <!--<v-list-item-content>-->
            <!--<v-list-item-title>{{ notification.title }}</v-list-item-title>-->
            <!--<v-list-item-sub-title>{{ notification.subtitle }}</v-list-item-sub-title>-->
            <!--</v-list-item-content>-->

            <!--<v-list-item-action>-->
            <!--<v-list-item-action-text>{{ notification.action }}</v-list-item-action-text>-->
            <!--<v-icon v-if="selected.indexOf(index) < 0"-->
            <!--color="gray">-->
            <!--mdi-circle-small-->
            <!--</v-icon>-->
            <!--<v-icon v-else-->
            <!--color="red">-->
            <!--mdi-circle-small-->
            <!--</v-icon>-->
            <!--</v-list-item-action>-->

            <!--</v-list-item>-->
            <!--<v-divider-->
            <!--v-if="index + 1 < items.length"-->
            <!--:key="index"-->
            <!--&gt;</v-divider>-->
            <!--</template>-->
            <!--</v-list>-->
            <!--</v-card>-->
            <!--</v-menu>-->

            <v-menu offset-y left :close-on-content-click="false" min-width="300">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on"
                           fab
                           small
                           icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list class="mt-2 py-0">
                    <v-list-item
                            v-if="$authLogic.hasRole('member')"
                            :href="frontUrl">
<!--                            :to="{name : 'member-dashboard'}">-->
                        <v-list-item-content>
                            <v-list-item-title>Espace membre</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon>mdi-call-made</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider></v-divider>

<!--                    <v-list-item link @click="$vuetify.theme.dark = !$vuetify.theme.dark">-->
<!--                        <v-list-item-content>-->
<!--                            Thème sombre-->
<!--                        </v-list-item-content>-->
<!--                        <v-list-item-icon>-->
<!--                            <v-switch-->
<!--                                    :value="$vuetify.theme.dark"-->
<!--                                    hide-details-->
<!--                                    color="white"-->
<!--                                    disabled-->
<!--                                    @change="switchTheme"-->
<!--                                    class="mt-0 pt-0"-->
<!--                            ></v-switch>-->
<!--                        </v-list-item-icon>-->
<!--                    </v-list-item>-->
<!--                    -->
<!--                    <v-divider></v-divider>-->

                    <v-list-group no-action class="px-0" id="dropdown-radio">
                        <template v-slot:activator>
                            <v-list-item-content class="">
                                Thème
                            </v-list-item-content>
                        </template>
                        <v-radio-group v-model="darkModeConf" hide-details >
                            <v-list-item class="px-4" @click="darkModeConf = 1">
                                <v-list-item-action>
                                    <v-radio :value="1"></v-radio>
                                </v-list-item-action>

                                <v-list-item-content >
                                    <v-list-item-title>Sombre</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="px-4" @click="darkModeConf = 0">
                                <v-list-item-action>
                                    <v-radio :value="0"></v-radio>
                                </v-list-item-action>

                                <v-list-item-content>
                                    <v-list-item-title>Clair</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="px-4" @click="darkModeConf = 2">
                                <v-list-item-action>
                                    <v-radio :value="2"></v-radio>
                                </v-list-item-action>

                                <v-list-item-content>
                                    <v-list-item-title>Thème de l'appareil</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-radio-group>
                    </v-list-group>

                    <v-divider></v-divider>

                    <v-list-item @click="logout">
                        <v-list-item-content>
                            <v-list-item-title>Déconnexion</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon>mdi-power</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-navigation-drawer
                v-if="PageStore.showDrawer"
                v-model="drawer"
                :mini-variant="miniVariant"
                class="elevation-3 secondary"
                id="dirty-drawer-color"
                width="300"
                app>
            <v-list class="py-2 pl-1 transparent">
                <v-list-item :to="{name : 'admin-profile'}">
                    <v-avatar
                            size="40"
                            class="mr-4"
                            :color="user.avatar && user.avatar.data.url ? null : 'primary'">
                        <img v-if="user.avatar" v-media-src="user.avatar.data">
                        <div v-else-if="!user.avatar" class="body-2">
                            {{user && user.username ? user.username.substr(0, 2).toUpperCase() : ''}}
                        </div>
                    </v-avatar>

                    <v-list-item-content>
                        <v-list-item-title class="body-2">{{ user.first_name}} {{user.last_name}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list class="pt-0 transparent" dense>
                <v-divider></v-divider>

                <template v-for="item in filteredItems">
                    <v-list-item v-if="item.url" :key="item.title">{{ item.name }}
                        <v-list-item-icon>
                            <v-icon>{{item.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> <a style="color: white; text-decoration: none;" target="_blank" :href="item.url">{{ item.title }}</a></v-list-item-title>
                    </v-list-item>
                    <v-list-group
                            v-else-if="item.subItems"
                            :key="item.title"
                            :group="item.group"
                            :prepend-icon="item.icon"
                            no-action>
                        <template v-slot:activator>
                            <v-list-item-title> {{ item.title }}</v-list-item-title>
                        </template>
                        <v-list-item
                                v-for="subItem in filteredSubItems(item.subItems)"
                                :key="subItem.title"
                                :to="subItem.to">
                            <v-list-item-content>
                                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-group>

                    <v-list-item v-else :to="item.to" :key="item.title">
                        <v-list-item-icon>
                            <v-icon>{{item.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> {{ item.title }}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <page-header></page-header>

            <v-container
                    fluid
                    :class="PageStore.containerClass">
                <router-view/>
            </v-container>
        </v-main>
    </v-app>
</template>


<style lang="scss">
    .large-header-item {
        .v-list-group__header {
            padding-top: 16px !important;
            padding-bottom: 16px !important;
        }
    }

    .member .v-navigation-drawer--mini-variant .v-list-item > *:first-child {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    #dirty-drawer-color {
        .v-list-item--active {
            color: #0175E2 !important
        }

        .v-list-item {
            &:not(.v-list-item--active):not(.v-btn__content):not(.v-list-item--disabled) {
                color: #ffffff !important;

                .v-icon {
                    color: #ffffff !important;
                }
            }

            &.v-list-item--disabled {
                /*color: darken(#ffffff, 30%) !important;*/
                color: rgba(255, 255, 255, 0.40) !important;

                .v-icon {
                    color: rgba(255, 255, 255, 0.40) !important;
                }
            }
        }
    }

    #dropdown-radio {
        .v-input--radio-group--column{
            margin: 0;
            padding-bottom: 12px;
        }
    }
</style>

<script>
    import AuthLogic from 'mg-js-core/lib/logics/AuthLogic'
    import PageHeader from '../components/PageHeader';
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import {EventBus} from 'mg-js-core/lib/services/Request'
    import Storage from "mg-js-core/lib/services/Storage";

    const mediaQueryListObject = window.matchMedia('(prefers-color-scheme: dark)');

    export default {
        name: 'admin-layout',
        components: {
            PageHeader,
        },

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            // title: 'Cours de guitare en ligne pour apprendre avec plaisir',
            // all titles will be injected into this template
            title: 'admin',
            titleTemplate: 'MyGuitare | %s'
        },

        data: () => ({
            PageStore,
            user: AuthLogic.me(),
            drawer: null,
            miniVariant: false,
            right: true,
            rightDrawer: false,
            title: 'admin',

            darkModeConf : 0,

            frontUrl : process.env.VUE_APP_FRONT_HOST,
            items: [
                {
                    icon: 'mdi-home',
                    title: 'Tableau de bord',
                    to: {
                        name: 'admin-dashboard'
                    },
                },
                {
                    icon: 'mdi-account-multiple',
                    title: 'Gestion des utilisateurs',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'teacher', 'support', 'marketing']),
                    to: {
                        name: 'admin-user-list'
                    }
                },
                {
                    icon: 'mdi-account-off',
                    title: 'Demandes d\'inscription échouées',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'support']),
                    to: {
                        name: 'admin-failed-registrations'
                    }
                },
                {
                    icon: 'mdi-align-vertical-bottom',
                    title: 'Analytics des inputs',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'statistic-advanced']),
                    group: 'analytics',
                    to: {
                        name: 'input-stats'
                    }
                },
                {
                    icon: 'mdi-lock-open',
                    title: 'Déblocage contenu en masse',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'support']),
                    to: {
                        name: 'mass-unlock'
                    }
                },
                {
                    icon: 'mdi-tune',
                    title: 'Gestion du contenu',
                    group: 'content',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'support', 'teacher']),
                    subItems: [
                        {
                            title: 'Cours',
                            to: {
                                name: 'admin-course-list'
                            }
                        },
                        {
                            title: 'Leçons',
                            to: {
                                name: 'admin-lesson-list'
                            }
                        },
                        {
                            title: 'Imports',
                            to: {
                                name: 'import-user-list'
                            }
                        },
                        {
                            title: 'Chansons',
                            to: {
                                name: 'admin-song-list'
                            }
                        },
                        {
                            title: 'Instruments',
                            to: {
                                name: 'instrument-list'
                            }
                        },
                        {
                            title: 'Quêtes',
                            to: {
                                name: 'admin-quest-list'
                            }
                        },
                    ]
                },
                {
                    icon: 'mdi-alpha-p-box',
                    title: 'Gestion des produits',
                    group: 'product',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'support']),
                    subItems: [
                        {
                            title: 'Produits',
                            to: {
                                name: 'admin-product-list'
                            }
                        },
                        {
                            title: 'Page répertoire (MG)',
                            to: `/products/${process.env.VUE_APP_REPERTORY_PRODUCT_ID_MG}`,
                        },
                        {
                            title: 'Page répertoire (MPP)',
                            to: `/products/${process.env.VUE_APP_REPERTORY_PRODUCT_ID_MPP}`,
                        },
                        {
                            title: 'Formule VIP Globale (MG)',
                            to: `/products/${process.env.VUE_APP_GLOBAL_VIP_PRODUCT_MG_ID}`,
                        },
                        {
                            title: 'Formule VIP Globale (MPP)',
                            to: `/products/${process.env.VUE_APP_GLOBAL_VIP_PRODUCT_MPP_ID}`,
                        },
                    ]
                },
                {
                    icon: 'mdi-star',
                    title: 'Clubs',
                    group: 'community',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'support']),
                    subItems: [
                        {
                            title: 'Club Privilèges - MyGuitare',
                            to: `/products/${process.env.VUE_APP_CLUB_PRIVILEGE_PRODUCT_MG_ID}`,
                        },
                        {
                            title: 'Club Répertoire - MyGuitare',
                            to: `/products/${process.env.VUE_APP_CLUB_REPERTORY_PRODUCT_MG_ID}`,
                        },
                        {
                            title: 'Club Complet - MyGuitare',
                            to: `/products/${process.env.VUE_APP_CLUB_COMPLETE_PRODUCT_MG_ID}`,
                        },
                        {
                            title: 'Club Privilèges - MyPianopop',
                            to: `/products/${process.env.VUE_APP_CLUB_PRIVILEGE_PRODUCT_MPP_ID}`,
                        },
                        {
                            title: 'Club Répertoire - MyPianopop',
                            to: `/products/${process.env.VUE_APP_CLUB_REPERTORY_PRODUCT_MPP_ID}`,
                        },
                        {
                            title: 'Club Complet - MyPianopop',
                            to: `/products/${process.env.VUE_APP_CLUB_COMPLETE_PRODUCT_MPP_ID}`,
                        },
                    ]
                },
                {
                    icon: 'mdi-earth',
                    title: 'Gestion communauté',
                    group: 'community',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'teacher', 'support', 'marketing']),
                    subItems: [
                        {
                            title: 'Commentaire leçon',
                            to: {
                                name: 'admin-community-comment-lesson-list'
                            }
                        },
                        {
                            title: 'Avis cours',
                            to: {
                                name: 'admin-community-comment-course-list'
                            }
                        },
                        // {
                        //     title: 'Évaluation leçon',
                        //     to: {
                        //         name: 'admin-lesson-list'
                        //     }
                        // },
                        {
                            title: 'Feedback VIP',
                            to: {
                                name: 'admin-community-feedback-vip-list'
                            }
                        },
                        {
                            title: 'Video',
                            to: {
                                name: 'admin-community-video-list'
                            }
                        },
                    ]
                },
                {
                    icon: 'mdi-tune',
                    title: 'Gestion automatisations',
                    group: 'automation',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing']),
                    subItems: [
                        {
                            title: 'Active Campaign',
                            to: {
                                name: 'tag-list'
                            }
                        },
                    ]
                },
                {
                    icon: 'mdi-bell',
                    title: 'Notifications',
                    group: 'notification',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin']),
                    subItems: [
                        {
                            title: 'Envoi groupé',
                            to: {
                                name: 'notification-send'
                            }
                        },
                    ]
                },
                {
                    icon: 'mdi-account-plus',
                    title: 'Parrainage',
                    group: 'referral',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'support', 'marketing']),
                    subItems: [
                        {
                            title: 'Cadeaux',
                            to: {
                                name: 'referral-gift-list'
                            }
                        },
                    ]
                },
                {
                    icon: 'mdi-cart',
                    title: 'Paniers abandonnés',
                    group: 'abandoned-cart',
                    to: {
                        name: 'abandoned-cart-list'
                    },
                    // condition: 'isSuperAdmin',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing'])
                },
                {
                    icon: 'mdi-cash',
                    title: 'Reporting',
                    group: 'reporting',
                    // condition: 'isSuperAdmin',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'statistic', 'statistic-advanced']),
                    subItems: [
                        {
                            title: 'Activités',
                            to: {
                                name: 'activity-stats-list',
                            }
                        },
                        {
                            title: 'Utilisateurs',
                            to: {
                                name: 'user-stats-list',
                            }
                        },
                        {
                            title: 'Progression',
                            to: {
                                name: 'user-module-stats-list',
                            }
                        },
                        // {
                        //     title: 'Ventes - old',
                        //     hidden: !AuthLogic.hasRole(['super-admin', 'statistic-advanced']),
                        //     to: {
                        //         name: 'order-list-old'
                        //     }
                        // },
                        {
                            title: 'Ventes',
                            hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'statistic-advanced']),
                            to: {
                                name: 'order-stats-list',
                            }
                        },
                        {
                            title: 'Feedbacks',
                            hidden: !AuthLogic.hasRole(['super-admin', 'admin']),
                            to: {
                                name: 'admin-reporting-feedback'
                            }
                        },
                    ]
                },
                {
                    icon: 'mdi-cog',
                    title: 'Paramètres',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin']),
                    group: 'settings',
                    to: {
                        name: 'admin-setting-edit',
                    },
                },
                {
                    icon: 'mdi-forum',
                    title: 'Flarum',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'support', 'marketing']),
                    group: 'forum',
                    to: {
                        name: 'forum'
                    }
                },
                {
                    icon: 'mdi-chart-line',
                    title: 'Nova',
                    group: 'nova',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin', 'marketing']),
                    url: process.env.VUE_APP_API_HOST.slice(0, -3) + 'nova-admin-3iGrML',
                },
                {
                    icon: 'mdi-eye',
                    title: 'Telescope',
                    group: 'nova',
                    hidden: !AuthLogic.hasRole(['super-admin', 'admin']),
                    url: process.env.VUE_APP_API_HOST.slice(0, -3) + 'telescope',
                }
            ],
            admins: [
                ['Management', 'people_outline'],
                ['Settings', 'settings']
            ],
            cruds: [
                ['Create', 'add'],
                ['Read', 'insert_drive_file'],
                ['Update', 'update'],
                ['Delete', 'delete']
            ],
            selected: [0, 1, 2, 3, 4],

            notifications: [
                {
                    action: '15 min',
                    title: 'Une nouvelle leçon est disponible',
                    subtitle: "Cours de Blues Débutant Gratuit"
                },
                {
                    action: '2 hr',
                    title: 'Vous avez obtenu un nouveau badge',
                    subtitle: "Solo dans la Peau"
                },
                {
                    action: '6 hr',
                    title: 'Vous avez obtenu un nouveau badge',
                    subtitle: 'Le Rythme dans la Peau'
                },
                {
                    action: '12 hr',
                    title: 'Une nouvelle leçon est disponible',
                    subtitle: 'Pack Arpèges Niveau 1'
                },
                {
                    action: '18hr',
                    title: 'Une nouvelle leçon est disponible',
                    subtitle: 'SOLO - Niveau Intermédiaire 2'
                }
            ]
        }),



        watch : {
            darkModeConf(value){
                this.setDarkModeConf(value)
            },
        },
        mounted() {
            this.user = AuthLogic.me()

            EventBus.$on('refresh-user', () => {
                AuthLogic.loadMe().then(() => {
                    this.user = AuthLogic.me()
                })
            });

            EventBus.$on('logout', () => {
                this.setDarkModeConf(0)
            });

            if(Storage.get('dark')){
                setTimeout(() => {
                    this.darkModeConf = Number(Storage.get('dark'))
                }, 500)
            }
        },

        methods: {
            logout() {
                AuthLogic.logout()
                    .then(() => this.$router.push({name: 'login'}))
                    .catch(() => this.$router.push({name: 'login'}))
            },

            toggle(index) {
                const i = this.selected.indexOf(index)

                if (i > -1) {
                    this.selected.splice(i, 1)
                } else {
                    this.selected.push(index)
                }
            },

            switchTheme(value) {
                this.$vuetify.theme.dark = value;
            },

            prefersDarkScheme() {
                this.$vuetify.theme.dark = mediaQueryListObject.matches;
            },

            setDarkModeConf(value){
                if(value !== 2){
                    mediaQueryListObject.removeEventListener('change', this.prefersDarkScheme);
                }

                if(value === 0){
                    this.$vuetify.theme.dark = false
                } else if (value === 1){
                    this.$vuetify.theme.dark = true
                } else if (value === 2){
                    this.prefersDarkScheme();

                    mediaQueryListObject.addEventListener('change', this.prefersDarkScheme);
                }else{
                    this.$vuetify.theme.dark = false
                }

                Storage.set('dark', value);
            },

            filteredSubItems(subItems) {
                return subItems.filter(item => {
                    return item.hidden !== true;
                });
            },
        },

        computed: {
            filteredItems() {
                return this.items.filter(item => {
                    return item.hidden !== true;
                });
            },

        }
    }
</script>
