import Vue from 'vue'

import CommentTable from "@/components/comment/CommentTable";
import CommentLessonDialog from "@/components/comment/CommentLessonDialog";

import CourseTable from "@/components/course/CourseTable";
import CourseForm from "@/components/course/CourseForm";
import CourseDripContentForm from "@/components/course/dripContent/CourseDripContentForm";
import CourseDripContentList from "@/components/course/dripContent/CourseDripContentList";
import CourseDripContentStepper from "@/components/course/dripContent/CourseDripContentStepper";
import CourseImportTable from "@/components/course/dripContent/CourseImportTable";
import ProductDripContentStepper from "@/components/course/dripContent/ProductDripContentStepper";
import UserCourseModuleListForm from "@/components/course/dripContent/UserCourseModuleListForm";
import CourseListExpansion from "@/components/course/CourseListExpansion";
import CourseModuleForm from "@/components/course/CourseModuleForm";
import CourseModuleList from "@/components/course/CourseModuleList";
import CourseModuleListForm from "@/components/course/CourseModuleListForm";
import CourseModuleSkuForm from "@/components/course/CourseModuleSkuForm";
import UserProfileForm from "@/components/user/UserProfileForm";
import UserAddressForm from "@/components/user/UserAddressForm";
import UserTable from "@/components/user/UserTable";
import InputStatsTable from "@/components/stats/InputStatsTable";


import SubscriptionList from "@/components/subscription/SubscriptionList";

import LessonTable from "@/components/lesson/LessonTable";
import LessonForm from "@/components/lesson/LessonForm";
import LessonFormComplete from "@/components/lesson/LessonFormComplete";
import LessonListExpansion from "@/components/lesson/LessonListExpansion";
import LessonListExpansionLight from "@/components/lesson/LessonListExpansionLight";
import LessonPartForm from "@/components/lesson/LessonPartForm";
import LessonPartFormComplete from "@/components/lesson/LessonPartFormComplete";
import LessonPartListForm from "@/components/lesson/LessonPartListForm";
import LessonRessourceForm from "@/components/lesson/LessonRessourceForm";
import LessonTablatureForm from "@/components/lesson/LessonTablatureForm";
import LessonTablatureList from "@/components/lesson/LessonTablatureList";
import LessonResourceList from "@/components/lesson/LessonResourceList";

import ProductForm from "@/components/product/ProductForm";
import ProductFormStepper from "@/components/product/ProductFormStepper";
import ProductTable from "@/components/product/ProductTable";
import ProductTypeListForm from "@/components/product/ProductTypeListForm";
import ProductTypeForm from "@/components/product/ProductTypeForm";
import PlanForm from "@/components/product/PlanForm";
import PlanTable from "@/components/product/PlanTable";
import PlanList from "@/components/product/PlanList";
import PlanCreateStepper from "@/components/product/PlanCreateStepper";
import QuestForm from "@/components/quest/QuestForm";
import QuestTable from "@/components/quest/QuestTable";
import OrderTable from "@/components/product/OrderTable";
import SkuForm from "@/components/product/SkuForm";
import SkuList from "@/components/product/SkuList";
import SkuCreateStepper from "@/components/product/SkuCreateStepper";
import OrderList from "@/components/product/OrderList";
import CreditCardList from "@/components/CreditCard/CreditCardList";
import SongTable from "@/components/song/SongTable";
import SongForm from "@/components/song/SongForm";
import SongFormComplete from "@/components/song/SongFormComplete";
import SongPartForm from '@/components/song/SongPartForm';
import SongPartListForm from '@/components/song/SongPartListForm';
import SongPartFormComplete from '@/components/song/SongPartFormComplete';


Vue.component('comment-table', CommentTable);
Vue.component('comment-lesson-dialog', CommentLessonDialog);

Vue.component('course-table', CourseTable);
Vue.component('course-form', CourseForm);

Vue.component('course-drip-content-list', CourseDripContentList);
Vue.component('course-drip-content-form', CourseDripContentForm);
Vue.component('course-drip-content-stepper', CourseDripContentStepper);
Vue.component('course-drip-content-import-v1', CourseImportTable);
Vue.component('product-drip-content-stepper', ProductDripContentStepper);
Vue.component('user-course-module-list-form', UserCourseModuleListForm);
Vue.component('course-list-expansion', CourseListExpansion);
Vue.component('course-module-form', CourseModuleForm);
Vue.component('course-module-list', CourseModuleList);
Vue.component('course-module-list-form', CourseModuleListForm);
Vue.component('course-module-sku-form', CourseModuleSkuForm);

Vue.component('user-profile-form', UserProfileForm);
Vue.component('user-address-form', UserAddressForm);
Vue.component('user-table', UserTable);
Vue.component('input-stats-latest-table', InputStatsTable);

Vue.component('subscription-list', SubscriptionList);

Vue.component('lesson-table', LessonTable);
Vue.component('lesson-form', LessonForm);
Vue.component('lesson-form-complete', LessonFormComplete);
Vue.component('lesson-list-expansion', LessonListExpansion);
Vue.component('lesson-list-expansion-light', LessonListExpansionLight);
Vue.component('lesson-part-form', LessonPartForm);
Vue.component('lesson-part-form-complete', LessonPartFormComplete);
Vue.component('lesson-part-list-form', LessonPartListForm);
Vue.component('lesson-resource-form', LessonRessourceForm);
Vue.component('lesson-tablature-form', LessonTablatureForm);

Vue.component('lesson-tablature-list', LessonTablatureList);
Vue.component('lesson-resource-list', LessonResourceList);

Vue.component('product-form', ProductForm);
Vue.component('product-form-stepper', ProductFormStepper);
Vue.component('product-table', ProductTable);
Vue.component('product-type-list-form', ProductTypeListForm);
Vue.component('product-type-form', ProductTypeForm);
Vue.component('plan-form', PlanForm);
Vue.component('plan-table', PlanTable);
Vue.component('plan-list', PlanList);
Vue.component('plan-create-stepper', PlanCreateStepper);
Vue.component('quest-form', QuestForm);
Vue.component('quest-table', QuestTable);
Vue.component('order-table', OrderTable);
Vue.component('sku-form', SkuForm);
Vue.component('sku-list', SkuList);
Vue.component('sku-create-stepper', SkuCreateStepper);
Vue.component('order-list', OrderList);

Vue.component('credit-card-list', CreditCardList);

Vue.component('song-table', SongTable);
Vue.component('song-form', SongForm);
Vue.component('song-form-complete', SongFormComplete);
Vue.component('song-part-form', SongPartForm);
Vue.component('song-part-form-complete', SongPartFormComplete);
Vue.component('song-part-list-form', SongPartListForm);