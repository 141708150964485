<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar dense flat :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-container class="container--fluid" grid-list-lg >
            <v-row  >
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="songPart.title"
                            :error-messages="errors.collect('title')"
                            label="Titre *"
                            name="title"
                            data-vv-title="title"
                            required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-select
                            v-model="songPart.difficulty"
                            :items="tagDifficulty"
                            item-text="title"
                            item-value="id"
                            v-validate
                            :error-messages="errors.collect('difficulty')"
                            data-vv-name="difficulty"
                            label="Difficulté"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-autocomplete
                            :items="availableLessons"
                            v-model="songPart.lessons"
                            :loading="lessonLoading"
                            item-text="title"
                            item-value="id"
                            chips
                            cache-items
                            menu-props="bottom offset-y"
                            :search-input.sync="lessonSearch"
                            :error-messages="errors.collect('lesson_add')"
                            data-vv-name="lesson_add"
                            label="Leçons associées *"
                            multiple
                            required
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                    :input-value="data.selected"
                                    class="chip--select-multi"
                            >
                                {{ data.item.title }} - {{ data.item.method_id }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.title }} - {{ data.item.method_id }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-container>
        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import LessonLogic from 'mg-js-core/lib/logics/LessonLogic';
    import _ from 'lodash';

    export default {
        name: 'song-part-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            prices: Array,

            dialog: Boolean,
            isDialog: Boolean,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            loading: Boolean,

            disabled: Boolean,
            dark: Boolean,

            value: Object,

            showToolbar: {
                type: String,
                default: null
            },
            tagDifficulty: Array,
        },

        data: () => ({
            pagination: Helpers.initPagination(20, 1, 'title', true),
            lessonSearch: null,
            lessonLoading: false,
            availableLessons: [],
        }),

        mounted() {
            if (this.value) {
                this.syncLessonPart(this.value)
                if (this.value.currentLessonsObject){
                    this.availableLessons = this.value.currentLessonsObject;
                }
            }
        },

        watch: {
            value: {
                handler(value) {
                    this.syncLessonPart(value)
                },
                deep: true
            },
            lessonSearch(val) {
                // Items have already been requested
                if (this.lessonLoading) return
                this.lessonLoading = true;
                this.getLessons(val);
            },
        },
        computed: {
            songPart: {
                get() {
                    return this.value;
                },
                set(value) {
                    console.error(value)
                    this.$emit('input', value)
                },
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },
        },

        methods: {
            syncLessonPart() {
            },

            validateLessonPartForm() {
                return this.$validator.validateAll();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                this.mgAlertComponent.closeAlert();

                this.validateLessonPartForm().then(isValid => {
                    if (isValid) {
                        this.$emit('submit');
                    }
                })
            },

            getLessons: _.debounce(function (search) {

                let filters = {
                    search,
                }

                LessonLogic
                    .getAll(this.pagination, filters)
                    .then(({data}) => {
                        this.availableLessons = data;
                        this.lessonLoading = false;
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }, 300),
        }
    }
</script>
