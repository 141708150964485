<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-stepper v-model="stepperIndex" vertical class="transparent pb-2">
            <v-stepper-step :complete="stepperIndex > 1" :step="1" editable>
                Produit par abonnement
            </v-stepper-step>

            <v-stepper-content :step="1">
                <v-col cols="12">
                    <v-autocomplete
                            :items="availableSubscriptionProducts"
                            :value="selectedSubscriptionProduct"
                            @input="selectSubscriptionProduct"
                            item-text="title"
                            item-value="id"
                            returnObject
                            cache-items
                            :search-input="searchSubscriptionProduct"
                            @update:search-input="$emit('update:searchSubscriptionProduct', $event)"
                            :error-messages="errors.collect('subscription-product')"
                            data-vv-name="subscription-product"
                            v-validate="'required'"
                            label="Produit par abonnement *"
                    >
                        <template v-slot:selection="data">
                            <v-chip class="caption">
                                {{ data.item.title }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-btn color="secondary" @click="stepperIndex++" :disabled="!selectedSubscriptionProduct.id">Continuer</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepperIndex > 2" :step="2" :editable="(selectedSubscriptionProduct.id ? true : false)">
                Plan
            </v-stepper-step>

            <v-stepper-content :step="2">
                <v-col cols="12">
                    <v-autocomplete
                            :items="availablePlans"
                            :value="selectedPlan"
                            @input="selectPlan"
                            item-text="title"
                            item-value="id"
                            returnObject
                            :search-input="searchPlan"
                            @update:search-input="$emit('update:searchPlan', $event)"
                            :error-messages="errors.collect('plans')"
                            data-vv-name="plans"
                            v-validate="'required'"
                            label="Plan*"
                    >
                        <template v-slot:selection="data">
                            <v-chip class="caption">
                                {{ data.item.title }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-btn color="secondary" @click="stepperIndex++" :disabled="!selectedPlan.id">Continuer</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepperIndex > 3" :step="3" :editable="(selectedPlan.id ? true : false)">
                Configuration
            </v-stepper-step>

            <v-stepper-content :step="3" :editable="(selectedSubscriptionProduct.id && selectedPlan.id ? true : false)">
                <v-row>
                     <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Nombre de jours d'abonnement
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Permet de définir une durée d'abonnement lorsque la personne se verra débloquer le produit par abonnement.
                            </v-card-text>

                        <v-col cols="6" class="pa-0 ml-4">
                                <v-text-field
                                    v-model="ended_days"
                                    hide-details
                                    single-line
                                    placeholder="Nombre de jours (laisser vide si pas de fin)"
                                    type="number"
                                />
                        </v-col>
                            
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <div class="ml-6 mt-10 font-weight-bold" style="font-size: 20px;">OU</div>
                </v-row>
                <v-row>
                     <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Date de fin de l'abonnement
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Permet de définir une date de fin. À partir de cette date, l'abonnement prendra fin et le contenu associé sera désactivé pour l'utilisateur. <strong>À noter que ce paramètre prend le dessus sur "Nombre de jours d'abonnement"</strong>.
                            </v-card-text>

                            <!-- Champ pour la date de fin -->
                            <v-card-text>
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="ended_date"
                                            label="Date de fin"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :append-icon="ended_date ? 'mdi-close-circle' : ''"
                                            @click:append="clearDate"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="ended_date"
                                        v-validate="'required'"
                                        :min="new Date().toISOString().substr(0, 10)"
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Vip
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Autoriser l'utilisateur à accéder aux ressources VIP (Sera pris en compte uniquement si le Plan choisi dispose d'une option VIP).
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-checkbox
                            v-validate
                            v-model="vip"
                            :error-messages="errors.collect('vip')"
                            label="Oui"
                            data-vv-name="vip"
                            required
                            :true-value="true"
                            :false-value="false"
                            color="secondary"
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-btn color="secondary lighten-1 mt-6 mb-2"
                       :loading="loading"
                       @click="submit">Valider
                </v-btn>
            </v-stepper-content>
        </v-stepper>
    </v-card>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";

    export default {
        name: 'plan-drip-content-stepper',

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            dark: Boolean,
            showToolbar: String,
            toolbarTitle: String,
            dialog: Boolean,
            isDialog: Boolean,

            loading: Boolean,
            step: Number,


            /* plans */
            availablePlans: Array,
            selectedPlan: Object,
            searchPlan: String,

            /* subscription products */
            availableSubscriptionProducts: Array,
            selectedSubscriptionProduct: Object,
            searchSubscriptionProduct: String,

        },

        data: () => ({
            rowActive: {},
            vip: false,
            ended_date: null,
            menu: false,
            ended_days: null,
        }),

        mounted() {

        },

        computed: {
            stepperIndex: {
                get() {
                    return this.step
                },
                set(step) {
                    this.$emit('update:step', step)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        watch: {
            dialog: {
                handler(value) {
                    if (!value) {
                        this.resetValidation()
                        this.ended_date = null;
                        this.vip = false;
                    }
                },
                deep: true
            },
        },

        methods: {
            selectSubscriptionProduct(product) {
                this.$emit('update:selectedSubscriptionProduct', product)
            },

            selectPlan(plan) {
                plan.enabled = true
                plan.dripping_enabled = true
                plan.visible = true

                this.$emit('update:selectedPlan', plan)
            },

            submit(body) {
                this.$emit('submit', {
                    ...body,
                    plan_id: this.selectedPlan.id,
                    ended_date: this.ended_date,
                    ended_days: (this.ended_days ? parseInt(this.ended_days) : null),
                    product_title: this.selectedSubscriptionProduct.title,
                    plan_title: this.selectedPlan.title,
                    vip: this.vip
                })

                this.ended_days = null;
            },
            resetValidation() {
                this.$validator.reset();
            },

            addFormErrors(errors) {
                this.MgAlertComponent.displayAlert();
                Helpers.addFormErrors(this.$validator, errors);
            },

            clearDate () {
                this.ended_date = null;
            },
        }
    }
</script>
