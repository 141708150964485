<template>
    <div>
        <v-expansion-panels
                id="lesson-list-expansion"
                :value="currentLessonKey"
                accordion
                mandatory
                @change="$emit('update:currentLessonKey', $event)">
            <v-expansion-panel
                    v-for="(userCourseModuleLesson,index) in lessons"
                    :key="userCourseModuleLesson.id"
                    :disabled="lessonIsLocked(userCourseModuleLesson)"
                    :id="`lesson-${getRelatedLesson(userCourseModuleLesson).slug}`"
            >
                <v-expansion-panel-header class="">
                    <v-list-item :key="index" :disabled="lessonIsLocked(userCourseModuleLesson)">
                        <v-list-item-avatar v-if="lessonIsLocked(userCourseModuleLesson)">
                            <v-icon>mdi-lock-outline</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-1 font-weight-bold">
                                {{getRelatedLesson(userCourseModuleLesson).title }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                                    disabled
                                    class="font-weight-bold"
                                    v-if="userCourseModuleLesson.id === nextLesson.id">
                                {{getUnlockDiff}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                    <v-card tile color="creamy">
                        <v-list two-line
                                class="transparent py-0 lessons-list">
                            <v-list-item-group
                                    mandatory
                                    color="green"
                                    :value="currentLessonPartIndex"
                            >
                                <template v-for="(lessonPart,index) in getRelatedPart(userCourseModuleLesson)">
                                    <v-list-item
                                            v-if="lessonPart.id"
                                            class="lesson-parts"
                                            :key="lessonPart.id"
                                            @click="$emit('update:currentLessonPartIndex', index)"
                                    >
                                        <v-list-item-content class="ml-10">
                                            <v-list-item-title class="body-2">
                                                {{ lessonPart.title }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider
                                            v-if="index !== getRelatedPart(userCourseModuleLesson).data - 1"
                                            :key="index">
                                    </v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import CourseDripContentModel from "mg-js-core/lib/models/CourseDripContentModel";

    export default {
        name: 'lesson-list-expansion',

        props: {
            course: {
                type: Object,
                default: () => (new CourseDripContentModel())
            },
            lessons: Array,

            currentLessonParts: Array,
            currentUserCourseModule: Object,

            currentLessonKey: Number,
            currentLessonPartIndex: Number,
        },

        data: () => ({
            PageStore,
        }),

        mounted() {
        },

        watch: {
            currentLessonKey(value) {
                if (typeof value !== 'number') {
                    this.$emit('update:currentLessonPartIndex', null)
                }
            },

            currentLessonParts(value) {
                this.$emit('update:currentLessonParts', value)
            }
        },

        computed: {
            nextLesson() {
                return this.course.nextLesson;
            },

            currentLesson() {
                return this.course.currentLesson;
            },

            currentCourseModule() {
                return this.course.currentCourseModule;
            },

            nextUnlockableOn() {
                return this.currentLesson && this.currentLesson.next_unlockable_on
                    ? this.$moment(this.currentLesson.next_unlockable_on)
                    : null;
            },

            unlockDuration() {
                const nextUnlockableOn = this.nextUnlockableOn

                if (nextUnlockableOn) {
                    return this.$moment.duration(nextUnlockableOn.diff(this.$moment()))
                } else {
                    return null
                }
            },

            getUnlockDiff() {
                if (this.course.dripping_enabled) {
                    const unlockDuration = this.unlockDuration

                    if (unlockDuration) {
                        return `Disponible ${unlockDuration.humanize(true)}`
                    } else {
                        return `Disponible ${this.getRelatedLesson(this.currentLesson).duration} jours après le début de la leçon précédente`
                    }
                } else {
                    return `Le déblocage du contenu est désactivé`
                }
            },
        },

        methods: {
            getRelatedPart(userCourseModuleLesson) {
                let lesson = this.getRelatedLesson(userCourseModuleLesson);

                return lesson.lessonParts && lesson.lessonParts.data
                    ? lesson.lessonParts.data
                    : []
            },

            getRelatedLesson(courseModuleDripContentLesson) {
                return courseModuleDripContentLesson.lesson && courseModuleDripContentLesson.lesson.data
                    ? courseModuleDripContentLesson.lesson.data
                    : LessonLogic.getEmptyObject()
            },

            moduleIsLocked(courseModuleDripContent) {
                return courseModuleDripContent.position > this.currentCourseModule.position;
            },

            lessonIsLocked(userCourseModuleLesson) {
                if(this.moduleIsLocked(this.currentUserCourseModule)){
                    return true;
                }

                return (this.currentUserCourseModule.position === this.currentCourseModule.position
                    && userCourseModuleLesson.position > this.currentLesson.position);
            }
        }
    }
</script>
