<template>
    <v-row class="fill-height">
        <v-col cols="12" class="py-0">
            <v-tabs :value="tab" @change="$emit('update:tab', $event)">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab href="#summary">
                    Général
                </v-tab>
                <v-tab href="#parts">
                    Parties
                </v-tab>
                <v-tab href="#tablatures">
                    Tablatures
                </v-tab>
                <v-tab href="#resources">
                    Ressources
                </v-tab>
                <v-tab href="#comments">
                    Commentaires
                </v-tab>
            </v-tabs>
        </v-col>

        <v-col cols="12" class="pb-0" v-if="lesson.archived">
            <v-alert
                    tile
                    class="mb-0 text-center"
                    transition="scale-transition"
                    absolute
                    dark
                    color="warning">
                La lecon est archivée
            </v-alert>
        </v-col>

        <v-col cols="12">

            <v-tabs-items :value="tab" @change="$emit('update:tab', $event)">
                <v-tab-item value="summary">
                    <lesson-form
                            dark
                            showToolbar
                            showOptionsMenu
                            colorToolbar="secondary"

                            flat
                            ref="lessonForm"
                            v-model="lesson"
                            submitBtn="Enregistrer"
                            submitBtnClass="text-center"
                            submitBtnColor="secondary"
                            @submit="updateLesson"
                            @archiveLesson="archiveLesson"

                            :loading.sync="summaryLoading"
                            :currentSongs="currentSongs">
                    </lesson-form>
                </v-tab-item>
                <v-tab-item value="parts">
                    <lesson-part-list-form
                            ref="lessonPartListForm"
                            :value="lessonParts"
                            @input="$emit('update:lessonParts', $event)"
                            flat
                            :addLessonPartLoading="addLessonPartLoading"
                            @update:addLessonPartLoading="$emit('update:addLessonPartLoading', $event)"

                            :currentKeyPart="currentKeyPart"
                            @update:currentKeyPart="$emit('update:currentKeyPart', $event)"

                            :addLessonPartDialog="addLessonPartDialog"
                            @update:addLessonPartDialog="$emit('update:addLessonPartDialog', $event)"

                            :deleteLessonPartDialog="deleteLessonPartDialog"
                            @update:deleteLessonPartDialog="$emit('update:deleteLessonPartDialog', $event)"
                            @deleteLessonPart="deleteLessonPart"

                            :lessonPartsIsSortable="lessonPartsIsSortable"
                            @update:lessonPartsIsSortable="$emit('update:lessonPartsIsSortable', $event)"
                            @submitSortLessonParts="submitSortLessonParts"

                            @addLessonPart="addLessonPart"

                            @updateLessonPart="updateLessonPart"
                            :updateLessonPartLoading="updateLessonPartLoading"
                    ></lesson-part-list-form>
                </v-tab-item>
                <v-tab-item value="tablatures">
                    <lesson-tablature-list
                            ref="lessonTablatureList"
                            :value="tablatures"
                            @input="$emit('update:tablatures', $event)"
                            flat
                            :dialog="dialogAddTablature"
                            @update:dialog="$emit('update:dialogAddTablature', $event)"

                            :dialogEdit="dialogEditTablature"
                            @update:dialogEdit="$emit('update:dialogEditTablature', $event)"

                            :dialogEditLoading="dialogEditTablatureLoading"
                            @update:dialogEditLoading="$emit('update:dialogEditTablatureLoading', $event)"

                            :dialogAddLoading="dialogAddTablatureLoading"
                            @update:dialogAddLoading="$emit('update:dialogAddTablatureLoading', $event)"

                            :dialogDeletingLoading="dialogDeletingTablature"
                            @update:dialogDeletingLoading="$emit('update:dialogDeletingTablature', $event)"

                            @addTablature="addTablature"
                            @editTablature="editTablature"

                            @deleteTablature="deleteTablature"

                            @submitSortTablatures="sortTablatures"
                            :tablatureIsSortable="tablatureIsSortable"
                            @update:tablatureIsSortable="$emit('update:tablatureIsSortable', $event)"

                    >
                    </lesson-tablature-list>

                </v-tab-item>
                <v-tab-item value="resources">
                    <lesson-resource-list
                            ref="lessonResourceList"
                            :value="resources"
                            @input="$emit('update:resources', $event)"

                            :dialog="dialogAddResource"
                            @update:dialog="$emit('update:dialogAddResource', $event)"

                            :dialogEdit="dialogEditResource"
                            @update:dialogEdit="$emit('update:dialogEditResource', $event)"

                            :dialogEditLoading="dialogEditResourceLoading"
                            @update:dialogEditLoading="$emit('update:dialogEditResourceLoading', $event)"

                            :dialogAddLoading="dialogAddResourceLoading"
                            @update:dialogAddLoading="$emit('update:dialogAddResourceLoading', $event)"

                            :dialogDeletingLoading="dialogDeletingResource"
                            @update:dialogDeletingLoading="$emit('update:dialogDeletingResource', $event)"

                            @addResource="addResource"
                            @editResource="editResource"
                            flat
                            @deleteResource="deleteResource"

                            @submitSortResources="sortResources"
                            :resourceIsSortable="resourceIsSortable"
                            @update:resourceIsSortable="$emit('update:resourceIsSortable', $event)"

                    >
                    </lesson-resource-list>
                </v-tab-item>
                <v-tab-item value="comments">
                    <mg-comment-list
                        :max-width="$vuetify.breakpoint.mdAndUp ? '75%' : '100%'"
                        @deleteComment="deleteComment"
                        @updateComment="updateComment"
                        :comments=comments>
                    </mg-comment-list>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
    import MgCommentList from "@/components/community/MgCommentList";

    export default {
        name: 'lesson-form-compete',

        components: {MgCommentList},

        props: {
            value: {
                type: Object,
                required: true,
            },
            tab: {
                type: String,
                required: true,
            },
            /* summary */
            summaryLoading: Boolean,
            /* parts */
            lessonParts: {
                type: Array,
                required: true,
            },
            currentKeyPart: Number,
            addLessonPartLoading: Boolean,
            addLessonPartDialog: Boolean,

            lessonPartsIsSortable: Boolean,
            deleteLessonPartDialog: Boolean,

            updateLessonPartLoading: Boolean,

            /* tablatures */
            tablatures: {
                type: Array,
                required: true,
            },
            dialogAddTablature: Boolean,
            dialogEditTablature: Boolean,

            dialogEditTablatureLoading: Boolean,
            dialogAddTablatureLoading: Boolean,

            dialogDeletingTablature: Boolean,
            tablatureIsSortable: Boolean,
            /* resources */
            resources: {
                type: Array,
                required: true,
            },

            dialogAddResource: Boolean,
            dialogEditResource: Boolean,

            dialogEditResourceLoading: Boolean,
            dialogAddResourceLoading: Boolean,

            dialogDeletingResource: Boolean,
            resourceIsSortable: Boolean,
            currentSongs: {
                type: Array,
                required: true,
            },
            /* comments */
            comments: {
                type: Array,
                required: true,
            },
        },
        data: () => ({}),
        mounted() {
        },

        computed: {
            lesson: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            lessonResourceListComponent() {
                return this.$refs.lessonResourceList
            },

            lessonResourceFormAdd() {
                return this
                    .lessonResourceListComponent
                    .lessonResourceFormAddComponent
            },

            lessonResourceFormEdit() {
                return this
                    .lessonResourceListComponent
                    .lessonResourceFormEditComponent
            }
        },
        watch: {},

        methods: {
            /* summary */
            updateLesson() {
                this.$emit('updateLesson')
                // this.$emit('submitLesson')
            },
            archiveLesson(lessonId) {
                this.$emit('archiveLesson', lessonId);
            },

            /* parts */
            addLessonPart(lessonPart) {
                this.$emit('addLessonPart', lessonPart)
            },

            updateLessonPart(lessonPart) {
                this.$emit('updateLessonPart', lessonPart)
            },

            deleteLessonPart(lessonPart) {
                this.$emit('deleteLessonPart', lessonPart)
            },

            submitSortLessonParts(newOrder) {
                this.$emit('submitSortLessonParts', newOrder)
            },

            addLessonPartFormError(errors) {
                this.$refs.lessonPartListForm.addLessonPartFormError(errors)
            },

            addUpdateLessonPartFormError(errors) {
                this.$refs.lessonPartListForm.addUpdateLessonPartFormError(errors)
            },

            /* resources */
            addResource(resource) {
                this.$emit('addResource', resource)
            },
            editResource(resource) {
                this.$emit('editResource', resource)
            },
            deleteResource(resource) {
                this.$emit('deleteResource', resource)
            },
            sortResources(resourcesOrder) {
                this.$emit('sortResources', resourcesOrder)
            },
            lessonResourceFormAddError(errors) {
                this.lessonResourceFormAdd.addFormErrors(errors)
            },

            lessonResourceFormEditError(errors) {
                this.lessonResourceFormEdit.addFormErrors(errors)
            },

            /* tablature */
            addTablature(tablature) {
                this.$emit('addTablature', tablature)
            },
            editTablature(tablature) {
                this.$emit('editTablature', tablature)
            },
            deleteTablature(tablature) {
                this.$emit('deleteTablature', tablature)
            },
            sortTablatures(tablaturesOrder) {
                this.$emit('sortTablatures', tablaturesOrder)
            },
            lessonTablatureFormAddError(errors) {
                this.lessonTablatureFormAdd.addFormErrors(errors)
            },

            lessonTablatureFormEditError(errors) {
                this.lessonTablatureFormEdit.addFormErrors(errors)
            },

            /*comments*/
            deleteComment(comment) {
                this.$emit('deleteComment', comment)
            },

            updateComment(comment, body) {
                this.$emit('updateComment', comment, body);
            }
        },
    }
</script>
