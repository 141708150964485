<template>
    <v-card>
        <v-app-bar flat dense color="secondary" dark v-if="showToolbar">
            <v-icon v-if="toolbarIcon">{{toolbarIcon}}</v-icon>
            <v-toolbar-title class="font-weight-light">{{pageTitle}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon
                   v-if="hasClickPlusIconEvent"
                   @click="$emit('click:add-icon')">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-app-bar>

        <template v-if="Object.keys(localFilters).length > 0">
            <v-row justify="center">
                <v-col cols="12" sm="6" md="7" v-if="localFilters.hasOwnProperty('search')">
                    <v-text-field
                            v-model="localFilters.search"
                            ref="search"
                            prepend-icon="mdi-magnify"
                            @click:prepend="$refs.search.focus()"
                            clearable
                            label="Titre"
                    ></v-text-field>
                </v-col>

                <v-col cols="6" sm="2" md="2" v-if="localFilters.hasOwnProperty('archived')">
                    <v-checkbox
                            v-model="localFilters.archived"
                            prepend-icon="mdi-trash"
                            :false-value="0"
                            :true-value="1"
                            label="Archivé"
                    ></v-checkbox>
                </v-col>

                <v-col cols="6" sm="2" md="3" v-if="localFilters.hasOwnProperty('onlyLessonsWithoutSongs')">
                    <v-checkbox
                            v-model="localFilters.onlyLessonsWithoutSongs"
                            :false-value="0"
                            :true-value="1"
                            label="Leçons non liées à une chanson"
                    ></v-checkbox>
                </v-col>
            </v-row>

            <v-divider></v-divider>

        </template>

        <v-data-table
                :options.sync="localPagination"
                :server-items-length="totalItems"
                :footer-props="{
                    'items-per-page-options' : [20, 50, 100, 150]
                }"
                :headers="headers"
                :loading="loading"
                :items="lessons">
            <template v-slot:item.title="{ item }">
                <router-link class="default--text"
                             :to="{name: editRouteName, params: {uuid: item.slug}}">
                    {{ item.title }}
                </router-link>
            </template>
            <template v-slot:item.enabled="{ item }">
                {{ item.enabled ? 'Oui' : 'Non'}}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-menu offset-y bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon fab small v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="openDialogDuplicate(item)">
                            <v-list-item-title>Dupliquer</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

    </v-card>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";

    export default {
        name: 'lesson-table',

        props: {
            lessons: Array,
            headers: Array,
            filters: {
                type: Object,
                default() {
                    return {}
                }
            },
            totalItems: Number,

            pagination: Object,
            loading: Boolean,
            pageTitle: String,
            toolbarIcon: String,

            showToolbar: {
                type: Boolean,
                default: false
            },

            editRouteName: {
                type: String,
                default: 'admin-lesson-edit'
            },
        },

        data: () => ({
            Helpers,
        }),

        mounted() {
        },

        computed: {
            localPagination: {
                get() {
                    return this.pagination;
                },

                set(value) {
                    this.$emit('update:pagination', value)
                }
            },
            localFilters: {
                get() {
                    return this.filters;
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },
            isLoading: {
                get() {
                    return this.loading;
                },

                set(value) {
                    this.$emit('update:loading', value)
                }
            },

            hasClickPlusIconEvent() {
                return !!(this.$listeners && this.$listeners['click:add-icon'])
            },
        },

        watch: {},
        methods: {
            getRandomInt(min, max) {
                // Will return a number inside the given range, inclusive of both minimum and maximum
                // i.e. if min=0, max=20, returns a number from 0-20
                return Math.floor(Math.random() * (max - min + 1)) + min;
            },

            openDialogDuplicate(lesson) {
                this.$emit('click:duplicate-icon', lesson);
            }
        }
    }
</script>
