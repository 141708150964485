<template>
    <v-row id="mg-breadcrumb" class="align-center px-4 pt-4" v-if="PageStore.breadcrumbs.length > 0 || PageStore.title.length > 0">
        <v-col cols="12" class="pb-1" v-if="PageStore.title.length > 0">
            <h1>{{PageStore.title}}</h1>
        </v-col>
        <v-col cols="12" v-if="PageStore.breadcrumbs.length > 0">
            <v-breadcrumbs class="px-0" large divider="/" :items="PageStore.breadcrumbs">
                <v-breadcrumbs-item slot="item"
                                    slot-scope="props"
                                    :to="props.item.to"
                                    exact
                                    :disabled="props.item.disabled">
                    {{ props.item.text }}
                </v-breadcrumbs-item>
            </v-breadcrumbs>
        </v-col>
    </v-row>
</template>

<style scoped>
    h3 {
        font-weight: 300;
        letter-spacing: normal;
        font-size: 24px;
    }

    .disabled {
        color: grey;
        pointer-events: none;
    }
</style>

<script>
    import PageStore from 'mg-js-core/lib/stores/PageStore'

    export default {
        data: () => ({
            PageStore
        }),
    };
</script>
