<template>
    <v-card>
        <v-app-bar flat dense color="secondary" dark v-if="showToolbar">
            <v-icon v-if="toolbarIcon">{{toolbarIcon}}</v-icon>
            <v-toolbar-title class="font-weight-light">{{pageTitle}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>

        <template v-if="Object.keys(localFilters).length > 0">
            <v-row justify="center" align="center">
                <v-col cols="12" sm="6" md="7" v-if="localFilters.hasOwnProperty('search')">
                    <v-text-field
                            v-model="localFilters.search"
                            ref="search"
                            prepend-icon="mdi-magnify"
                            @click:prepend="$refs.search.focus()"
                            clearable
                            label="Titre"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" v-if="localFilters.hasOwnProperty('platform_code')">
                    <mg-select-platform
                        v-model="localFilters.platform_code"
                        label="Plateforme"
                        data-vv-name="platform_code"
                        name="platform_code">
                    </mg-select-platform>
                </v-col>
                <v-col cols="1" sm="1" md="1" v-if="localFilters.hasOwnProperty('platform_code') && localFilters.platform_code !== ''">
                    <v-btn icon small fab @click="clearPlatformFilter">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider></v-divider>

        </template>

        <v-data-table
                :options.sync="localPagination"
                :server-items-length="totalItems"
                :footer-props="{
                    'items-per-page-options' : [20, 50, 100, 150]
                }"
                :headers="headers"
                :loading="loading"
                :items="quests">
            <template v-slot:item.title="{ item }">
                <router-link class="default--text"
                             :to="{name: editRouteName, params: {uuid: item.id}}">
                    {{ item.title }}
                </router-link>
            </template>
        </v-data-table>

    </v-card>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";
    import MgSelectPlatform from "../platform/MgPlatformSelect";

    export default {
        name: 'quest-table',

        components: {MgSelectPlatform},

        props: {
            quests: Array,
            headers: Array,
            filters: {
                type: Object,
                default() {
                    return {}
                }
            },
            totalItems: Number,

            pagination: Object,
            loading: Boolean,
            pageTitle: String,
            toolbarIcon: String,

            showToolbar: {
                type: Boolean,
                default: false
            },

            editRouteName: {
                type: String,
                default: 'admin-quest-edit'
            },
        },

        data: () => ({
            Helpers,
        }),

        mounted() {
        },

        computed: {
            localPagination: {
                get() {
                    return this.pagination;
                },

                set(value) {
                    this.$emit('update:pagination', value)
                }
            },
            localFilters: {
                get() {
                    return this.filters;
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },
            isLoading: {
                get() {
                    return this.loading;
                },

                set(value) {
                    this.$emit('update:loading', value)
                }
            },
        },

        watch: {},
        methods: {
            clearPlatformFilter() {
                this.localFilters.platform_code = ''
            }
        }
    }
</script>
