<template>
    <v-card flat>
        <v-app-bar flat dense :dark="dark" :color="toolbar" v-if="showToolbar">
            <v-btn icon
                   :dark="dark"
                   v-if="isDialog"
                   @click="$emit('update:dialog', false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

            <v-spacer></v-spacer>
        </v-app-bar>

        <mg-alert ref="mgAlert"></mg-alert>


        <v-list v-if="orders.length > 0" class="py-0">
            <draggable v-model="orders"
                       class="dragArea"
            >
                <v-list-item
                        v-for="order in orders"
                        :key="order.id">

                    <v-list-item-content >
                        <v-list-item-title>
                            {{ getSkus(order) }} <template v-if="(order.amount_admin !== null ? order.amount_admin : order.amount) / 100 > 0">({{ (order.amount_admin ? order.amount_admin : order.amount) / 100 }}€)</template>
                            <v-tooltip bottom v-if="!order.visible">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" small color="default">mdi-eye-off</v-icon>
                                </template>
                                <span>Facture non visible pour l'utilisateur</span>
                            </v-tooltip>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="canRefund">
                            {{ order.status_admin ? getStatusLabel(order.status_admin) : getStatusLabel(order.status) }}
                            <template v-if="order.stripe_id || order.paypal_id">-</template>
                            <span v-if="order.stripe_id">{{ order.stripe_id }}</span>
                            <span v-if="order.paypal_id">{{ order.paypal_id }}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-chip color="danger" disabledoutlined>{{ $helpers.parseDate((order.date_admin ? order.date_admin : order.created_at), 'DD/MM/YYYY HH:mm') }}</v-chip>
                    </v-list-item-action>

                    <v-list-item-action>
                         <v-menu offset-y left :close-on-content-click="false" min-width="300">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                    fab
                                    small
                                    icon>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list class="mt-2 py-0">
                                <v-list-item v-if="isRefundable(order)" @click="openDialogRefundConfirmation(order)">
                                    <v-list-item-content>
                                        <v-list-item-title>Rembourser</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon>mdi-cash</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider v-if="isRefundable(order)"></v-divider>

                                <v-list-item @click="updateOrder(order, {'visible': !order.visible});">
                                    <v-list-item-content>
                                        <v-list-item-title>Rendre {{order.visible? 'invisible' : 'visible'}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon> {{order.visible? 'mdi-eye-off' : 'mdi-eye'}}</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item @click="downloadOneOrder(order)">
                                    <v-list-item-content>
                                        <v-list-item-title>Télécharger</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon>mdi-download</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item @click="openDialogEditConfirmation(order)">
                                    <v-list-item-content>
                                        <v-list-item-title>Editer</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item @click="openDialogDeleteConfirmation(order)">
                                    <v-list-item-content>
                                        <v-list-item-title>Supprimer</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>

            </draggable>

        </v-list>

        <v-list v-else>
            <v-list-item>Aucune facture</v-list-item>
        </v-list>

        <v-dialog
                v-if="canRefund"
                :value="dialogRefundConfirmation"
                @input="$emit('update:dialogRefundConfirmation', $event)"
                max-width="300">
            <v-card class="text-center">
                <v-card-title class="headline justify-center">
                    Êtes-vous sur ?
                </v-card-title>
                <v-card-title class="subtitle-2 justify-center">
                    Le remboursement est irréversible.
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" depressed @click="$emit('update:dialogRefundConfirmation', false)">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="refundOneOrder">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog   
                :value="dialogEditConfirmation"
                @input="$emit('update:dialogEditConfirmation', $event)"
                max-width="800">
           <order-form
                v-model="currentOrder"
                submitBtn="Enregistrer"
                submitBtnColor="secondary"
                :isLoading="dialogEditConfirmationLoading"
                :showToolbar="toolbar"
                isDialog
                :dialog="dialogEditConfirmation"
                @update:dialog="$emit('update:dialogEditConfirmation', $event)"
                @submit="updateOrder">
           </order-form>
        </v-dialog>

        <v-dialog   
                :value="dialogDeleteConfirmation"
                @input="$emit('update:dialogDeleteConfirmation', $event)"
                max-width="300">
            <v-card class="text-center">
                <v-card-title class="headline justify-center">
                    Êtes-vous sur ?
                </v-card-title>
                <v-card-title class="subtitle-2 justify-center">
                    La suppression est irréversible.
                </v-card-title>
                <v-card-actions>
                    <v-btn depressed color="primary" @click="$emit('update:dialogDeleteConfirmation', false)">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="deleteOneOrder">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable'
import OrderForm from './OrderForm.vue'

    export default {
        name: 'order-list',

        components: {
            draggable,
            OrderForm
        },

        props: {
            /* orders */
            value: {
                type: Array,
                required: true,
            },

            /* general */
            toolbar: String,
            showToolbar: Boolean,
            toolbarTitle: String,
            dark: Boolean,
            dialog: Boolean,
            isDialog: Boolean,
            canRefund: Boolean,

            dialogRefunding: Boolean,
            dialogRefundConfirmation: Boolean,
            dialogDeleteConfirmation: Boolean,

            dialogEditConfirmation: Boolean,
            dialogEditConfirmationLoading: Boolean,
        },

        data: () => ({
            currentOrder: null
        }),

        mounted() {

        },

        computed: {
            orders: {
                get() {
                    return this.value
                },
                set(orders) {
                    this.$emit('input', orders)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        watch: {},

        methods: {
            refundOneOrder() {
                console.log("delete");
                this.$emit('refundOneOrder', this.currentOrder);
            },
            
            deleteOneOrder() {
                this.$emit('deleteOneOrder', this.currentOrder);
            },

            downloadOneOrder(order) {
                this.currentOrder = order;
                this.$emit('downloadOneOrder', this.currentOrder);
            },

            openDialogRefundConfirmation(sku) {
                this.currentOrder = sku;
                this.$emit('update:dialogRefundConfirmation', true);
            },

            openDialogEditConfirmation(order) {
                this.currentOrder = order;
                this.$emit('update:dialogEditConfirmation', true);
            },

            openDialogDeleteConfirmation(order) {
                this.currentOrder = order;
                this.$emit('update:dialogDeleteConfirmation', true);
            },

            getSkus(order) {
                const text = order.skus.data.map((sku) => {
                    return sku.title;
                }).join(",");
                return text || '-';
            },
            
            updateOrder(order, body) {
                this.$emit('updateOrder', order, body);
            },

            getStatusLabel(status) {
                switch (status) {
                    case 'paid':
                        return 'payé';
                    case 'pending':
                        return 'en cours';
                    case 'free':
                        return 'gratuit';
                    default:
                        return status;
                }
            },

            isRefundable(order) {
                const status = order.status_admin ? order.status_admin : order.status;
                const amount = order.amount_admin !== null ? order.amount_admin : order.amount;
                return this.canRefund 
                        && (status !== 'refund' || status !== 'free') 
                        && amount > 0 
                        && order.pi_id 
                        && (order.stripe_id || order.paypal_id);
            }
        }
    }
</script>
