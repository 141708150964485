<template>
    <v-card :flat="flat">
        <template v-if="showToolbar">
            <v-app-bar dark flat dense :color="colorToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-menu offset-y bottom left
                        v-if="showOptionsMenu">
                    <template v-slot:activator="{ on }">
                        <v-btn icon fab small v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense class="py-0">
                        <v-list-item
                                @click="archiveLesson">
                            <v-list-item-title class="primary--text">Supprimer</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <template v-if="isDialog">

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>


            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-container class="container--fluid" grid-list-lg>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="lesson.title"
                            :error-messages="errors.collect('title')"
                            label="Titre *"
                            name="title"
                            data-vv-title="title"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="lesson.method_id"
                            :error-messages="errors.collect('method_id')"
                            label="Methode ID *"
                            name="title"
                            data-vv-title="method_id"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="lesson.duration"
                            :error-messages="errors.collect('duration')"
                            label="Durée max de la leçon (en jour) *"
                            name="duration"
                            data-vv-title="duration"
                            type="number"
                            :min="0"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-model="lesson.active_campaign_id"
                            :error-messages="errors.collect('active_campaign_id')"
                            label="Active Campaign tag"
                            name="active_campaign_id"
                            data-vv-title="active_campaign_id"
                            type="text"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>


        <!--<mg-confirmation-modal
                :dialog.sync="disableAlert"
                @delete="submit(true)"
                confirmationText="Désactiver"
                :maxWidth="400"
        >
            <p class="subtitle-1 font-weight-bold text-center">
                Attention, vous allez désactiver une leçon
            </p>

            <ul style=" width: 80%;margin: auto;">
                <li>
                    Elle sera désactivé de tous les modules liés
                </li>
                <li>
                    Tous les modules liés devront être réactivé manuellement
                </li>
            </ul>

        </mg-confirmation-modal>-->

        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";

    export default {
        name: 'lesson-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            prices: Array,

            dialog: Boolean,
            isDialog: Boolean,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            loading: Boolean,

            disabled: Boolean,
            dark: Boolean,

            value: Object,

            showToolbar: Boolean,
            showOptionsMenu: Boolean,
            flat: Boolean,
            colorToolbar: String,
        },

        data: () => ({
            // disableAlert: false,
            pagination: Helpers.initPagination(20, 1, 'title', true),
            songSearch: null,
            songLoading: false,
            availableSongs: [],
        }),

        watch: {
            songSearch(val) {
                // Items have already been requested
                if (this.songLoading) return
                this.songLoading = true;
                this.getSongs(val);
            },
        },

        computed: {
            lesson: {
                get() {
                    return this.value;
                },
                set(value) {
                    console.error(value)
                    this.$emit('input', value)
                },
            },

            mgAlertComponent() {
                return this.$refs.mgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },
        },

        methods: {
            archiveLesson() {
                this.$emit('archiveLesson', this.lesson.id)
            },

            validateLessonForm() {
                return this.$validator.validateAll();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                this.validateLessonForm().then(isValid => {
                    if (isValid) {
                        this.$emit('submit');
                    }
                })
            },
        }
    }
</script>
