<template>
    <v-row>
        <v-col>
            <v-card>
                <v-app-bar dense flat dark :color="toolbar">
                    <v-btn icon dark
                           v-if="isDialog"
                           @click="$emit('update:dialog', $event)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{toolbarTitle}} <span v-if="showDataCount">({{totalItems}})</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>
                </v-app-bar>

                <v-row justify="center pt-2">
                    <v-col cols="12" sm="6" md="4" v-if="filtersLocal.hasOwnProperty('search')">
                        <v-text-field
                                v-model="filtersLocal.search"
                                ref="search"
                                prepend-icon="mdi-magnify"
                                @click:prepend="$refs.search.focus()"
                                clearable
                                label="Nom ou prénom ou email"
                        ></v-text-field>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="6" md="4">
                        <v-select
                            v-model="filtersLocal.platform"
                            :items="[{id: 'MYPIANOPOP', name:'MyPianoPop'}, {id: 'MYGUITARE', name:'MyGuitare'}, {id: null, name: 'Toutes'}]"
                            item-text="name"
                            item-value="id"
                            label="Platforme"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="6" md="4">
                        <v-select
                            v-model="filtersLocal.input_reference"
                            :items="[{id: 'song_list_page', name:'song_list_page'}]"
                            item-text="name"
                            item-value="id"
                            label="Référence de l'input"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-data-table
                        :options="pagination"
                        @update:options="$emit('update:pagination', $event)"
                        :server-items-length="totalItems"
                        :footer-props="{
                            'items-per-page-options' : [20, 50, 100, 150]
                        }"
                        :headers="headers"
                        :loading="isLoading"
                        :items="users">
                    <template v-slot:item.email="{ item }">
                        <router-link class="default--text"
                                     :to="{name: userEditRouteName, params: {uuid: item.user.data.id}}">
                            {{ item.user.data.email }}
                        </router-link>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import PageStore from 'mg-js-core/lib/stores/PageStore'

    export default {
        name: 'input-stats-table',

        props: {

            toolbar: String,
            dark: Boolean,
            isDialog: Boolean,
            isLoading: Boolean,
            dialog: Boolean,
            toolbarTitle: String,
            showSearchFilter: Boolean,
            headers: Array,

            value: {
                type: Array,
                required: true,
            },

            userEditRouteName: {
                type: String,
                default : 'admin-user-edit'
            },

            showDataCount: {
                type: Boolean,
                default: false
            },
            filters: {
                type: Object,
                required: true
            },
            pagination: {
                type: Object,
                required: true,
            },
            totalItems: Number,
        },

        data: () => ({
            PageStore,
            Helpers,

            pageTitle: 'Les dernières entrées',

            paginationOptions: {},

            result: [],
            delay: 350,
            clicks: 0,
            timer: null,

            selectFilterItems: [
                {text: 'Tous', value: null},
                {text: 'Oui', value: 1},
                {text: 'Non', value: 0},
            ],
        }),


        mounted() {
        },

        computed: {
            users: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            filtersLocal: {
                get() {
                    return this.filters
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },
        },

        watch: {},
    };
</script>
