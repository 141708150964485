<template>
    <v-select
        :items="$platformStore.platformItemList"
        v-model="platformCode"
        item-text="title"
        item-value="code"
        :hide-details="hideDetails"
        :error-messages="errors.collect(name)"
        :label="label"
        :data-vv-name="name"
        :clearable="clearable"
        :name="name">
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>

        <!-- Pass on all scoped slots -->
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope"/>
        </template>
    </v-select>
</template>

<script>
    export default {
        name: 'mg-select-platform',

        inject: ['$validator'],

        props: {
            value: String,
            clearable: Boolean,

            name: {
                type : String,
                default : "platform",
            },

            label: {
                type : String,
                default : 'Plateforme'
            },
            
            hideDetails: {
                type : Boolean,
                default : false
            },
        },

        computed: {
            platformCode: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
        },
    };
</script>
