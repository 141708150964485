import AdminLayout from '@/views/Layout.vue';
import LazyLoad from '@/services/LazyLoad'
// import AuthLogic from "mg-js-core/lib/logics/AuthLogic";
import RouterLogic from "@/logics/RouterLogic";
import AuthLogic from "mg-js-core/lib/logics/AuthLogic";


export default [
    {
        // path: '/admin',
        path: '/',
        component: AdminLayout,
        name: 'admin-layout',
        beforeEnter: (to, from, next) => {
            RouterLogic.redirectAdmin(to, from, next)
        },
        children: [
            {
                path: 'dashboard',
                name: 'admin-dashboard',
                component: LazyLoad.localView('Dashboard')
            },
            {
                path: 'profile',
                name: 'admin-profile',
                component: LazyLoad.localView('user/UserProfile'),
            },
            {
                path: 'users/:uuid/:tab?',
                name: 'admin-user-edit',
                component: LazyLoad.localView('user/UserEdit')

            },
            {
                path: 'users',
                name: 'admin-user-list',
                component: LazyLoad.localView('user/UserList')
            },
            {
                path: 'failed-registrations',
                name: 'admin-failed-registrations',
                component: LazyLoad.localView('failed-registrations/List')
            },
            {
                path: 'input-stats',
                name: 'input-stats',
                component: LazyLoad.localView('stats/InputStats')
            },
            {
                path: 'content/courses',
                name: 'admin-course-list',
                component: LazyLoad.localView('course/CourseList')

            },
            {
                path: 'content/courses/:uuid/:courseTab?/:courseModuleUuid?/:courseModuleTab?',
                name: 'admin-course-edit',
                component: LazyLoad.localView('course/CourseEdit')

            },
            {
                path: 'content/lessons',
                name: 'admin-lesson-list',
                component: LazyLoad.localView('lesson/LessonList')

            },
            {
                path: 'content/lessons/:uuid/:lessonTab?',
                name: 'admin-lesson-edit',
                component: LazyLoad.localView('lesson/LessonEdit')

            },
            {
                path: 'content/instruments',
                name: 'instrument-list',
                component: LazyLoad.localView('instrument/InstrumentList')
            },
            {
                path: 'content/import',
                name: 'import-user-list',
                component: LazyLoad.localView('import/ImportUserList')
            },
            {
                path: 'content/instruments/:uuid',
                name: 'instrument-edit',
                component: LazyLoad.localView('instrument/InstrumentEdit')
            },
            {
                path: 'automation/active-campaign',
                name: 'tag-list',
                component: LazyLoad.localView('active-campaign/TagList')
            },
            {
                path: 'notification/send',
                name: 'notification-send',
                component: LazyLoad.localView('notification/NotificationSend')
            },
            {
                path: 'referral/gift',
                name: 'referral-gift-list',
                component: LazyLoad.localView('referral/ReferralGift')
            },
            {
                path: 'referral/gift/:id',
                name: 'referral-gift-edit',
                component: LazyLoad.localView('referral/ReferralGiftEdit')
            },
            {
                path: 'reporting/users/:tab?',
                name: 'user-stats-list',
                beforeEnter: (to, from, next) => {
                    if(AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'statistic', 'statistic-advanced'])){
                        next()
                    }else{
                        next({name : 'admin-dashboard'})
                    }
                },
                component: LazyLoad.localView('reporting/UserStatsList')
            },
            {
                path: 'reporting/user-modules/:tab?',
                name: 'user-module-stats-list',
                beforeEnter: (to, from, next) => {
                    if(AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'statistic', 'statistic-advanced'])){
                        next()
                    }else{
                        next({name : 'admin-dashboard'})
                    }
                },
                component: LazyLoad.localView('reporting/UserModuleStatsList')
            },
            {
                path: 'reporting/activity',
                name: 'activity-stats-list',
                beforeEnter: (to, from, next) => {
                    if(AuthLogic.hasRole(['super-admin', 'admin', 'marketing', 'statistic', 'statistic-advanced'])){
                        next()
                    }else{
                        next({name : 'admin-dashboard'})
                    }
                },
                component: LazyLoad.localView('reporting/Activity')
            },
            // {
            //     path: 'reporting/orders-old',
            //     name: 'order-list-old',
            //     beforeEnter: (to, from, next) => {
            //         if(AuthLogic.hasRole(['super-admin', 'statistic-advanced'])){
            //             next()
            //         }else{
            //             next({name : 'admin-dashboard'})
            //         }
            //     },
            //     component: LazyLoad.localView('reporting/OrderList')
            // },
            {
                path: '/reporting/orders/:tab?',
                name: 'order-stats-list',
                beforeEnter: (to, from, next) => {
                    if(AuthLogic.hasRole(['super-admin', 'admin', 'statistic-advanced'])){
                        next()
                    }else{
                        next({name : 'admin-dashboard'})
                    }
                },
                component: LazyLoad.localView('reporting/OrderStatsList')
            },
            {
                path: 'reporting/feedback',
                name: 'admin-reporting-feedback',
                beforeEnter: (to, from, next) => {
                    if(AuthLogic.hasRole(['super-admin', 'admin'])){
                        next()
                    }else{
                        next({name : 'admin-dashboard'})
                    }
                },
                component: LazyLoad.localView('feedback/FeedbackCountPage')
            },
            {
                path: 'abandoned-cart',
                name: 'abandoned-cart-list',
                component: LazyLoad.localView('abandoned-cart/CartList')
            },
            {
                path: 'automation/active-campaign/:id',
                name: 'tag-edit',
                component: LazyLoad.localView('active-campaign/TagEdit')
            },
            {
                path: 'products',
                name: 'admin-product-list',
                component: LazyLoad.localView('product/ProductList')
            },
            {
                path: 'products/:uuid/:productTab?',
                name: 'admin-product-edit',
                component: LazyLoad.localView('product/ProductEdit')
            },
            {
                path: 'plans',
                name: 'admin-plan-list',
                component: LazyLoad.localView('plan/PlanList')
            },
            {
                path: 'community/comments/lessons',
                name: 'admin-community-comment-lesson-list',
                component: LazyLoad.localView('comment/LessonCommentList')
            },
            {
                path: 'community/comments/courses',
                name: 'admin-community-comment-course-list',
                component: LazyLoad.localView('comment/CourseCommentList')
            },
            {
                path: 'community/feedback/vip',
                name: 'admin-community-feedback-vip-list',
                component: LazyLoad.localView('feedback/FeedbackPage')
            },
            {
                path: 'community/video',
                name: 'admin-community-video-list',
                component: LazyLoad.localView('video/VideoList')
            },
            {
                path: 'plans/:uuid',
                name: 'admin-plan-edit',
                component: LazyLoad.localView('plan/PlanEdit')
            },
            {
                path: 'settings/:tab?',
                name: 'admin-setting-edit',
                component: LazyLoad.localView('setting/SettingEdit')
            },
            {
                path: '/forum',
                name: 'forum',
                component: LazyLoad.localView('Forum')
            },
            {
                path: 'content/songs',
                name: 'admin-song-list',
                component: LazyLoad.localView('songs/SongList')

            },
            {
                path: 'content/songs/:uuid/:songTab?',
                name: 'admin-song-edit',
                component: LazyLoad.localView('songs/SongEdit')

            },
            {
                path: 'content/quests',
                name: 'admin-quest-list',
                component: LazyLoad.localView('quest/QuestList')

            },
            {
                path: 'content/quests/:uuid',
                name: 'admin-quest-edit',
                component: LazyLoad.localView('quest/QuestEdit')

            },
            {
                path: 'mass-unlock',
                name: 'mass-unlock',
                component: LazyLoad.localView('tools/MassUnlock')
            },
            {
                path: 'download',
                name: 'admin-download',
                component: LazyLoad.localView('Download')

            },
        ]
    },
]
