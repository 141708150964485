<template>
    <div>
        <v-expansion-panels
                id="lesson-list-expansion"
                :value="currentLessonKey"
                accordion
                mandatory
                @change="$emit('update:currentLessonKey', $event)">
            <v-expansion-panel
                    v-for="(lesson,index) in lessons"
                    :key="lesson.id"
                    :id="`lesson-${lesson.slug}`"
            >
                <v-expansion-panel-header class="">
                    <v-list-item :key="index">
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-1 font-weight-bold">
                                {{ lesson.title }} - {{ lesson.method_id }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                    <v-card flat tile color="creamy">
                        <v-list two-line
                                class="transparent py-0 lessons-list">
                            <v-list-item-group
                                    mandatory
                                    v-model="currentLessonPartIndexValue">
                                <template v-for="lessonPart in currentLessonParts">
                                    <v-list-item
                                            class="lesson-parts"
                                            v-show="lessonPart.enabled"
                                            :key="lessonPart.id">
                                        <v-list-item-content class="ml-10">
                                            <v-list-item-title class="body-2">
                                                {{ lessonPart.title }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

<!--                                    <v-divider-->
<!--                                            v-if="index !== currentLessonParts.length - 1"-->
<!--                                            :key="index">-->
<!--                                    </v-divider>-->
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";

    export default {
        name: 'lesson-list-expansion-light',

        props: {
            // course: {
            //     type: Object,
            //     default: () => (new CourseDripContentModel())
            // },
            lessons: Array,

            currentLessonParts: Array,

            currentLessonKey: Number,
            currentLessonPartIndex: Number,
        },

        data: () => ({
            PageStore,
        }),

        mounted() {
        },

        watch: {
            // currentLessonKey(value) {
            //     if (typeof value !== 'number') {
            //         this.$emit('update:currentLessonPartIndex', null)
            //     }
            // },
            //
            // currentLessonParts(value) {
            //     this.$emit('update:currentLessonParts', value)
            // }
        },

        computed: {
            // nextLesson() {
            //     return this.course.nextLesson;
            // },
            //
            // currentLesson() {
            //     return this.course.currentLesson;
            // },
            //
            // currentCourseModule() {
            //     return this.course.currentCourseModule;
            // },
            //
            // nextUnlockableOn() {
            //     return this.currentLesson && this.currentLesson.next_unlockable_on
            //         ? this.$moment(this.currentLesson.next_unlockable_on)
            //         : null;
            // },
            //
            // unlockDuration() {
            //     const nextUnlockableOn = this.nextUnlockableOn
            //
            //     if (nextUnlockableOn) {
            //         return this.$moment.duration(nextUnlockableOn.diff(this.$moment()))
            //     } else {
            //         return null
            //     }
            // },
            //
            // getUnlockDiff() {
            //     if (this.course.dripping_enabled) {
            //         const unlockDuration = this.unlockDuration
            //
            //         if (unlockDuration) {
            //             return `Disponible ${unlockDuration.humanize(true)}`
            //         } else {
            //             return `Disponible ${this.getRelatedLesson(this.currentLesson).duration} jours après le début de la leçon précédente`
            //         }
            //     } else {
            //         return `Le déblocage du contenu est désactivé`
            //     }
            // },
            //
            // /* NEW */
            currentLessonPartIndexValue: {
                get() {
                    return this.currentLessonPartIndex
                },
                set(value) {
                    this.$emit('update:currentLessonPartIndex', value)
                },
            },
        },

        methods: {
            getRelatedPart(userCourseModuleLesson) {
                let lesson = this.getRelatedLesson(userCourseModuleLesson);

                return lesson.lessonParts && lesson.lessonParts.data
                    ? lesson.lessonParts.data
                    : []
            },

            getRelatedLesson(courseModuleDripContentLesson) {
                return courseModuleDripContentLesson.lesson && courseModuleDripContentLesson.lesson.data
                    ? courseModuleDripContentLesson.lesson.data
                    : LessonLogic.getEmptyObject()
            }
        }
    }
</script>
