import Vue from 'vue'
import Router from 'vue-router'
import PageStore from 'mg-js-core/lib/stores/PageStore'

import AdminRoute from '@/router/admin'
import PublicRoute from '@/router/public'
import RouterLogic from "@/logics/RouterLogic";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...AdminRoute,
        ...PublicRoute,
    ],
    meta: []
});

router.beforeEach((to, from, next) => {
    if (!process.env.VUE_APP_MAINTENANCE) {
        PageStore.resetPageInfo();
        RouterLogic.userCanAccess(to)
            ? next()
            : next({name : 'admin-dashboard'});
    } else {
        to.name !== 'maintenance'
            ? next({name: 'maintenance'})
            : next()
    }
});

export default router;
