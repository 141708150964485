<template>
    <v-card :flat="flat">
        <v-app-bar flat dark dense color="secondary" class="title">
            <!--<v-toolbar-title class="font-weight-light">-->
            <!--Parties-->
            <!--</v-toolbar-title>-->
            <v-spacer></v-spacer>
            <v-menu offset-y bottom left v-if="!lessonPartsIsSortable">
                <template v-slot:activator="{ on }">
                    <v-btn icon fab small v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list dense class="py-0">
                    <v-list-item v-if="!lessonPartsIsSortable && lessonParts.length" @click="sortLessonParts">
                        <v-list-item-title>Trier les parties</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item @click="$emit('update:addLessonPartDialog', true)">
                        <v-list-item-title>Ajouter une partie</v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-menu>

            <template v-if="lessonPartsIsSortable">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="cancelSortLessonParts">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Annuler</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="submitSortLessonParts">
                            <v-icon small>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Enregistrer</span>
                </v-tooltip>
            </template>
        </v-app-bar>

        <v-container fluid>
            <v-row v-if="lessonParts.length">
                <v-col md="4">
                    <v-card flat style="height: 100%; max-height: 500px; overflow-y: scroll">
                        <v-list two-line class="py-0">
                            <draggable v-model="lessonParts"
                                       class="dragArea"
                                       :options="{group:'people'}"
                                       @change="lessonPartMoved">
                                <v-list-item
                                        @click="!lessonPartsIsSortable ? $emit('update:currentKeyPart', key) : null"
                                        :input-value="currentKeyPart === key"
                                        :class="{'grabable' : lessonPartsIsSortable}"
                                        v-for="(lessonPart,key) in lessonParts"
                                        :key="lessonPart.id"
                                >

                                    <v-list-item-action v-if="lessonPartsIsSortable">
                                        <v-icon>mdi-drag</v-icon>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ lessonPart.title }}</v-list-item-title>
                                    </v-list-item-content>

                                    <v-chip dark small
                                            v-if="!lessonPart.enabled">
                                        Désactivé
                                    </v-chip>
                                </v-list-item>

                            </draggable>

                        </v-list>
                    </v-card>
                </v-col>

                <v-col md="8">
                    <lesson-part-form-complete
                            :types="lessonPartTypes"
                            flat
                            ref="updateForm"
                            @submit="updateLessonPart"
                            @deleteLessonPart="deleteLessonPart"
                            :loading="updateLessonPartLoading"
                            :dialogDelete="deleteLessonPartDialog"
                            @update:dialogDelete="$emit('update:deleteLessonPartDialog', $event)"
                            v-model="currentLessonPart"
                    ></lesson-part-form-complete>
                </v-col>
            </v-row>

            <v-row class="px-1 fill-height" v-if="!lessonParts.length">
                <v-col cols="12">
                    <v-card flat style="height: 100%; max-height: 500px; overflow-y: scroll">
                        <v-card-text class="text-center">
                            <v-btn color="secondary"
                                   @click="$emit('update:addLessonPartDialog', true)">Ajouter une partie
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog :value="addLessonPartDialog" @input="$emit('update:addLessonPartDialog', $event)"
                  max-width="900px">

            <lesson-part-form
                    :types="lessonPartTypes"
                    dark
                    isDialog
                    ref="addForm"
                    :dialog="addLessonPartDialog"
                    @update:dialog="$emit('update:addLessonPartDialog', $event)"
                    toolbarTitle="Ajouter une partie"
                    showToolbar="secondary"

                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    submitBtnColor="secondary lighten-1"
                    @submit="addLessonPart"
                    :loading="addLessonPartLoading"
                    v-model="newLessonPart">
            </lesson-part-form>
        </v-dialog>
    </v-card>


</template>

<style lang="scss" scoped>
</style>

<script>
    import _ from 'lodash';
    import draggable from 'vuedraggable'
    import LessonPartLogic from "mg-js-core/lib/logics/LessonPartLogic";
    import PageStore from "mg-js-core/lib/stores/PageStore";

    export default {
        name: 'lesson-part-list-form',

        components: {
            draggable,
        },

        props: {
            value: {
                type: Array,
                required: true,
            },

            flat: Boolean,
            currentKeyPart: Number,

            lessonPartsIsSortable: Boolean,

            addLessonPartLoading: Boolean,
            addLessonPartDialog: Boolean,

            updateLessonPartLoading: Boolean,
            deleteLessonPartDialog: Boolean,
        },


        data: () => ({
            // lessonPartsIsSortable: false,
            // currentKeyPart: 0,
            PageStore,

            newResource: {
                title: '',
                file: null,
            },

            newLessonPart: LessonPartLogic.getEmptyObject(),
            /* lesson form */
            // lessonDialog: false,
            // lessonIsLoading: false,
            
            lessonPartTypes: []
        }),

        computed: {
            lessonParts: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            currentLessonPart() {
                return this.lessonParts
                    ? Object.assign({}, this.lessonParts[this.currentKeyPart])
                    : LessonPartLogic.getEmptyObject()
            },
        },

        mounted() {
            if (this.lessonParts.length) {
                this.newLessonPart = LessonPartLogic.getEmptyObject({
                    position: this.lessonParts.length ? _.last(this.lessonParts).position + 1 : 1
                })
            }
            this.getAllTypes();
        },

        methods: {
            sortLessonParts() {
                this.$emit('update:lessonPartsIsSortable', true)
                this.lessonPartsRaw = Object.assign([], this.lessonParts)
            },
            cancelSortLessonParts() {
                this.$emit('update:lessonPartsIsSortable', false)
                this.lessonParts = Object.assign([], this.lessonPartsRaw)
            },

            submitSortLessonParts() {
                let newOrder = _.map(this.lessonParts, (lessonPart, index) => {
                    return {
                        id: lessonPart.id,
                        position: index + 1
                    }
                })

                this.$emit('submitSortLessonParts', {
                    lesson_parts_order : newOrder
                })

            },
            showLesson(lesson) {
                this.$emit('showLesson', lesson)
            },
            currentLessons(lessons) {
                return _.sortBy(lessons, ['position'])
            },
            lessonPartMoved(value) {
                console.warn('lessonPartMoved')
                console.warn(value.moved)
                console.warn(value.moved.newIndex)

                this.$emit('update:currentKeyPart', value.moved.newIndex)
            },

            addLessonPart() {
                this.$emit('addLessonPart', this.newLessonPart);
            },

            updateLessonPart() {
                this.$emit('updateLessonPart', this.currentLessonPart);
            },

            deleteLessonPart() {
                this.$emit('deleteLessonPart', this.currentLessonPart);
            },

            addLessonPartFormError(errors) {
                this.$refs.addForm.addFormErrors(errors)
            },

            addUpdateLessonPartFormError(errors) {
                this.$refs.updateForm.addFormErrors(errors)
            },

            getAllTypes() {
                LessonPartLogic
                    .getAllTypes()
                    .then(({data}) => {
                        this.lessonPartTypes = data;
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        }
    };
</script>
