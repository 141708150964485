<template>
    <v-card flat class="">
        <v-app-bar flat dark v-if="showToolbar" dense :color="toolbar">
            <v-btn icon dark
                   v-if="isDialog"
                   @click="$emit('update:dialog', false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="font-weight-light">{{toolbarTitle}} <span
                    v-if="showDataCount">({{totalItems}})</span></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="hasClickAddPlan"
                   @click="$emit('update:dialog', true)"
                   icon>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-app-bar>

        <v-row v-if="filtersValue" class="justify-center">
            <v-col cols="4" v-if="showSearchFilter && filtersValue.hasOwnProperty('search')">
                <v-text-field
                        v-model="filtersValue.search"
                        ref="search"
                        prepend-icon="mdi-magnify"
                        @click:prepend="$refs.search.focus()"
                        clearable
                        label="Nom"
                ></v-text-field>

            </v-col>

            <v-col cols="4" v-if="showProductFilter && filtersValue.hasOwnProperty('product_id')">
                <v-autocomplete
                        :items="availableStripeProducts"
                        v-model="filtersValue.product_id"
                        item-text="title"
                        item-value="id"
                        :search-input="productsFiltersSearch"
                        @update:search-input="$emit('update:productsFiltersSearch', $event)"
                        label="Produit"
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-divider v-if="filtersValue"></v-divider>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-card-text>
            <v-data-table
                    :options.sync="paginationValue"
                    :server-items-length="totalItems"
                    :footer-props="{
                        'items-per-page-options' : [20, 50, 100, 150]
                    }"
                    :headers="headers"
                    :loading="loading"
                    item-key="id"
                    :items="plans">
                <template v-slot:item="props">
                    <tr :class="{ clickable : hasClickRowEvent}"
                        @click="clickRow(props.item)">
                        <td>
                            {{ props.item.title ? props.item.title : '-' }}
                        </td>
                        <td>
                            {{ props.item.product ? props.item.product.data.title : '-' }}
                        </td>
                        <td>
                            {{generateInterval(props.item) }}
                        </td>
                        <td>
                            {{ !!props.item.vipPlan && props.item.vipPlan.data.deleted_at === null ? 'Oui' : 'Non' }}
                        </td>
                        <td>
                            {{!!props.item.vipPlan && props.item.vipPlan.data.deleted_at === null ? generateInterval(props.item.vipPlan.data) : '-'}}
                        </td>
                        <td>
                            {{ props.item.enabled ? 'Oui' : 'Non' }}
                        </td>
                        <td>
                            {{ Helpers.parseDate(props.item.created_at, 'DD/MM/YYYY') }}
                        </td>
                        <td class="text-right">
                            <v-menu offset-y bottom left>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon fab small v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense>
                                    <v-list-item @click="$emit('click:showPlan', props.item)">
                                        <v-list-item-title>Voir</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item @click="togglePlan(props.item)">
                                        <v-list-item-title>
                                            {{props.item.enabled ? 'Désactiver' : 'Activé'}}
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item @click="openDialogDeleteConfirmation(props.item)">
                                        <v-list-item-title class="primary--text">Supprimer</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </template>
            </v-data-table>

        </v-card-text>

        <v-dialog
                :value="dialogDeleting"
                @input="$emit('update:dialogDeleting', $event)"

                hide-overlay
                persistent
                width="300">
            <v-card color="accent" dark>
                <v-card-text class="pt-5">
                    <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
                :value="dialogDeleteConfirmation"
                @input="$emit('update:dialogDeleteConfirmation', $event)"
                max-width="300">
            <v-card color="accent" dark class="text-center">
                <v-card-title class="headline justify-center">
                    Êtes-vous sur ?
                </v-card-title>
                <v-card-title class="subtitle-2 justify-center">
                    La suppresion est irréversible.
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" flat @click="$emit('update:dialogDeleteConfirmation', false)">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn flat @click="deleteOnePlan()">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import PlanLogic from "mg-js-core/lib/logics/PlanLogic";

    export default {
        name: 'plan-table',

        props: {
            toolbar: String,
            toolbarTitle: String,
            showSearchFilter: Boolean,
            showProductFilter: Boolean,
            availableStripeProducts: Array,
            productsFiltersSearch: String,
            isDialog: Boolean,


            loading: Boolean,

            canAdd: Boolean,
            canDelete: Boolean,
            canShow: Boolean,

            showProduct: Boolean,
            showOrganization: Boolean,
            organizations: Array,

            currentPlan: Object,

            value: {
                type: Array,
                required: true,
            },
            products: {
                type: Array,
            },
            newPlan: {
                type: Object,
                default() {
                    return PlanLogic.getEmptyObject()
                }
            },
            dialog: {
                type: Boolean,
                default: false
            },
            dialogUpdate: {
                type: Boolean,
                default: false
            },
            dialogDeleteConfirmation: {
                type: Boolean,
                default: false
            },
            dialogDeleting: {
                type: Boolean,
                default: false
            },
            showToolbar: {
                type: Boolean,
                default: false
            },
            showDataCount: {
                type: Boolean,
                default: false
            },
            filters: {
                type: Object,
                required: true
            },
            pagination: {
                type: Object,
                required: true,
            },

            selectAll: null,
            totalItems: Number,
        },
        data: () => ({
            Helpers,
            // dialog: false,
            planToDelete: null,

            headers: [
                {
                    text: 'Nom',
                    align: 'left',
                    sortable: true,
                    value: 'name'
                },
                {
                    text: 'Produit lié',
                    align: 'left',
                    sortable: true,
                    value: 'product_name'
                },
                {
                    text: 'Prix',
                    align: 'left',
                    sortable: true,
                    value: 'product_name'
                },
                {
                    text: 'Option VIP',
                    align: 'left',
                    sortable: true,
                    value: 'vip_allowed'
                },
                {
                    text: 'Prix Option VIP',
                    align: 'left',
                    sortable: true,
                    value: 'vip_price'
                },
                {
                    text: 'Activé',
                    align: 'left',
                    sortable: true,
                    value: 'is_enabled'
                },
                {
                    text: 'Créé le',
                    align: 'left',
                    sortable: true,
                    value: 'created_at'
                },
                {
                    align: 'right',
                    sortable: false,
                },
            ],
        }),

        computed: {
            plans: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            hasClickRowEvent() {
                return !!(this.$listeners && this.$listeners['click:row'])
            },

            hasClickAddPlan() {
                return !!(this.$listeners && this.$listeners['click:addPlan'])
            },

            hasClickDeleteOne() {
                return !!(this.$listeners && this.$listeners['click:deleteOne'])
            },

            paginationValue: {
                get() {
                    return this.pagination;
                },
                set(value) {
                    this.$emit('update:pagination', value)
                }
            },
            filtersValue: {
                get() {
                    return this.filters;
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },

            mgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        watch: {
            dialogDeleteConfirmation(value) {
                if (value === false) {
                    this.planToDelete = null
                }
            }
        },
        methods: {
            togglePlan(plan) {
                this.$emit('togglePlan', plan.id, {
                    enabled: !plan.enabled
                });
            },

            deleteOnePlan() {
                this.$emit('deleteOnePlan', this.currentPlan);
            },

            generateInterval(plan) {
                let subtitle = '';

                console.warn(plan.currency === 'EUR' ? '€' : '$')

                subtitle = subtitle.concat(plan.price)
                subtitle = subtitle.concat(plan.currency === 'EUR' ? '€' : '$')

                let interval = '';

                if (plan.interval === 'week') {
                    interval = 'semaine'
                } else if (plan.interval === 'year') {
                    interval = 'année'
                } else {
                    interval = 'mois'
                }

                return subtitle.concat('/' + interval);
            },

            clickRow(plan) {
                if (this.hasClickRowEvent) {
                    this.$emit('click:row', plan)
                }
            },
            openDialogDeleteConfirmation(plan) {
                this.$emit('update:currentPlan', Object.assign(PlanLogic.getEmptyObject(), plan));
                this.$emit('update:dialogDeleteConfirmation', true);
            },

            changeSort(header) {
                if (header.sortable) {
                    if (this.paginationValue.sortBy === header.value && !this.paginationValue.descending) {
                        this.paginationValue.descending = true
                    } else if (this.paginationValue.sortBy === header.value && this.paginationValue.descending) {
                        this.paginationValue.sortBy = null
                        this.paginationValue.descending = null
                    } else {
                        this.paginationValue.sortBy = header.value
                        this.paginationValue.descending = false
                    }
                }
            },
        }
    };
</script>
