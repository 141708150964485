<template>
    <div class="comment-editor">
        <v-list-item class="px-0">
            <v-list-item-avatar
                class="white--text align-self-start mt-0"
                v-if="user.id && showAvatar">
                <img v-if="user.avatar"
                     v-media-src="user.avatar.data">
                <v-img v-else
                       :src="require('@/assets/mg-avatar-logo.svg')">
                </v-img>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-rating dense
                          v-if="canRate"
                          class="mb-2"
                          color="star"
                          background-color="star"
                          v-model="rating"
                >
                </v-rating>
                <editor-content
                    ref="editor"
                    @paste.native="paste"
                    style="border-radius: 8px;"
                    :style="`max-width: ${maxWidth}`"
                    :class="{'secondary' : $vuetify.theme.dark, 'white' : !$vuetify.theme.dark, 'error--border' : errorMessages.length}"
                    :editor="editor"/>


                <div :style="!errorMessages.length ? 'display : hidden' : ''" class="v-text-field__details">
                    <div class="v-messages theme--light error--text">
                        <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                                {{ errorMessages.length ? errorMessages[0] : '' }}
                            </div>
                        </div>
                    </div>
                </div>
            </v-list-item-content>
        </v-list-item>


        <v-list-item class="px-0" v-if="canShowActionButtons && showActionButtons">
            <v-list-item-content>
                <v-card-actions :style="`max-width: ${maxWidth ? `calc(${maxWidth} + 15px` : '100%'}`"
                                class="pa-0 text-subtitle-1 justify-end">
                    <div
                        class="pr-4 clickable"
                        style="color: #A0A4A8"
                        @click="cancel">
                        Annuler
                    </div>
                    <div class="pr-4 clickable primary--text"
                         @click="dialog = true">
                        Ajouter une video
                    </div>
                    <v-btn color="primary" rounded class="px-5 py-2"
                           :loading="loading" depressed
                           @click="submitMessage()">
                        {{ submitBtnText }}
                    </v-btn>
                </v-card-actions>
            </v-list-item-content>
        </v-list-item>

        <v-dialog v-model="dialog" max-width="544px">
            <v-card flat class="py-9 px-10">
                <div class="text-h2 primary--text pb-7">
                    Ajouter une vidéo
                </div>

                <v-btn icon
                       @click="dialog = false" small
                       style="position: absolute;right: 10px;top: 10px;">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                Hébergeur
                <v-radio-group v-model="videoProvider" row class="mt-3 pt-0">
                    <v-radio label="Youtube" value="youtube"></v-radio>
                    <v-radio label="Vimeo" value="vimeo"></v-radio>
                </v-radio-group>

                <mg-text-field
                    label="Lien"
                    placeholder="Copier le lien ici"
                    v-model="videoUrl"
                    :error-messages="errors.collect('videoUrl')"
                    v-validate="'required'"
                    data-vv-name="videoUrl"
                ></mg-text-field>
                <v-card-actions class="pb-0 px-0" style="padding-top: 2px">
                    <v-spacer></v-spacer>
                    <v-btn rounded depressed small
                           class="px-5"
                           color="primary" @click="insertVideo()">Ajouter
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss">
    .comment-editor {

        & *.is-empty:nth-child(1)::before {
            content: attr(data-empty-text);
            float: left;
            color: #aaa;
            pointer-events: none;
            height: 0;
            font-style: italic;
        }

        .editor__content * {
            box-sizing: border-box !important;
            -moz-box-sizing: border-box !important;
            -webkit-box-sizing: border-box !important;

        }

        .error--border {
            .ProseMirror {
                border: 2px solid var(--v-error-base) !important;
            }
        }

        .ProseMirror {
            padding: 12px 16px;
            max-height: 144px;
            overflow: auto;

            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;

            box-sizing: border-box;
            border-radius: 8px;
            border: 2px solid #A0A4A8;

            &:focus, .ProseMirror-focused {
                #label_editor {
                    color: rgb(206, 33, 53);
                }

                outline: none !important;
            }

            -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        }

        .ProseMirror {
            &:focus, .ProseMirror-focused {
                border: 2px solid var(--v-primary-base);
            }
        }
    }

</style>

<script>
    import MgEditorMixin from "mg-js-core/src/mixins/MgEditorMixin";

    export default {
        components: {
            /* more on MgEditorMixin */
        },

        mixins: [
            MgEditorMixin
        ],

        props: {
            /* local */
            maxWidth: String,
            canRate: Boolean,
            initRating: Number,
            showAvatar: {
                type: Boolean,
                default: true
            },
            canShowActionButtons: {
                type: Boolean,
                default: true
            },
            showActionButtons: {
                type: Boolean,
                default: true
            },

            /* more on MgEditorMixin */
        },

        data: () => ({
            /* local */
            rating: 5,

            /* more on MgEditorMixin */
        }),

        watch: {
            rating: {
                handler(value) {
                    this.$emit('updateRating', value)
                }
            }
        },

        mounted() {
            /* more on MgEditorMixin */
        },

        methods: {
            /* more on MgEditorMixin */
        },
    }
</script>
