<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-stepper v-model="stepperIndex" vertical class="transparent pb-2">
            <v-stepper-step :complete="stepperIndex > 1" :step="1" editable>
                Cours
            </v-stepper-step>

            <v-stepper-content :step="1">
                <v-col cols="12">
                    <v-autocomplete
                            :items="availableCourses"
                            :value="selectedCourse"
                            @input="selectCourse"
                            item-text="title"
                            item-value="id"
                            returnObject
                            cache-items
                            :search-input="searchCourse"
                            @update:search-input="$emit('update:searchCourse', $event)"
                            :error-messages="errors.collect('courses')"
                            data-vv-name="courses"
                            v-validate="'required'"
                            label="Cours *"
                    >
                        <template v-slot:selection="data">
                            <v-chip class="caption">
                                {{ data.item.title }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-btn color="secondary" @click="validateCourses">Continuer</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepperIndex > 2" :step="2" editable>
                Modules
            </v-stepper-step>

            <v-stepper-content :step="2">
                <v-list two-line subheader v-if="selectedCourse">
                    <v-subheader>{{selectedCourse.title}}</v-subheader>

                    <template v-if="selectedCourse.courseModules">
                        <v-list-item v-for="courseModule in selectedCourse.courseModules.data"
                                     :key="courseModule.id"
                                     @click="selectCourseModule(courseModule)"
                                     :disabled="!courseModule.enabled">
                            <v-list-item-action>
                                <v-checkbox v-model="courseModulesSelectedValue"
                                            color="secondary"
                                            readonly
                                            :disabled="!courseModule.enabled"
                                            @change="$emit('change', $event)"
                                            :ref="courseModule.id"
                                            :value="courseModule"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>{{courseModule.title}}</v-list-item-title>

                                <v-list-item-subtitle>
                                    <template v-if="courseModule.lessons && courseModule.lessons.data">
                                        {{courseModule.lessons.data.length}} leçon<span
                                            v-if="courseModule.lessons.data.length > 1">s</span>
                                    </template>
                                    <template v-else>
                                        0 leçon
                                    </template>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-chip dark small disabled
                                        v-if="!courseModule.enabled">
                                    Désactivé
                                </v-chip>
                            </v-list-item-action>
                        </v-list-item>
                    </template>

                </v-list>

                <v-btn color="secondary lighten-1"
                       :loading="loading"
                       @click="stepperIndex++">Continuer
                </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepperIndex > 3" :step="3" editable>
                Configuration
            </v-stepper-step>

            <v-stepper-content :step="3">
                <course-drip-content-form
                        v-if="selectedCourse"
                        submitBtn="Enregistrer"
                        :value="selectedCourse"
                        showVipCheckbox
                        :loading="loading"
                        @input="$emit('update:selectedCourse', $event)"
                        submitBtnColor="secondary"
                        submitBtnClass="text-center"
                        :courseModules="courseModulesSelectedValue"
                        @submit="submit"
                        showCurrentLesson>
                </course-drip-content-form>
            </v-stepper-content>
        </v-stepper>
    </v-card>
</template>

<script>
    import _ from 'lodash'
    import Helpers from "mg-js-core/lib/services/Helpers";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import CourseModuleLogic from "mg-js-core/lib/logics/CourseModuleLogic";

    export default {
        name: 'course-drip-content-stepper',

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            dark: Boolean,
            showToolbar: String,
            toolbarTitle: String,
            dialog: Boolean,
            isDialog: Boolean,

            value: {
                type: Object,
                required: true,
            },

            loading: Boolean,
            step: Number,


            /* courses */
            availableCourses: Array,
            selectedCourse: Object,
            searchCourse: String,

            currentLesson: Object,

            showSelectCourses: Boolean,

            courseModulesSelected: Array,
        },

        data: () => ({
            rowActive: {},
            // selectedCourse: []
        }),

        mounted() {

        },

        computed: {
            stepperIndex: {
                get() {
                    return this.step
                },
                set(step) {
                    this.$emit('update:step', step)
                }
            },

            courseModulesSelectedValue: {
                get() {
                    return this.courseModulesSelected;
                },
                set(value) {
                    this.$emit('update:courseModulesSelected', value)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        watch: {
            dialog: {
                handler(value) {
                    if (!value) {
                        this.resetValidation()
                    }
                },
                deep: true
            },
        },

        methods: {
            selectCourse(course) {
                course.enabled = true
                course.dripping_enabled = true
                course.visible = true

                this.$emit('update:selectedCourse', course)
                this.$emit('update:currentLesson', LessonLogic.getEmptyObject())

                this.courseModulesSelectedValue = [];
            },


            selectCourseModule(courseModule) {
                const index = _.findIndex(this.courseModulesSelectedValue, selectedCourseModule => {
                    return selectedCourseModule.id === courseModule.id
                });

                if (index > -1) {
                    this.courseModulesSelectedValue.splice(index, 1);

                } else {
                    this.courseModulesSelectedValue.push(courseModule);
                }

                //refresh currentLesson
                this.$nextTick(() => {
                    this.courseModulesSelectedValue = _.sortBy(this.courseModulesSelectedValue, ['position']);

                    this.selectFirstLesson();
                })
            },


            selectFirstLesson() {
                this.$emit('update:currentLesson', CourseModuleLogic.selectFirstLesson(this.courseModulesSelectedValue))
            },


            validateCourses() {
                this.$validator.validate('courses').then(isValid => {
                    if (isValid) {
                        this.stepperIndex++
                    }
                })
            },

            submit(id, body) {
                this.$emit('submit', {
                    ...body,
                    course_id: this.selectedCourse.id,
                    course_module_ids: _.map(this.courseModulesSelectedValue, (courseModule) => {
                        return courseModule.id
                    })
                })
            },
            resetValidation() {
                this.$validator.reset();
            },

            addFormErrors(errors) {
                this.MgAlertComponent.displayAlert();
                Helpers.addFormErrors(this.$validator, errors);
            },
        }
    }
</script>
