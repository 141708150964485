<template>
    <v-row>
        <v-col>
            <v-card>
                <v-app-bar flat dense color="secondary" dark v-if="showToolbar">
                    <v-icon v-if="toolbarIcon">{{toolbarIcon}}</v-icon>
                    <v-toolbar-title class="font-weight-light">{{pageTitle}}</v-toolbar-title>

                    <template v-if="canAdd">
                        <v-spacer></v-spacer>

                        <v-btn icon
                               @click="$emit('update:dialogAdd', true)">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>

                </v-app-bar>


                <template v-if="Object.keys(localFilters).length > 0">
                    <v-row justify="center">
                        <v-col cols="12" sm="6" md="4" v-if="localFilters.hasOwnProperty('search')">
                            <v-text-field
                                    v-model="localFilters.search"
                                    ref="search"
                                    prepend-icon="mdi-magnify"
                                    @click:prepend="$refs.search.focus()"
                                    clearable
                                    label="Titre"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                </template>

                <v-data-table
                        :options.sync="localPagination"
                        :server-items-length="totalItems"
                        :footer-props="{
                            'items-per-page-options' : [20, 50, 100, 150]
                        }"
                        :headers="headers"
                        :items="courses">

                    <template v-slot:item.title="{ item }">
                        <router-link class="default--text"
                                     :to="{name: routeNameShowCourse, params: {uuid: item.slug}}">
                            {{ item.title }}
                        </router-link>
                    </template>
                    <template v-slot:item.enabled="{ item }">
                        {{ item.enabled ? 'Oui' : 'Non'}}
                    </template>
                    <template v-slot:item.stats="{ item }">
                        <v-btn icon depressed @click="downloadStats(item)">
                            <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

        <v-dialog :value="dialogAdd" @input="$emit('update:dialogAdd', $event)" max-width="900px">

            <course-form
                    dark
                    ref="courseFormAdd"
                    isDialog
                    :dialog="dialogAdd"
                    @update:dialog="$emit('update:dialogAdd', $event)"
                    toolbarTitle="Ajouter un course"
                    showToolbar

                    :loading="addLoading"
                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    submitBtnColor="secondary lighten-1"
                    @submit="createCourse"

                    :value="newCourse"
                    @input="$emit('update:newCourse', $event)"

                    :tagStylesAvailable.sync="tagStylesAvailable"
                    :tagLevelsAvailable.sync="tagLevelsAvailable"
                    :instrumentsAvailable.sync="instrumentsAvailable"
            >
            </course-form>
        </v-dialog>

        <v-dialog
            :value="exportLoading"
            persistent
            width="300"
        >
            <v-card>
                <v-card-text class="pt-2 pb-4 text-center">
                    Export en préparation
                    <v-progress-linear
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";
    import CourseLogic from "mg-js-core/lib/logics/CourseLogic";

    export default {
        name: 'course-table',
        props: {
            courses: Array,
            headers: Array,
            filters: {
                type: Object,
                default() {
                    return {}
                }
            },
            totalItems: Number,

            pagination: Object,
            loading: Boolean,
            exportLoading: Boolean,
            pageTitle: String,
            toolbarIcon: String,

            showToolbar: {
                type: Boolean,
                default: false
            },

            routeNameShowCourse: {
                type: String,
            },

            /* add */
            canAdd: Boolean,
            dialogAdd: Boolean,
            addLoading: Boolean,
            newCourse: Object,

            /* tags */
            tagStylesAvailable: Array,
            tagLevelsAvailable: Array,
            instrumentsAvailable: Array,
        },
        data: () => ({
            Helpers,
        }),


        mounted() {
        },

        computed: {
            localPagination: {
                get() {
                    return this.pagination;
                },

                set(value) {
                    this.$emit('update:pagination', value)
                }
            },
            localFilters: {
                get() {
                    return this.filters;
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },
            isLoading: {
                get() {
                    return this.loading;
                },

                set(value) {
                    this.$emit('update:loading', value)
                }
            },

            // useful for parent component
            courseFormAddComponent() {
                return this.$refs.courseFormAdd
            },
        },

        watch: {
            dialogAdd(value){
                if(!value){
                    this.$emit('update:newCourse', CourseLogic.getEmptyObject())
                    this.courseFormAddComponent.resetValidation()
                }
            }
        },

        methods: {
            createCourse(course) {
                this.$emit('createCourse', course)
            },

            downloadStats(course) {
                this.$emit('downloadStats', course)
            },

            countUnassignedLessons(lessons) {
                return lessons.length ? lessons.length : '-';
            },
        }
    };
</script>
