<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-stepper v-model="stepperIndex" vertical class="transparent pb-2">
            <v-stepper-step :complete="stepperIndex > 1" :step="1" editable>
                Produit
            </v-stepper-step>

            <v-stepper-content :step="1">
                <v-col cols="12">
                    <v-autocomplete
                            :items="availableProducts"
                            :value="selectedProduct"
                            @input="selectProduct"
                            item-text="title"
                            item-value="id"
                            returnObject
                            cache-items
                            :search-input="searchProduct"
                            @update:search-input="$emit('update:searchProduct', $event)"
                            :error-messages="errors.collect('products')"
                            data-vv-name="products"
                            v-validate="'required'"
                            label="Produit *"
                    >
                        <template v-slot:selection="data">
                            <v-chip class="caption">
                                {{ data.item.title }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-btn color="secondary" @click="stepperIndex++">Continuer</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepperIndex > 1" :step="2" editable>
                Configuration
            </v-stepper-step>

            <v-stepper-content :step="2">
                <v-row>
                    <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Vip
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Autoriser l'utilisateur à accéder aux ressources VIP
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-checkbox
                            v-validate
                            v-model="vip"
                            :error-messages="errors.collect('vip')"
                            label="Oui"
                            data-vv-name="vip"
                            required
                            :true-value="true"
                            :false-value="false"
                            color="secondary"
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-btn color="secondary lighten-1"
                       :loading="loading"
                       @click="submit">Valider
                </v-btn>
            </v-stepper-content>
        </v-stepper>
    </v-card>
</template>

<script>
    import _ from 'lodash'
    import Helpers from "mg-js-core/lib/services/Helpers";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";
    import CourseModuleLogic from "mg-js-core/lib/logics/CourseModuleLogic";

    export default {
        name: 'product-drip-content-stepper',

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            dark: Boolean,
            showToolbar: String,
            toolbarTitle: String,
            dialog: Boolean,
            isDialog: Boolean,

            value: {
                type: Object,
                required: true,
            },

            loading: Boolean,
            step: Number,


            /* products */
            availableProducts: Array,
            selectedProduct: Object,
            searchProduct: String,

            currentLesson: Object,

            showSelectProducts: Boolean,

            courseModulesSelected: Array,
        },

        data: () => ({
            rowActive: {},
            vip: false
            // selectedCourse: []
        }),

        mounted() {

        },

        computed: {
            stepperIndex: {
                get() {
                    return this.step
                },
                set(step) {
                    this.$emit('update:step', step)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },
        },

        watch: {
            dialog: {
                handler(value) {
                    if (!value) {
                        this.resetValidation()
                    }
                },
                deep: true
            },
        },

        methods: {
            selectProduct(product) {
                console.log(product);
                product.enabled = true
                product.dripping_enabled = true
                product.visible = true

                this.$emit('update:selectedProduct', product)
                this.$emit('update:currentLesson', LessonLogic.getEmptyObject())

                this.courseModulesSelectedValue = [];
            },


            selectCourseModule(courseModule) {
                const index = _.findIndex(this.courseModulesSelectedValue, selectedCourseModule => {
                    return selectedCourseModule.id === courseModule.id
                });

                if (index > -1) {
                    this.courseModulesSelectedValue.splice(index, 1);

                } else {
                    this.courseModulesSelectedValue.push(courseModule);
                }

                //refresh currentLesson
                this.$nextTick(() => {
                    this.courseModulesSelectedValue = _.sortBy(this.courseModulesSelectedValue, ['position']);

                    this.selectFirstLesson();
                })
            },


            selectFirstLesson() {
                this.$emit('update:currentLesson', CourseModuleLogic.selectFirstLesson(this.courseModulesSelectedValue))
            },

            submit(id, body) {
                this.$emit('submit', {
                    ...body,
                    product_id: this.selectedProduct.id,
                    vip: this.vip
                })
            },
            resetValidation() {
                this.$validator.reset();
            },

            addFormErrors(errors) {
                this.MgAlertComponent.displayAlert();
                Helpers.addFormErrors(this.$validator, errors);
            },
        }
    }
</script>
