<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :color="toolbarColor">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template>
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           v-if="isDialog"
                           @click="$emit('update:dialog', false);">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>


        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-card-text>
            <v-data-table
                    :options.sync="currentPagination"
                    :server-items-length="totalItems"
                    :footer-props="{
                        'items-per-page-options' : [20, 50, 100, 150]
                    }"
                    :headers="headers"
                    :loading="loading"
                    :hide-default-footer="hideDefaultFooter"
                    item-key="id"
                    :items="orders">
                <template v-slot:item.name="{ item }">
                    {{ getSkuName(item) }}
                    <v-icon
                            v-if="hasSkuVip(item)"
                            color="warning" small style="padding-bottom: 2px">
                        mdi-star
                    </v-icon>
                </template>
                <template v-slot:item.status="{ item }">
                    {{ translateStatus(item) }}
                </template>

                <template v-slot:item.price="{ item }">
                    {{generateInterval(item) }}
                </template>

                <template v-slot:item.ends_at="{ item }">
                    {{ item.ends_at ? Helpers.parseDate(item.ends_at, 'DD/MM/YYYY') : '-'}}
                </template>
            </v-data-table>
        </v-card-text>

    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'

    export default {
        name: 'order-table',

        $_veeValidate: {
            validator: 'new',
        },
        props: {
            value: {
                type: Array,
                required: true,
            },
            filters: {
                type: Object,
            },
            pagination: {
                type: Object,
            },

            totalItems: Number,

            toolbarTitle: String,
            showToolbar: Boolean,
            hideDefaultFooter: Boolean,

            toolbarColor: {
                type: String,
                default: 'accent'
            },
            dialog: Boolean,
            isDialog: Boolean,
            loading: Boolean,

            showActions: Boolean,
        },

        data: () => ({
            Helpers,
        }),

        computed: {
            orders: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            currentPagination: {
                get() {
                    return this.pagination;
                },
                set(value) {
                    this.$emit('update:pagination', value)
                }
            },
            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            headers() {
                let headers = [
                    {
                        text: 'Nom',
                        align: 'left',
                        sortable: true,
                        value: 'name'
                    },
                    {
                        text: 'Statut',
                        align: 'left',
                        sortable: true,
                        value: 'status'
                    },
                    {
                        text: 'Prix',
                        align: 'left',
                        sortable: true,
                        value: 'price'
                    },
                ];

                if (this.showActions) {
                    headers.push({text: 'Actions', value: 'action', sortable: false})
                }

                return headers;
            },
        },

        mounted() {

        },

        watch: {},

        methods: {
            translateStatus(order) {
                if (order.status === 'paid') {
                    return 'Payé'
                } else {
                    return order.status
                }
            },

            getSkuName(order) {
                return order.skus
                && order.skus.data
                && order.skus.data.length
                    ? order.skus.data[0].title
                    : ''
            },

            hasSkuVip(order) {
                return order.vipSkus && order.vipSkus && order.vipSkus.data.length
            },

            generateInterval(order) {
                let skus = order.skus ? order.skus.data : [];

                let subtitle = '';
                let orderCurrency = '';

                let orderPrice = 0;


                skus.forEach((sku) => {
                    orderPrice += sku.price
                })

                if (!orderPrice) {
                    return 'Gratuit'
                }

                if (skus.length) {
                    orderCurrency = skus[0].currency;
                }

                subtitle = subtitle.concat(orderPrice)
                subtitle = subtitle.concat(orderCurrency === 'EUR' ? '€' : '$')

                return subtitle
            },
        }
    };
</script>
