<template>
    <v-card :flat="flat">
        <template v-if="showToolbar">
            <v-app-bar flat dense :color="colorToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-menu offset-y bottom left
                        v-if="showOptionsMenu">
                    <template v-slot:activator="{ on }">
                        <v-btn icon fab small v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense class="py-0">
                        <v-list-item
                                @click="archiveSong">
                            <v-list-item-title class="primary--text">Supprimer</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <template v-if="isDialog">

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>


            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-container class="container--fluid" grid-list-lg>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="song.title"
                            :error-messages="errors.collect('title')"
                            label="Titre *"
                            name="title"
                            data-vv-title="title"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="song.author"
                            :error-messages="errors.collect('author')"
                            label="Artiste *"
                            name="author"
                            data-vv-title="author"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-model="song.guitarist"
                            :error-messages="errors.collect('guitarist')"
                            label="Guitariste"
                            name="guitarist"
                            data-vv-title="guitarist"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                            v-model="song.tag_style_id"
                            :items="tagStylesAvailable"
                            item-text="title"
                            item-value="id"
                            v-validate
                            :error-messages="errors.collect('tagStyles')"
                            data-vv-name="tagStyles"
                            label="Style"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                            v-model="song.current_instruments"
                            :items="instrumentsAvailable"
                            item-text="name"
                            item-value="id"
                            v-validate
                            multiple
                            :error-messages="errors.collect('instruments')"
                            data-vv-name="instruments"
                            label="Instruments"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-checkbox
                            v-validate
                            v-model="song.is_new"
                            :error-messages="errors.collect('enabled')"
                            label="Est une nouvelle chanson"
                            data-vv-name="is_new"
                            required
                            :true-value="true"
                            :false-value="false"
                            color="secondary"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-container>

        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    export default {
        name: 'song-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            prices: Array,

            dialog: Boolean,
            isDialog: Boolean,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,
            loading: Boolean,

            disabled: Boolean,
            dark: Boolean,

            value: Object,

            showToolbar: Boolean,
            showOptionsMenu: Boolean,
            flat: Boolean,
            colorToolbar: String,
            tagStylesAvailable: Array,
            instrumentsAvailable: Array,
        },

        data: () => ({
            // disableAlert: false,
            pagination: Helpers.initPagination(20, 1, 'title', true),
            lessonSearch: null,
            lessonLoading: false,
        }),

        watch: {
            lessonSearch(val) {
                // Items have already been requested
                if (this.lessonLoading) return
                this.lessonLoading = true;
                this.getLessons(val);
            },
        },

        computed: {
            song: {
                get() {
                    return this.value;
                },
                set(value) {
                    console.error(value)
                    this.$emit('input', value)
                },
            },

            mgAlertComponent() {
                return this.$refs.mgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },
        },

        methods: {
            archiveSong() {
                this.$emit('archiveSong', this.song.id)
            },

            validateSongForm() {
                return this.$validator.validateAll();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                this.validateSongForm().then(isValid => {
                    if (isValid) {
                        this.$emit('submit');
                    }
                })
            },
        }
    }
</script>
