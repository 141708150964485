<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="MgAlert"></mg-alert>

        <v-container class="container--fluid" grid-list-lg>
            <v-row>
                <template v-if="showRelatedProducts && filteredProduct.length">
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-card-title class="pb-0 title font-weight-bold">
                            Général
                        </v-card-title>
                    </v-col>
                </template>
            </v-row>
            <v-row class="px-1">
                <v-col cols="12" sm="6">
                    <v-text-field
                            v-validate="'required'"
                            v-model="courseModule.title"
                            :error-messages="errors.collect('title')"
                            label="Titre *"
                            name="title"
                            data-vv-title="title"
                            required
                    ></v-text-field>
                </v-col>
                <template v-if="!showRelatedProducts">
                    <v-col cols="12">
                        <v-autocomplete
                                v-model="defaultSelectedSku"
                                :items="relatedSkus"
                                label="Achat à la carte (sku)"
                                item-text="name"
                                v-validate
                                data-vv-title="defaultSelectedSku"
                                item-value="id"
                        >
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </template>

                <v-col cols="12" sm="6">
                    <v-select
                            v-model="courseModule.tag_level_id"
                            :items="tagLevelsAvailable"
                            item-text="title"
                            item-value="id"
                            v-validate
                            :error-messages="errors.collect('tagLevel')"
                            data-vv-name="tagLevel"
                            label="Niveau"
                    ></v-select>
                </v-col>

                <template v-if="showContent">
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>

                    <v-col cols="12" sm="9">
                        <v-autocomplete
                            :items="availableCourseModules"
                            v-model="courseModule.wf_course_module_id"
                            @input="getWfData"
                            item-text="name"
                            item-value="id"
                            cache-items
                            :error-messages="errors.collect('wf_course_module_id')"
                            data-vv-name="wf_course_module_id"
                            v-validate=""
                            label="Module webflow">
                            <template v-slot:selection="data">
                                <v-chip class="caption">
                                    {{ data.item.name }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>


                    <v-col cols="12" sm="3" class="align-self-center">
                        <v-checkbox
                            v-validate
                            v-model="courseModule.wf_auto_sync"
                            :error-messages="errors.collect('wf_auto_sync')"
                            label="Enregistrer changement dans webflow"
                            data-vv-name="wf_auto_sync"
                            :true-value="true"
                            :false-value="false"
                            @change="fields.wf_auto_sync.dirty = true"
                            color="secondary"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" >
                        <v-text-field
                            v-validate
                            v-model="courseModule.duration"
                            :error-messages="errors.collect('duration')"
                            label="Nombre lecon - durée"
                            name="duration"
                            data-vv-title="duration"
                            required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-textarea
                            rows="1"
                            auto-grow
                            v-model="courseModule.short_description"
                            label="Description courte"
                            v-validate
                            name="short_description"
                            data-vv-title="short_description">
                            >
                        </v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <mg-editor-full
                            ref="mgEditorDescription"
                            v-model="courseModule.description"
                            label="Description"
                            v-validate
                            name="description"
                            data-vv-title="description">
                            >
                        </mg-editor-full>
                    </v-col>
                    <v-col cols="12" >
                        <v-text-field
                                v-model="courseModule.active_campaign_id"
                                :error-messages="errors.collect('active_campaign_id')"
                                v-validate
                                label="Active Campaign tag"
                                name="active_campaign_id"
                                data-vv-title="active_campaign_id"
                                type="text"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                            v-validate
                            v-model="courseModule.bypass_courses_order"
                            :error-messages="errors.collect('wf_auto_sync')"
                            label="Bypass l'odre de déblocage des leçons"
                            data-vv-name="bypass_courses_order"
                            :true-value="true"
                            :false-value="false"
                            @change="fields.bypass_courses_order.dirty = true"
                            color="secondary"
                        ></v-checkbox>
                        <p>L'option "Bypass l'odre de déblocage des leçons" permet au membre de pouvoir jouer les leçons de ce module sans prendre en compte l'odre de déblocage des leçons. De plus, il aura accès directement à l'ensemble des leçons de ce module.</p>
                    </v-col>
                </template>
            </v-row>

            <v-row v-if="showRelatedProducts && filteredProduct.length">
                <v-col cols="12" class="pa-0">
                    <v-card flat tile color="transparent">
                        <v-card-title class="pb-0 title font-weight-bold">
                            Produits associés
                        </v-card-title>
                        <v-card-text class="pb-0">
                            Ajouter directement le module aux produits liés du cours
                        </v-card-text>

                        <v-card-title class="py-0">

                            <v-radio-group v-model="addRelatedProducts" row>
                                <v-radio label="Non" :value="false"></v-radio>
                                <v-radio label="Oui" :value="true"></v-radio>
                            </v-radio-group>
                        </v-card-title>
                    </v-card>
                </v-col>

                <template v-if="addRelatedProducts === true">
                    <v-col cols="12" class="px-5 py-0">
                        <v-divider></v-divider>
                    </v-col>

                    <v-col cols="12"
                           class="pt-0 px-5"
                           v-for="product in filteredProduct"
                           :key="product.id">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 subtitle-1 font-weight-bold">
                                {{product.title}}
                            </v-card-title>

                            <v-list-item-group
                                    v-model="selectedPlans"
                                    multiple>
                                <v-list class="py-0">
                                    <v-list-item
                                            v-for="plan in getPlans(product)"
                                            :ripple="false"
                                            :value="plan.id"
                                            :key="plan.id">
                                        <template v-slot:default="{ active, toggle }">
                                            <v-list-item-action>
                                                <v-checkbox
                                                        :value="active"
                                                        color="secondary"
                                                        @click="toggle"
                                                ></v-checkbox>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{plan.title}}
                                                    <v-icon
                                                            v-if="plan.vipPlan && plan.vipPlan.data && plan.vipPlan.data.deleted_at === null"
                                                            color="warning" small
                                                            style="padding-bottom: 2px">
                                                        mdi-star
                                                    </v-icon>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{generateSubTitle(plan)}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                </v-list>
                            </v-list-item-group>

                            <v-list-item-group
                                    v-model="selectedSkus"
                                    multiple>
                                <v-list class="py-0">
                                    <v-list-item
                                            v-for="sku in getSkus(product)"
                                            :ripple="false"
                                            :value="sku.id"
                                            :key="sku.id">
                                        <template v-slot:default="{ active, toggle }">
                                            <v-list-item-action>
                                                <v-checkbox
                                                        :value="active"
                                                        color="secondary"
                                                        @click="toggle"
                                                ></v-checkbox>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>{{sku.title}}
                                                    <v-icon
                                                            v-if="sku.vipSku && sku.vipSku.data && sku.vipSku.data.deleted_at === null"
                                                            color="warning" small
                                                            style="padding-bottom: 2px">
                                                        mdi-star
                                                    </v-icon>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{generateSubTitle(sku)}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                </v-list>
                            </v-list-item-group>
                        </v-card>
                    </v-col>


                    <v-col cols="12" class="px-5 py-0">
                        <v-divider></v-divider>
                    </v-col>
                </template>
            </v-row>


            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>

            <v-col cols="12" style="font-size: 18px; color:red;">
                Les champs suivants sont uniquement reservés aux modules faisant partie du MyRépertoire.
            </v-col>

            <v-col cols="12">
                <v-text-field
                        v-model="courseModule.repertory_author"
                        :error-messages="errors.collect('author')"
                        label="Auteur de la chanson (MyRépertoire uniquement)"
                        name="author"
                        data-vv-title="author"
                ></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-text-field
                        v-model="courseModule.repertory_guitarist"
                        :error-messages="errors.collect('guitarist')"
                        label="Guitariste de la chanson (MyRépertoire uniquement)"
                        name="guitarist"
                        data-vv-title="guitarist"
                ></v-text-field>
            </v-col>

            <v-col cols="12">
                    <v-select
                            v-model="courseModule.tag_style_id"
                            :items="tagStylesAvailable"
                            item-text="title"
                            item-value="id"
                            v-validate
                            :error-messages="errors.collect('tagStyles')"
                            data-vv-name="tagStyles"
                            label="Style (MyRépertoire uniquement)"
                    ></v-select>
                </v-col>
                <v-col cols="12">
                    <v-select
                            v-model="courseModule.instrument_id"
                            :items="instrumentsAvailable"
                            item-text="name"
                            item-value="id"
                            v-validate
                            :error-messages="errors.collect('instrument_id')"
                            data-vv-name="instrument_id"
                            label="Instrument (MyRépertoire uniquement)"
                    ></v-select>
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                            v-validate
                            v-model="courseModule.repertory_is_new"
                            :error-messages="errors.collect('repertory_is_new')"
                            label="Est une nouvelle chanson (MyRépertoire uniquement)"
                            data-vv-name="repertory_is_new"
                            :true-value="true"
                            :false-value="false"
                            color="secondary"
                    ></v-checkbox>
                </v-col>

        </v-container>
        <v-card-text class="pt-0 ml-0" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="loading"
                   @click="submit()">
                {{submitBtn}}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import parallel from 'async/parallel';
    import _ from 'lodash';
    import WebflowLogic from "mg-js-core/lib/logics/WebflowLogic";

    export default {
        name: 'course-module-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            prices: Array,

            showRelatedProducts: Boolean,
            products: Array,
            availableRelatedSkus: Array,
            availableLessons: Array,
            lessonSelectedIds: Array,
            lessonSearch: String,

            dialog: Boolean,
            isDialog: Boolean,
            loading: Boolean,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,

            disabled: Boolean,
            dark: Boolean,

            value: Object,

            showToolbar: {
                type: String,
                default: null
            },

            showContent: Boolean,

            tagLevelsAvailable: Array,
            tagStylesAvailable: Array,
            instrumentsAvailable: Array,
        },

        data: () => ({
            selectedPlans: [],
            selectedSkus: [],
            addRelatedProducts: false,
            providerValues: [
                {
                    text: 'Youtube',
                    value: "youtube"
                },
                {
                    text: 'Viméo',
                    value: "vimeo"
                },
            ],

            webflowLoading: false,
            availableCourseModules: [],

            /* TEST */
            defaultSelectedSku: null,
        }),

        mounted() {
            this.setDefaultSelectedSku(this.courseModule);

            if(this.showContent){
                WebflowLogic
                    .getAllCourseModules()
                    .then(({data}) => {
                        this.webflowLoading = false;
                        this.availableCourseModules = data
                        console.log(data)
                    })
            }
        },

        watch: {
            courseModule(value) {
                this.setDefaultSelectedSku(value);
            }
        },
        computed: {
            courseModule: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            mgAlertComponent() {
                return this.$refs.MgAlert
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },

            relatedSkus() {
                const groupByProductTitle = _.groupBy(this.availableRelatedSkus, 'product.data.title');
                let skus = []
                for (let [key, value] of Object.entries(groupByProductTitle)) {
                    skus.push({
                        header: key
                    })

                    value.forEach(sku => {
                        skus.push({
                            name: sku.title,
                            id: sku.id,
                            group: key,
                        })
                    })
                }

                return skus
            },

            filteredProduct() {
                return this.products.filter((product => {
                    const plans = this.getPlans(product);
                    const skus = this.getSkus(product);
                    return (plans && plans.length) || (skus && skus.length > 0)
                }))
            },
        },

        methods: {
            setDefaultSelectedSku(value) {
                if (value.defaultSku && value.defaultSku.data) {
                    this.defaultSelectedSku = value.defaultSku.data.id
                }else {
                    this.defaultSelectedSku = null;
                }
            },

            getSkus(product) {
                return product.skus && product.skus.data ? product.skus.data : null
            },

            getPlans(product) {
                return product.plans && product.plans.data ? product.plans.data : null
            },

            syncEditor() {
                setTimeout(() => {
                    this.$refs.mgEditorDescription.refreshContent();
                }, 250)
            },

            validateCourseModuleForm() {
                return this.$validator.validateAll();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.mgAlertComponent.displayAlert();
            },

            submit() {
                if (this.isDirty) {
                    this.mgAlertComponent.closeAlert();

                    parallel([
                        (callback) => {
                            this.validateCourseModuleForm().then(isValid => {
                                isValid
                                    ? callback()
                                    : callback('invalid')
                            })
                        }
                    ], (err) => { //callback
                        if (!err) {
                            let courseModule = {
                                id: this.courseModule.id,
                                title: this.courseModule.title,
                                position: this.courseModule.position,
                                enabled: this.courseModule.enabled,
                                course_id: this.courseModule.course_id,
                                bypass_courses_order: this.courseModule.bypass_courses_order,
                                active_campaign_id: this.courseModule.active_campaign_id,
                                repertory_author: this.courseModule.repertory_author,
                                repertory_guitarist: this.courseModule.repertory_guitarist,
                                tag_style_id: this.courseModule.tag_style_id,
                                tag_level_id: this.courseModule.tag_level_id,
                                instrument_id: this.courseModule.instrument_id,
                                repertory_is_new: this.courseModule.repertory_is_new,
                                // video_id: this.courseModule.video_id,
                                // provider: this.courseModule.provider,
                            };

                            if (this.showContent) {
                                courseModule.wf_course_module_id =this.courseModule.wf_course_module_id;
                                courseModule.wf_auto_sync =this.courseModule.wf_auto_sync;
                                courseModule.description =this.courseModule.description;
                                courseModule.short_description =this.courseModule.short_description;
                                courseModule.duration =this.courseModule.duration;
                            }

                            if (this.showRelatedProducts) {
                                if (this.selectedPlans.length) {
                                    courseModule.plan_ids = this.selectedPlans;
                                }

                                if (this.selectedSkus.length) {
                                    courseModule.sku_ids = this.selectedSkus;
                                }
                            } else if(this.defaultSelectedSku) {
                                courseModule.default_sku_id = this.defaultSelectedSku
                            }

                            console.warn(courseModule);

                            // this.$emit('submit', this.courseModule);
                            this.$emit('submit', courseModule);
                        }
                    });
                }
            },

            generateTitle(item) {
                return `${item.title} ${item.method_id}`
            },

            getWfData() {
                this.webflowLoading = true;

                WebflowLogic
                    .getOneCourseModule(this.courseModule.wf_course_module_id)
                    .then(({data}) => {
                        this.courseModule = {
                            ...this.courseModule,
                            description : data.description,
                            short_description : data.short_description,
                            duration : data.duration,
                        }

                        this.webflowLoading = false;

                        this.$nextTick(() => {
                            this.$refs.mgEditorDescription.refreshContent()
                        })

                        console.log(data)
                    })
            },

            generateSubTitle(plan) {
                let subtitle = '';

                subtitle = subtitle.concat(plan.price);
                subtitle = subtitle.concat(plan.currency === 'EUR' ? '€' : '$');

                let interval = '';

                if (plan.interval === 'week') {
                    interval = 'semaine'
                } else if (plan.interval === 'year') {
                    interval = 'année'
                } else if (plan.interval === 'month' && plan.interval_count === 6) {
                    interval = 'semestre'
                } else if (plan.interval === 'month' && plan.interval_count === 3) {
                    interval = 'trimestre'
                } else {
                    interval = 'mois'
                }

                return subtitle.concat('/' + interval);
            },
        }
    }
</script>
