<template>
    <v-card flat tile class="mg-comment-content transparent mb-2">
        <v-app-bar flat dark dense color="secondary">
            <v-spacer></v-spacer>
        </v-app-bar>
        <mg-comment
            :id="comment.id"
            :max-width="$vuetify.breakpoint.mdAndUp ? '75%' : '100%'"
            :ref="`mgComment-${comment.id}`"
            v-for="comment in comments"
            :key="comment.id"
            showDescendants
            canDelete
            canHover
            canAnswer
            @deleteComment="deleteComment"
            @updateComment="updateComment"
            :comment="comment">
        </mg-comment>
    </v-card>
</template>

<script>
    import PageStore from "mg-js-core/lib/stores/PageStore";
    import MgComment from "@/components/community/MgComment";

    export default {
        name: 'mg-comment-list',

        components: {MgComment},

        props: {
            comments: Array,
        },

        data: () => ({
            PageStore,
        }),

        mounted() {
        },

        computed: {
        },

        methods: {
            deleteComment(comment) {
                this.$emit('deleteComment', comment)
            },

            updateComment(comment, body) {
                this.$emit('updateComment', comment, body);
            }
        }
    }
</script>
