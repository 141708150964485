<template>
    <v-dialog :value="dialogEdit"
              fullscreen hide-overlay transition="dialog-bottom-transition"
              color="creamy"
              @input="$emit('update:dialogEdit', $event)">

        <v-card flat tile color="creamy">
            <v-app-bar tile flat dense dark color="secondary">
                <v-btn icon small fab dark @click="$emit('update:dialogEdit', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="flex-grow-1">Avis sur le cours "<strong>{{ currentCourse.title }}</strong>" écrit par "<strong>{{ currentComment.user.data.username }}</strong>" </div>
            </v-app-bar>

            <v-row
                    class="pt-3"
                    justify="center">
                <v-col cols="12" md="11" xl="9">
                    <v-card flat class="transparent">
                        <v-row>
                            <v-col class="px-1">

                                <v-tabs

                                        v-model="currentIndexResourceTab"
                                        :background-color="'secondary'"
                                        dark
                                        show-arrows
                                        slider-color="primary">
                                    <v-tab
                                            style="transition: none;"
                                            v-for="resourceTab in resourceTabs"
                                            :key="resourceTab.title"
                                            ripple>
                                        <v-icon class="pr-2">{{resourceTab.icon}}</v-icon>
                                        {{resourceTab.title}}
                                    </v-tab>
                                    <template v-for="resourceTab in resourceTabs">
                                        <v-tab-item
                                                class="mb-3"
                                                style="max-height: 700px; overflow: scroll"
                                                color="creamy"
                                                :key="resourceTab.title">
                                            <v-card class="lesson-part-tabs"
                                                    id="contentComments"
                                                    style="min-height: 200px;"
                                                    v-if="resourceTab.id === 'comments'">

                                                <mg-comment-refact
                                                    canValidate
                                                    showDescendants
                                                    showRating

                                                    ref="mgCommentRefact"
                                                    @addMessage="addSubMessage"
                                                    @deleteComment="deleteComment"


                                                    @updateComment="updateComment"

                                                    :commentAnswerIdShow.sync="commentAnswerId"
                                                    @refresh="refresh(currentComment)"
                                                    focusCurrent
                                                    @showSubThread="commentAnswerId = $event.id"
                                                    :comment="currentComment"
                                                    @update:comment="$emit('update:currentComment', $event)"
                                                >
                                                </mg-comment-refact>
                                            </v-card>
                                        </v-tab-item>
                                    </template>
                                </v-tabs>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>

</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import CommentLogic from "mg-js-core/lib/logics/CommentLogic";
    import LessonLogic from "mg-js-core/lib/logics/LessonLogic";

    export default {
        name: 'comment-course-dialog',

        props: {
            dialogEdit: Boolean,

            currentComment: {
                type: Object,
                default: () => (CommentLogic.getEmptyObject())
            },
            currentCourse: {
                type: Object,
                default: () => (LessonLogic.getEmptyObject())
            },
        },
        data: () => ({
            Helpers,


            currentTab: null,
            playerHeight: null,

            currentIndexResourceTab: 0,
            commentAnswerId: null,

            resourceTabs: [
                {
                    id: 'comments',
                    title: 'Avis',
                    icon: 'mdi-comment-outline'
                },
            ],
        }),


        mounted() {
            setTimeout(() => {
                this.onResize();
            }, 1000)
        },

        computed: {
            /* DIALOG */

            mgComment() {
                return this.$refs.mgCommentRefact
            },
        },

        watch: {
        },

        methods: {
            refresh(comment) {
                this.$emit('refresh', comment)
            },

            addSubMessage(htmlMessage) {
                this.$emit('addSubMessage', htmlMessage)
            },

            deleteComment(comment) {
                this.$emit('deleteComment', comment)
            },

            updateComment(currentComment, body = {}) {
                this.$emit('updateComment', currentComment, body)
            },

            /* NEW */
            onResize() {
                this.playerWitdh = this.$refs['player-block'] && this.$refs['player-block'].offsetWidth > 0 ? 
                    this.$refs['player-block'].offsetWidth : 
                    400 * 1.78
                this.playerHeight = this.playerWitdh / 1.78
            },
        }
    };
</script>
